import { apiConfig, loginRequest, msalInstance } from "../config/config";
import { MediaState } from "../models/mediaState";
import { HistoryEntry } from "../models/historyEntry";
import { IngestSlot } from "../models/ingestSlot/ingestSlot";
import { IngestSlotBooking } from "../models/ingestSlot/ingestSlotBooking";
import { IngestStation } from "../models/ingestStation";
import { ApiConfig } from "./apiConfig";
import { DataboxBooking } from "../models/databoxBooking";
import { Comment } from "../models/comment";
import { Project } from "../models/project";
import { Country } from "../models/country";
import { Databox } from "../models/databox";
import { Company } from "../models/company";
import { DataboxOrderRequest } from "../models/databoxOrderRequest";
import { Order } from "../models/order";
import { IngestStationNameAndId } from "../models/ingestStationNameAndId";
import { PortType } from "../models/portType";
import { OrderProjectHistory } from "../models/orderProjectHistory";
import { UpsPickup } from "../models/upsPickup";
import { CyclesReport } from "../models/reports/cyclesReport";
import { PackageDropOff } from "../models/packageDropOff/packageDropOff";
import { IngestsReport } from "../models/reports/ingestsReport";
import { Ingest } from "../models/ingest";
import { JiraTicket } from "../models/jiraTicket";
import { DataboxesStatusReport } from "../models/reports/databoxesStatusReport";
import { CancelRequest } from "../models/cancelRequest";
import { IngestSlotHistory } from "../models/ingestSlot/ingestSlotHistory";
import { Address } from "../models/address";
import { DataboxOrderResponse } from "../models/DataboxOrderResponse";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { JobReport } from "../models/reports/jobReport";
import { IngestEvent } from "../models/ingestEvent";
import { SwitchJobRequest } from "../models/switchJobRequest";
import { InCarMount } from "../models/incarmount";
import { CancellationRequest } from "../models/cancellationRequest";
import { InCarMountComment } from "../models/inCarMountsComments";
import { NonStandardRequest } from "../models/nonStandardRequest";
import { NonStandardRequestResponse } from "../models/nonStandardRequestResponse";
import { DataboxPin } from "../models/databoxPin";
import { DataboxResponse } from "../models/databoxResponse";
import { ProposedAddress } from "../models/proposedAddress";
import { PackageDropOffResponse } from "../models/packageDropOff/packageDropOffResponse";
import { IngestSlotSwitchJob } from "../models/ingestSlotSwitchJob";
import { Tracker } from "../models/tracker";
import { IngestSlotMonitorData } from "../models/ingestSlotMonitorData";
import { IngestStationOverview } from "../models/ingestStationOverview";
import { DataboxDashboardOverview } from "../models/databoxDashboardOverview";
import { SusHubMonitorData } from "../models/SusHubMonitorData";
import { SusNameAndId } from "../models/SusNameAndId";
import { IngestsStatisticsData } from "../models/IngestsStatisticsData";
import { MaintenanceSchedule } from "../models/maintenanceSchedule";
import { MaintenanceScheduleResponse } from "../models/maintenanceScheduleReponse";
import { ReleaseTask } from "../models/releaseTask";
import { InitializeDataboxRequest } from "../models/InitializeDataboxRequest";

/**
 * The Api Service handles all backend requests of the frontend
 *
 * The Service implements the ApiConfig interface, which defines all methods needed to be implemented
 *
 * @implements ApiConfig
 *
 */
export const ApiService: ApiConfig = class ApiService {
    // Creates a header with a Bearer Token from MsalInstance to authenticate at the backend
    private static async useAuthHeader(): Promise<HeadersInit> {
        const silentRequest = {
            ...loginRequest,
            forceRefresh: false,
            account: msalInstance.getAllAccounts()[0],
        };

        const popupRequest = {
            scopes: ["User.Read"],
            account: msalInstance.getAllAccounts()[0],
        };

        let headers: HeadersInit = {};

        await msalInstance
            .acquireTokenSilent(silentRequest)
            .then(async (accessTokenResponse) => {
                // Request a new Access & Id Token from Azure
                // The IdToken is used to authenticate Requests on our own spring boot backend
                // The AccessToken is only to autorize Requests when calling Azure Services, e.x. Microsoft Graph API
                // All tokens are only refreshed by msal-browser library when the AccessToken is invalid(expired). But it can be that the IdToken we use for authentication expires first → Our backend can´t authenticate the request
                // This would result in an 401 - Unauthorized Error from our backend
                // The following code will get new Tokens üer login Request - It could be that some User interaction is needed when this happens
                if (accessTokenResponse.idTokenClaims["exp"] <= Math.floor(Date.now() / 1000)) {
                    // ID TOKEN IS EXPIRED!
                    await msalInstance.loginRedirect(loginRequest).catch((e) => {
                        console.log(e);
                    });
                }
                // Set API-Header with token
                headers = {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessTokenResponse.idToken}`, // access token can't be validated by backend.
                };
            })
            .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                if (error instanceof InteractionRequiredAuthError) {
                    msalInstance
                        .acquireTokenPopup(popupRequest)
                        .then((accessTokenResponse) => {
                            // Acquire token interactive success
                            headers = {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${accessTokenResponse.idToken}`, // access token can't be validated by backend.
                            };
                        })
                        .catch(function (error) {
                            // Acquire token interactive failure
                            console.log(error);
                        });
                }
                console.log(error);
            });
        return headers;
    }

    // GET REQUESTS
    // Returns a list of all Ingest Stations availableF
    public static async getAllIngestStations(): Promise<IngestStation[]> {
        const resourceName = "Ingest Stations";

        const headers = await this.useAuthHeader();

        const response = await fetch(apiConfig.backendURL + "/ingest-stations", {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            // A fetch() promise only rejects when a network error is encountered
            // when server cannot get reached it gets a null object which cannot be put into a Response object
            // --> Throws TypeError (Type Null != Type Response)
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response, resourceName);

            const body = await response.json();
            return body.map((json: any) => IngestStation.fromSerialized(json));
        } else {
            throw Error("Server send empty message.");
        }
    }

    // NOT USED -- DEPRECATED?
    // Returns the general Information of one specific Ingest Station by a given Ingest Station Id
    public static async getIngestStationsGeneralInformation(ingestStationId: number): Promise<any> {
        const resourceName = "Ingest Station General Information";

        const headers = await this.useAuthHeader();

        const response = await fetch(
            apiConfig.backendURL + `/ingest-stations/general-information/${ingestStationId}`,
            {
                method: "GET",
                headers: headers,
            }
        ).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response, resourceName);

            const body = await response.json();
            return body;
        } else {
            throw Error("Server send empty message.");
        }
    }

    // NOT USED -- DEPRECATED?
    // Returns the Shipping Address of one specific Ingest Station by a given Ingest Station Id
    public static async getIngestStationsShippingAddress(ingestStationId: number): Promise<any> {
        const resourceName = "Ingest Station Shipping Address";

        const headers = await this.useAuthHeader();

        const response = await fetch(
            apiConfig.backendURL + `/ingest-stations/shipping-address/${ingestStationId}`,
            {
                method: "GET",
                headers: headers,
            }
        ).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response, resourceName);

            const body = await response.json();

            return body;
        } else {
            throw Error("Server send empty message.");
        }
    }

    // NOT USED -- DEPRECATED?
    // Returns the Managed Countries of one specific Ingest Station by a given Ingest Station Id
    public static async getIngestStationsManagedCountries(ingestStationId: number): Promise<any> {
        const resourceName = "Ingest Station Managed Countries";

        const headers = await this.useAuthHeader();

        const response = await fetch(
            apiConfig.backendURL + `/ingest-stations/managed-countries/${ingestStationId}`,
            {
                method: "GET",
                headers: headers,
            }
        ).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response, resourceName);

            const body = await response.json();

            return body;
        } else {
            throw Error("Server send empty message.");
        }
    }

    // Returns one Ingest Station by a given Ingest Station Id
    public static async getIngestStationById(
        ingestStationId: number
    ): Promise<IngestStation | null> {
        const resourceName = "Ingest Station";

        const headers = await this.useAuthHeader();

        const response = await fetch(apiConfig.backendURL + `/ingest-stations/${ingestStationId}`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response, resourceName);

            const body = await response.json();
            return IngestStation.fromSerialized(body);
        } else {
            throw Error("Server send empty message.");
        }
    }

    // Returns all Ingest Slots for one Ingest Station by Ingest Station Id
    public static async getAllIngestSlotsByIngestStationId(
        ingestStationId: number
    ): Promise<IngestSlot[]> {
        const resourceName = "Ingest Slots";

        const headers = await this.useAuthHeader();

        const response = await fetch(apiConfig.backendURL + `/ingest-slots/${ingestStationId}`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response, resourceName);

            const body = await response.json();
            // TODO optimize type declaration
            // TODO handle null values
            return body.map((json: any) => IngestSlot.fromSerialized(json));
        } else {
            throw Error("Server send empty message.");
        }
    }

    // Returns all free Ingest Slots for one Ingest Station by Ingest Station Id
    public static async getFreeIngestSlotsByIngestStationId(
        ingestStationId: number
    ): Promise<IngestSlot[]> {
        const resourceName = "Ingest Slots";

        const headers = await this.useAuthHeader();

        const response = await fetch(
            apiConfig.backendURL + `/ingest-slots/available-slots/${ingestStationId}`,
            {
                method: "GET",
                headers: headers,
            }
        ).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response, resourceName);

            const body = await response.json();
            return body.map((json: any) => IngestSlot.fromSerialized(json));
        } else {
            throw Error("Server send empty message.");
        }
    }

    // Returns one Ingest Slot of an Ingest Station
    // To verify the relation between an Ingest Slot and an Ingest Station, both Id´s have to be provided
    public static async getIngestSlotById(
        ingestStationId: number,
        ingestSlotId: number
    ): Promise<IngestSlot | null> {
        const resourceName = "Ingest Slot";

        const headers = await this.useAuthHeader();
        const response = await fetch(
            apiConfig.backendURL + `/ingest-slots/${ingestStationId}/${ingestSlotId}`,
            {
                method: "GET",
                headers: headers,
            }
        ).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response, resourceName);

            const body = await response.json();

            return IngestSlot.fromSerialized(body);
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getAllDataboxes(): Promise<Databox[]> {
        const resourceName = "Databoxes";

        const headers = await this.useAuthHeader();

        const response = await fetch(apiConfig.backendURL + "/databoxes", {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (!response) {
            throw Error("Server send empty message.");
        }

        const returnValue = await checkStatus(response, resourceName, 404);
        if (returnValue === "Special-case 404") return [];

        const body = await response.json();
        const databoxResponse = DataboxResponse.fromSerialized(body);
        return databoxResponse.databoxes ?? [];
    }

    public static async getDataboxById(databoxID: number): Promise<Databox> {
        const resourceName = "Databox";

        const headers = await this.useAuthHeader();
        const response = await fetch(apiConfig.backendURL + `/databoxes/${databoxID}`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (!response) {
            throw Error("Server send empty message.");
        }

        await checkStatus(response, resourceName);

        const body = await response.json();
        const databoxResponse = DataboxResponse.fromSerialized(body);

        if (!databoxResponse.databoxes) {
            throw new Error("No Databox found with the given Id");
        }

        return databoxResponse.databoxes[0];
    }

    // Get names and ids of all ingest stations
    public static async getIngestStationNamesAndIds(): Promise<IngestStationNameAndId[]> {
        const resourceName = "Ingest Station Name and ID";

        const headers = await this.useAuthHeader();
        const response = await fetch(`${apiConfig.backendURL}/ingest-stations/short`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response, resourceName);

            const body = await response.json();
            return body.map((json: any) => IngestStationNameAndId.fromSerialized(json));
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getDataboxHistoryByDataboxID(
        databoxID: number
    ): Promise<HistoryEntry[] | null> {
        const resourceName = "Databox History";

        const headers = await this.useAuthHeader();
        const response = await fetch(`${apiConfig.backendURL}/databoxes/history/${databoxID}`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            const returnValue = await checkStatus(response, resourceName, 404);
            if (returnValue === "Special-case 404") return [];

            const body = await response.json();

            return body.map((json: any) => HistoryEntry.fromSerialized(json));
        } else {
            throw Error("Server send empty message.");
        }
    }

    // get data for the dashboard widget ingest slot status
    public static async getIngestSlotMonitorDataForIngestStation(
        ingestStationId: number
    ): Promise<IngestSlotMonitorData[]> {
        const resourceName = "Ingest Slot Monitor Data";

        const headers = await this.useAuthHeader();
        const response = await fetch(
            `${apiConfig.backendURL}/dashboard/ingest-slot-monitor/${ingestStationId}`,
            {
                method: "GET",
                headers: headers,
            }
        ).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response, resourceName);
            const body = await response.json();
            return body.map((row: IngestSlotMonitorData) =>
                IngestSlotMonitorData.fromSerialized(row)
            );
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getIngestSlotMonitorDataForIngestStationAndSpecificSlot(
        ingestStationId: number,
        slotSerialNumber: string
    ): Promise<IngestSlotMonitorData> {
        const resourceName = "Ingest Slot Monitor Data";

        const headers = await this.useAuthHeader();
        const response = await fetch(
            `${apiConfig.backendURL}/dashboard/ingest-slot-monitor/${ingestStationId}/${slotSerialNumber}`,
            {
                method: "GET",
                headers: headers,
            }
        ).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response, resourceName);

            const body = await response.json();

            return IngestSlotMonitorData.fromSerialized(body);
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getMediaStates(): Promise<MediaState[]> {
        const resourceName = "Media States";

        const headers = await this.useAuthHeader();
        const response = await fetch(apiConfig.backendURL + `/media-states`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response, resourceName);

            const body = await response.json();
            // TODO Error Handling if body is empty
            return body.map((json: any) => MediaState.fromSerialized(json));
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getAllPortTypes(): Promise<PortType[]> {
        const resourceName = "Port Types";

        const headers = await this.useAuthHeader();
        const response = await fetch(apiConfig.backendURL + "/port-types", {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response, resourceName);

            const body = await response.json();
            return body.map((json: any) => PortType.fromSerialized(json));
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getAllCompanies(): Promise<Company[] | null> {
        const resourceName = "Companies";

        const headers = await this.useAuthHeader();
        const response = await fetch(apiConfig.backendURL + "/companies", {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response, resourceName);

            const body = await response.json();
            return body.map((json: any) => Company.fromSerialized(json));
        } else {
            throw Error("Server send empty message.");
        }
    }
    public static async getAllProposedAddresses(): Promise<ProposedAddress[] | null> {
        const resourceName = "ProposedAddresses";

        const headers = await this.useAuthHeader();
        const response = await fetch(apiConfig.backendURL + "/proposed_locations", {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response, resourceName);

            const body = await response.json();
            return body.map((json: any) => ProposedAddress.fromSerialized(json));
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getAllIngestStationOverviewes(): Promise<IngestStationOverview[]> {
        const resourceName = "Ingeststation-Overview";

        const headers = await this.useAuthHeader();
        const response = await fetch(apiConfig.backendURL + "/view_ingest_station_overview", {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response, resourceName);

            const body = await response.json();
            return body.map((json: any) => IngestStationOverview.fromSerialized(json));
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getAllDataboxesOverview(
        stationID: number
    ): Promise<DataboxDashboardOverview[]> {
        const resourceName = "Databoxes-Overview";

        const headers = await this.useAuthHeader();
        const response = await fetch(apiConfig.backendURL + "/databoxesOverview/" + stationID, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (!response) {
            throw Error("Server send empty message.");
        }
        const returnValue = await checkStatus(response, resourceName, 404);
        if (returnValue === "Special-case 404") return [];
        const body = await response.json();
        return body.map((json: any) => DataboxDashboardOverview.fromSerialized(json));
    }

    public static async getAllCommentsForDatabox(databoxID: number): Promise<Comment[]> {
        const resourceName = "Comments";

        const headers = await this.useAuthHeader();
        const response = await fetch(apiConfig.backendURL + "/comments/" + databoxID, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response, resourceName);

            const body = await response.json();
            return body.map((json: any) => Comment.fromSerialized(json));
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getCurrentBookingByDataboxId(
        databoxID: number
    ): Promise<DataboxBooking | null> {
        const resourceName = "Current Booking";

        const headers = await this.useAuthHeader();

        const response = await fetch(
            `${apiConfig.backendURL}/databoxes/current-booking/${databoxID}`,
            {
                method: "GET",
                headers: headers,
            }
        ).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            const returnValue = await checkStatus(response, resourceName, 404);
            if (returnValue === "Special-case 404") return null;

            const body = await response.json();
            return DataboxBooking.fromSerialized(body);
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getHistoryEntryByDataboxIdAndUuid(
        databoxID: number,
        historyUuid: number
    ): Promise<HistoryEntry | null> {
        const resourceName = "History Entry";

        const headers = await this.useAuthHeader();
        const response = await fetch(
            `${apiConfig.backendURL}/databoxes/history/${databoxID}/${historyUuid}`,
            {
                method: "GET",
                headers: headers,
            }
        ).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response, resourceName);

            const body = await response.json();
            return HistoryEntry.fromSerialized(body);
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getAllProjects(): Promise<Project[] | null> {
        const resourceName = "Project";
        const headers = await this.useAuthHeader();
        const response = await fetch(`${apiConfig.backendURL}/projects`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            const returnValue = await checkStatus(response, resourceName, 404);
            if (returnValue === "Special-case 404") return [];

            const body = await response.json();
            return body?.map((json: any) => Project.fromSerialized(json)) ?? [];
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getAllCountries(): Promise<Country[] | null> {
        const resourceName = "Country";

        const headers = await this.useAuthHeader();
        const response = await fetch(`${apiConfig.backendURL}/countries`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            const returnValue = await checkStatus(response, resourceName, 404);
            if (returnValue === "Special-case 404") return [];

            const body = await response.json();
            return body?.map((json: any) => Country.fromSerialized(json)) ?? [];
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getAllDataboxOrders(): Promise<Order[] | null> {
        const resourceName = "Databox Orders";

        const headers = await this.useAuthHeader();
        const response = await fetch(`${apiConfig.backendURL}/databox-orders`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            const returnValue = await checkStatus(response, resourceName, 404);
            if (returnValue === "Special-case 404") return [];

            const body = await response.json();
            return body?.map((json: any) => Order.fromSerialized(json)) ?? [];
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getFreeDataboxesByCountryId(countryId: number): Promise<DataboxResponse> {
        const resourceName = "Databoxes";

        const headers = await this.useAuthHeader();
        const response = await fetch(
            `${apiConfig.backendURL}/databoxes/free?countryId=${countryId}`,
            {
                method: "GET",
                headers: headers,
            }
        ).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (!response) {
            throw Error("Server send empty message.");
        }

        const returnValue = await checkStatus(response, resourceName, 404);
        if (returnValue === "Special-case 404") return new DataboxResponse({});

        const body = await response.json();
        return DataboxResponse.fromSerialized(body);
    }
    public static async getFreeDataboxesByCountryIdAndLocation(
        countryId: number,
        locationId: number
    ): Promise<DataboxResponse> {
        const resourceName = "Databoxes ING";
        const headers = await this.useAuthHeader();
        const response = await fetch(
            `${apiConfig.backendURL}/databoxes/free-ing-users?countryId=${countryId}&locationId=${locationId}`,
            {
                method: "GET",
                headers: headers,
            }
        ).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (!response) {
            throw Error("Server sent an empty message.");
        }

        const returnValue = await checkStatus(response, resourceName, 404);
        if (returnValue === "Special-case 404") return new DataboxResponse({});

        const body = await response.json();
        return DataboxResponse.fromSerialized(body);
    }

    public static async getFreeMaintenanceReservedDataboxesByCountryId(
        countryId: number
    ): Promise<Databox[] | undefined> {
        const resourceName = "Databoxes";

        const headers = await this.useAuthHeader();
        const response = await fetch(
            `${apiConfig.backendURL}/databoxes/free-reserved-maintenance?countryId=${countryId}`,
            {
                method: "GET",
                headers: headers,
            }
        ).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (!response) {
            throw Error("Server send empty message.");
        }

        const returnValue = await checkStatus(response, resourceName, 404);
        if (returnValue === "Special-case 404") return [];

        const body = await response.json();
        const databoxResponse = DataboxResponse.fromSerialized(body);

        return databoxResponse.databoxes;
    }

    public static async getOrderById(orderId: number): Promise<Order> {
        const resourceName = "Databox Orders";

        const headers = await this.useAuthHeader();
        const response = await fetch(`${apiConfig.backendURL}/databox-orders/${orderId}`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response, resourceName);

            const body = await response.json();
            return Order.fromSerialized(body);
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getOrderByShipmentJobId(shipmentJobId: number): Promise<Order> {
        const resourceName = "Shipment Job Details";

        const headers = await this.useAuthHeader();
        const response = await fetch(
            `${apiConfig.backendURL}/databox-orders/by-shipment/${shipmentJobId}`,
            {
                method: "GET",
                headers: headers,
            }
        ).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response, resourceName);
            const body = await response.json();
            return Order.fromSerialized(body);
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getOrderByDataboxId(databoxId: number): Promise<Order | null> {
        const resourceName = "Order";

        const headers = await this.useAuthHeader();
        const response = await fetch(
            `${apiConfig.backendURL}/databox-orders/by-databox/${databoxId}`,
            {
                method: "GET",
                headers: headers,
            }
        ).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            const returnValue = await checkStatus(response, resourceName, 404);
            if (returnValue === "Special-case 404") return null;

            const body = await response.json();
            return Order.fromSerialized(body);
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getOrderProjectHistoryByOrderId(
        orderId: number
    ): Promise<OrderProjectHistory[]> {
        const resourceName = "Order Project History";

        const headers = await this.useAuthHeader();
        const response = await fetch(`${apiConfig.backendURL}/orders/history/${orderId}`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            console.log(error);
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            const returnValue = await checkStatus(response, resourceName, 404);
            if (returnValue === "Special-case 404") return [];

            const body = await response.json();
            return body.map((json: any) => OrderProjectHistory.fromSerialized(json));
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getCurrentOrderProjectHistoryByDataboxId(
        databoxId: number
    ): Promise<OrderProjectHistory | null> {
        const resourceName = "Order Project History";

        const headers = await this.useAuthHeader();
        const response = await fetch(
            `${apiConfig.backendURL}/orders/history/current/${databoxId}`,
            {
                method: "GET",
                headers: headers,
            }
        ).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            const returnValue = await checkStatus(response, resourceName, 404);
            if (returnValue === "Special-case 404") return null;

            const body = await response.json();
            return OrderProjectHistory.fromSerialized(body);
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getAllOrdersOverviewByAzureUserId(
        azureUserId: string
    ): Promise<Order[] | null> {
        const resourceName = "Databox Orders";

        const headers = await this.useAuthHeader();
        const response = await fetch(
            `${apiConfig.backendURL}/databox-orders/overview/${azureUserId}`,
            {
                method: "GET",
                headers: headers,
            }
        ).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            const returnValue = await checkStatus(response, resourceName, 404);
            if (returnValue === "Special-case 404") return [];

            const body = await response.json();
            return body?.map((json: any) => Order.fromSerialized(json)) ?? [];
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getAllCompletedOrdersOverviewByAzureUserId(
        azureUserId: string
    ): Promise<Order[] | null> {
        const resourceName = "Databox Orders";

        const headers = await this.useAuthHeader();
        const response = await fetch(
            `${apiConfig.backendURL}/databox-orders/overview/completed/${azureUserId}`,
            {
                method: "GET",
                headers: headers,
            }
        ).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            const returnValue = await checkStatus(response, resourceName, 404);
            if (returnValue === "Special-case 404") return [];

            const body = await response.json();
            return body?.map((json: any) => Order.fromSerialized(json)) ?? [];
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getAllNonCompletedOrdersOverviewByAzureUserId(
        azureUserId: string
    ): Promise<Order[] | null> {
        const resourceName = "Databox Orders";

        const headers = await this.useAuthHeader();
        const response = await fetch(
            `${apiConfig.backendURL}/databox-orders/overview/non-completed/${azureUserId}`,
            {
                method: "GET",
                headers: headers,
            }
        ).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            const returnValue = await checkStatus(response, resourceName, 404);
            if (returnValue === "Special-case 404") return [];

            const body = await response.json();
            return body?.map((json: any) => Order.fromSerialized(json)) ?? [];
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getAllOrdersOverview(): Promise<Order[] | null> {
        const resourceName = "Databox Orders";

        const headers = await this.useAuthHeader();
        const response = await fetch(`${apiConfig.backendURL}/databox-orders/overview`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            const returnValue = await checkStatus(response, resourceName, 404);
            if (returnValue === "Special-case 404") return [];

            const body = await response.json();
            return body?.map((json: any) => Order.fromSerialized(json)) ?? [];
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getAllNonCompletedOrdersOverview(): Promise<Order[] | null> {
        const resourceName = "Databox Orders";

        const headers = await this.useAuthHeader();
        const response = await fetch(
            `${apiConfig.backendURL}/databox-orders/overview/non-completed`,
            {
                method: "GET",
                headers: headers,
            }
        ).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            const returnValue = await checkStatus(response, resourceName, 404);
            if (returnValue === "Special-case 404") return [];

            const body = await response.json();
            return body?.map((json: any) => Order.fromSerialized(json)) ?? [];
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getAllCompletedOrdersOverview(): Promise<Order[] | null> {
        const resourceName = "Databox Orders";

        const headers = await this.useAuthHeader();
        const response = await fetch(`${apiConfig.backendURL}/databox-orders/overview/completed`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            const returnValue = await checkStatus(response, resourceName, 404);
            if (returnValue === "Special-case 404") return [];

            const body = await response.json();
            return body?.map((json: any) => Order.fromSerialized(json)) ?? [];
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getAllUserFromOrders(): Promise<string[] | null> {
        const resourceName = "Usernames";

        const headers = await this.useAuthHeader();
        const response = await fetch(`${apiConfig.backendURL}/databox-orders/overview/users`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            const returnValue = await checkStatus(response, resourceName, 404);
            if (returnValue === "Special-case 404") return [];

            const body = await response.json();
            return body;
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getAllShipmentJobsOverview(): Promise<Order[] | null> {
        const resourceName = "Databox Orders";

        const headers = await this.useAuthHeader();
        const response = await fetch(
            `${apiConfig.backendURL}/databox-orders/overview/shipment-jobs`,
            {
                method: "GET",
                headers: headers,
            }
        ).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            const returnValue = await checkStatus(response, resourceName, 404);
            if (returnValue === "Special-case 404") return [];

            const body = await response.json();
            return body?.map((json: any) => Order.fromSerialized(json)) ?? [];
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getAllIngestJobsOverview(): Promise<Order[] | null> {
        const resourceName = "Databox Orders";

        const headers = await this.useAuthHeader();
        const response = await fetch(
            `${apiConfig.backendURL}/databox-orders/overview/ingest-jobs`,
            {
                method: "GET",
                headers: headers,
            }
        ).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            const returnValue = await checkStatus(response, resourceName, 404);
            if (returnValue === "Special-case 404") return [];

            const body = await response.json();
            return body?.map((json: any) => Order.fromSerialized(json)) ?? [];
        } else {
            throw Error("Server send empty message.");
        }
    }
    public static async getAllIngestSlotSwitchJobs(
        orderId: number
    ): Promise<IngestSlotSwitchJob[]> {
        const resourceName = "IngestSlotSwitchJobs";

        const headers = await this.useAuthHeader();
        const response = await fetch(apiConfig.backendURL + "/ingest-slot-switch-job/" + orderId, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });
        if (!response) {
            throw Error("Server send empty message.");
        }
        const returnValue = await checkStatus(response, resourceName, 404);
        if (returnValue === "Special-case 404") return [];

        const body = await response.json();
        return body?.map((json: any) => IngestSlotSwitchJob.fromSerialized(json)) ?? [];
    }

    public static async getAllCyclesReport(): Promise<CyclesReport[] | null> {
        const resourceName = "Cycles Report";

        const headers = await this.useAuthHeader();
        const response = await fetch(`${apiConfig.backendURL}/cycles-report`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            const returnValue = await checkStatus(response, resourceName, 404);
            if (returnValue === "Special-case 404") return [];

            const body = await response.json();
            return body?.map((json: any) => CyclesReport.fromSerialized(json)) ?? [];
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getAllIngestsReports(): Promise<IngestsReport[] | null> {
        const resourceName = "Ingests Report";

        const headers = await this.useAuthHeader();
        const response = await fetch(`${apiConfig.backendURL}/ingests-report`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            const returnValue = await checkStatus(response, resourceName, 404);
            if (returnValue === "Special-case 404") return [];

            const body = await response.json();
            return body?.map((json: any) => IngestsReport.fromSerialized(json)) ?? [];
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getIngestsReportByCalenderWeek(
        calenderWeek: string
    ): Promise<IngestsReport[] | null> {
        const resourceName = "Ingests Report";

        const headers = await this.useAuthHeader();
        const response = await fetch(`${apiConfig.backendURL}/ingests-report/${calenderWeek}`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            const returnValue = await checkStatus(response, resourceName, 404);
            if (returnValue === "Special-case 404") return [];

            const body = await response.json();
            return body?.map((json: any) => IngestsReport.fromSerialized(json)) ?? [];
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getAllIngests(): Promise<Ingest[] | null> {
        const resourceName = "Ingests Report";

        const headers = await this.useAuthHeader();
        const response = await fetch(`${apiConfig.backendURL}/ingests`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            const returnValue = await checkStatus(response, resourceName, 404);
            if (returnValue === "Special-case 404") return [];

            const body = await response.json();
            return body?.map((json: any) => Ingest.fromSerialized(json)) ?? [];
        } else {
            throw Error("Server send empty message.");
        }
    }
    /*----------------------------
     ----------------------------*/
    public static async getAllIngest(): Promise<Ingest[] | null> {
        const resourceName = "Ingests Report";

        const headers = await this.useAuthHeader();
        const response = await fetch(`${apiConfig.backendURL}/ingests-view`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });
        if (response) {
            const returnValue = await checkStatus(response, resourceName, 404);
            if (returnValue === "Special-case 404") return [];

            const body = await response.json();
            return body?.map((json: any) => Ingest.fromSerialized(json)) ?? [];
        } else {
            throw Error("Server send empty message.");
        }
    }

    /*----------------------------
     ----------------------------*/

    public static async getDataboxesStatusReports(): Promise<DataboxesStatusReport[] | null> {
        const resourceName = "Databoxes Status Report";

        const headers = await this.useAuthHeader();
        const response = await fetch(`${apiConfig.backendURL}/databoxes-status-report`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (!response) {
            throw Error("Server send empty message.");
        }

        const returnValue = await checkStatus(response, resourceName, 404);
        if (returnValue === "Special-case 404") return [];
        const body = await response.json();
        return body?.map((json: any) => DataboxesStatusReport.fromSerialized(json)) ?? [];
    }

    public static async getAllIngestSlotHistoriesByIngestSlotId(
        ingestSlotId: number
    ): Promise<IngestSlotHistory[] | null> {
        const resourceName = "Ingest Slot History";

        const headers = await this.useAuthHeader();
        const response = await fetch(
            `${apiConfig.backendURL}/ingest-slots/history/${ingestSlotId}`,
            {
                method: "GET",
                headers: headers,
            }
        ).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            const returnValue = await checkStatus(response, resourceName, 404);
            if (returnValue === "Special-case 404") return [];

            const body = await response.json();
            return body.map((json: any) => IngestSlotHistory.fromSerialized(json));
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getAllIngestSlotBookingsByIngestSlotId(
        ingestSlotId: number
    ): Promise<IngestSlotBooking[] | null> {
        const resourceName = "Ingest Slot Bookings";

        const headers = await this.useAuthHeader();
        const response = await fetch(
            `${apiConfig.backendURL}/ingest-slots/bookings/${ingestSlotId}`,
            {
                method: "GET",
                headers: headers,
            }
        ).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            const returnValue = await checkStatus(response, resourceName, 404);
            if (returnValue === "Special-case 404") return [];

            const body = await response.json();
            return body.map((json: any) => IngestSlotBooking.fromSerialized(json));
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getCurrentIngestSlotBookingByIngestSlotId(
        ingestSlotId: number
    ): Promise<IngestSlotBooking | null> {
        const resourceName = "Ingest Slot Bookings";

        const headers = await this.useAuthHeader();
        const response = await fetch(
            `${apiConfig.backendURL}/ingest-slots/bookings/current/${ingestSlotId}`,
            {
                method: "GET",
                headers: headers,
            }
        ).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response, resourceName, 404);

            const body = await response.json();
            return IngestSlotBooking.fromSerialized(body);
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getOrderNumberByDataboxName(databoxName: string): Promise<number | null> {
        const resourceName = "Order";

        const headers = await this.useAuthHeader();
        const response = await fetch(`${apiConfig.backendURL}/my-databoxes/${databoxName}`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response, resourceName);

            const body = await response.json();

            return body;
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getJobReports(): Promise<JobReport[]> {
        const resourceName = "Job Report";

        const headers = await this.useAuthHeader();
        const response = await fetch(`${apiConfig.backendURL}/job-report`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            const returnValue = await checkStatus(response, resourceName, 404);
            if (returnValue === "Special-case 404") return [];

            const body = await response.json();

            return body.map((json: any) => JobReport.fromSerialized(json));
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getAllInCarMounts(): Promise<InCarMount[]> {
        const resourceName = "InCarMounts";

        const headers = await this.useAuthHeader();

        const response = await fetch(`${apiConfig.backendURL}/in-car-mounts`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            const returnValue = await checkStatus(response, resourceName, 404);
            if (returnValue === "Special-case 404") return [];

            const body = await response.json();
            return body.map((json: any) => InCarMount.fromSerialized(json));
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getAllInCarMountsForCustomer(): Promise<InCarMount[]> {
        const resourceName = "InCarMounts";

        const headers = await this.useAuthHeader();

        const response = await fetch(`${apiConfig.backendURL}/in-car-mounts/customer`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            const returnValue = await checkStatus(response, resourceName, 404);
            if (returnValue === "Special-case 404") return [];

            const body = await response.json();
            return body.map((json: any) => InCarMount.fromSerialized(json));
        } else {
            throw Error("Server send empty message.");
        }
    }
    public static async getAllCommentsForInCarMounts(
        inCarMountID: number
    ): Promise<InCarMountComment[]> {
        const resourceName = "Comments";

        const headers = await this.useAuthHeader();
        const response = await fetch(
            apiConfig.backendURL + "/in-car-mounts-comments/" + inCarMountID,
            {
                method: "GET",
                headers: headers,
            }
        ).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response, resourceName);

            const body = await response.json();
            return body.map((json: any) => InCarMountComment.fromSerialized(json));
        } else {
            throw Error("Server send empty message.");
        }
    }
    public static async getInCarMountById(inCarMountID: number): Promise<InCarMount> {
        const resourceName = "InCarMount";
        const headers = await this.useAuthHeader();
        const response = await fetch(apiConfig.backendURL + `/in-car-mounts/${inCarMountID}`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response, resourceName);

            const body = await response.json();
            return InCarMount.fromSerialized(body);
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getAllNonStandardRequests(): Promise<NonStandardRequest[]> {
        const resourceName = "Non-Standard Request";
        const headers = await this.useAuthHeader();
        const response = await fetch(apiConfig.backendURL + "/non-standard-request", {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response, resourceName);

            const body = await response.json();
            return body.map((json: any) => NonStandardRequest.fromSerialized(json));
        } else {
            throw Error("Server send empty message.");
        }
    }
    public static async getNonStandardRequestById(id: number): Promise<NonStandardRequest> {
        const resourceName = "Non-Standard Request";
        const headers = await this.useAuthHeader();
        const response = await fetch(apiConfig.backendURL + `/non-standard-request/${id}`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response, resourceName);

            const body = await response.json();
            return NonStandardRequest.fromSerialized(body);
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getAllDataboxPins(): Promise<DataboxPin[]> {
        const resourceName = "DataboxPins Request";
        const headers = await this.useAuthHeader();
        const response = await fetch(apiConfig.backendURL + `/databox-pins`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            const returnValue = await checkStatus(response, resourceName, 404);
            if (returnValue === "Special-case 404") return [];

            const body = await response.json();
            return body.map((json: any) => DataboxPin.fromSerialized(json));
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getDataboxPinsForCustomer(): Promise<DataboxPin[]> {
        const resourceName = "DataboxPins";
        const headers = await this.useAuthHeader();
        const response = await fetch(apiConfig.backendURL + "/databox-pins/customer", {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            const returnValue = await checkStatus(response, resourceName, 404);
            if (returnValue === "Special-case 404") return [];

            const body = await response.json();
            return body.map((json: any) => DataboxPin.fromSerialized(json));
        } else {
            throw Error("Server send empty message.");
        }
    }

    // get all data for the dashboard widget SUS hubs status
    public static async getAllMonitorDataForSusHubs(): Promise<SusHubMonitorData[]> {
        const resourceName = "Sus Hub Monitor Data";

        const headers = await this.useAuthHeader();
        const response = await fetch(`${apiConfig.backendURL}/sus-hubs`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (!response) {
            throw Error("Server send empty message.");
        }
        const returnValue = await checkStatus(response, resourceName, 404);
        if (returnValue === "Special-case 404") return [];

        const body = await response.json();
        return body?.map((json: SusHubMonitorData) => SusHubMonitorData.fromSerialized(json)) ?? [];
    }

    // get data for the dashboard widget SUS Hubs status by Location
    public static async getMonitorDataForSusHubsByLocation(
        locationId: number
    ): Promise<SusHubMonitorData[]> {
        const resourceName = "Sus Hub Monitor Data by Location Id";

        const headers = await this.useAuthHeader();
        const response = await fetch(`${apiConfig.backendURL}/sus-hubs/${locationId}`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (!response) {
            throw Error("Server send empty message.");
        }
        const returnValue = await checkStatus(response, resourceName, 404);
        if (returnValue === "Special-case 404") return [];

        const body = await response.json();
        return body?.map((json: SusHubMonitorData) => SusHubMonitorData.fromSerialized(json)) ?? [];
    }

    //get all Self Upload Station Locations
    public static async getAllSusLocations(): Promise<SusNameAndId[]> {
        const resourceName = "Sus Location Name and ID";

        const headers = await this.useAuthHeader();
        const response = await fetch(`${apiConfig.backendURL}/suslocations`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach serverr");
        });

        if (!response) {
            throw Error("Server send empty message.");
        }
        const returnValue = await checkStatus(response, resourceName, 404);
        if (returnValue === "Special-case 404") return [];

        const body = await response.json();
        return body?.map((json: any) => SusNameAndId.fromSerialized(json)) ?? [];
    }

    // get statistics for ingests by date and location
    public static async getIngestsStatisticsByTimeRangeAndLocation(
        startDate: string,
        endDate: string,
        ingestLocation: string
    ): Promise<IngestsStatisticsData[]> {
        const resourceName = "Ingests Statistics";
        const headers = await this.useAuthHeader();
        const response = await fetch(
            `${apiConfig.backendURL}/ingests-statistic/${startDate}/${endDate}/${ingestLocation}`,
            {
                method: "GET",
                headers: headers,
            }
        ).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (!response) {
            throw Error("Server send empty message.");
        }
        const returnValue = await checkStatus(response, resourceName, 404);
        if (returnValue === "Special-case 404") return [];

        const body = await response.json();
        return (
            body?.map((json: IngestsStatisticsData) =>
                IngestsStatisticsData.fromSerialized(json)
            ) ?? []
        );
    }

    public static async getAllIngestsStatisticsByTimeRange(
        startDate: string,
        endDate: string
    ): Promise<IngestsStatisticsData[]> {
        const resourceName = "Ingests Statistics for All Locations";
        const headers = await this.useAuthHeader();
        const response = await fetch(
            `${apiConfig.backendURL}/ingests-statistic-all-locations/${startDate}/${endDate}`,
            {
                method: "GET",
                headers: headers,
            }
        ).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (!response) {
            throw Error("Server send empty message.");
        }
        const returnValue = await checkStatus(response, resourceName, 404);
        if (returnValue === "Special-case 404") return [];

        const body = await response.json();

        return (
            body?.map((json: IngestsStatisticsData) =>
                IngestsStatisticsData.fromSerialized(json)
            ) ?? []
        );
    }

    public static async getCurrentMaintenanceMessage(): Promise<MaintenanceSchedule | null> {
        const resourceName = "Maintenance Message";
        const headers = await this.useAuthHeader();
        const response = await fetch(`${apiConfig.backendURL}/maintenance-message`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (!response) {
            throw Error("Server send empty message.");
        }

        const body = await response.json();

        const maintenanceScheduleReponse: MaintenanceScheduleResponse | null =
            MaintenanceScheduleResponse.fromSerialized(body);

        const returnValue = await checkStatus(
            response,
            resourceName,
            404,
            maintenanceScheduleReponse?.error
        );
        if (returnValue === "Special-case 404") return null;

        if (!maintenanceScheduleReponse) {
            return null;
        }

        return maintenanceScheduleReponse.maintenanceSchedule;
    }
    public static async getAllReleaseTasks(): Promise<ReleaseTask[]> {
        const resourceName = "ReleaseTasks";

        const headers = await this.useAuthHeader();

        const response = await fetch(`${apiConfig.backendURL}/release-tasks`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            const returnValue = await checkStatus(response, resourceName, 404);
            if (returnValue === "Special-case 404") return [];

            const body = await response.json();
            return body.map((json: any) => ReleaseTask.fromSerialized(json));
        } else {
            throw Error("Server send empty message.");
        }
    }

    // PUT REQUESTS
    public static async updateDataboxBookingById(
        bookingId: number,
        databoxBooking: DataboxBooking
    ): Promise<string> {
        const headers = await this.useAuthHeader();
        const response = await fetch(`${apiConfig.backendURL}/databox/edit-booking/${bookingId}`, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(databoxBooking),
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response);
            return "success";
        }

        return "failed";
    }

    public static async updateSmartHandsTasks(
        orderId: number,
        smartHandsTasks: string,
        currentTime: number
    ): Promise<string> {
        const headers = await this.useAuthHeader();
        const response = await fetch(
            `${apiConfig.backendURL}/databox-orders/complete-tasks/${orderId}/${smartHandsTasks}/${currentTime}`,
            {
                method: "PUT",
                headers: headers,
            }
        ).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response);
            return "success";
        }

        return "failed";
    }

    public static async addOverwriteStateToIngest(
        ingestId: number,
        state: string,
        reset: boolean
    ): Promise<string> {
        const headers = await this.useAuthHeader();
        const response = await fetch(
            `${apiConfig.backendURL}/ingest/overwrite-state/${ingestId}/${state}/${reset}`,
            {
                method: "PUT",
                headers: headers,
            }
        ).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response);
            return "success";
        }

        return "failed";
    }

    public static async addDetailsMessageToIngest(
        ingestId: number,
        message: string,
        reset: boolean
    ): Promise<string> {
        const headers = await this.useAuthHeader();
        const response = await fetch(
            `${apiConfig.backendURL}/ingest/details-message/${ingestId}?` +
                new URLSearchParams({
                    message: message,
                    reset: String(reset),
                }),
            {
                method: "PUT",
                headers: headers,
            }
        ).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response);
            return "success";
        }

        return "failed";
    }

    public static async updateFutureIngestSlotBooking(
        ingestSlotBooking: IngestSlotBooking
    ): Promise<string> {
        const headers = await this.useAuthHeader();
        const response = await fetch(`${apiConfig.backendURL}/ingest-slots/edit-state`, {
            method: "PUT",
            headers: headers,
            body: ingestSlotBooking.serialize(),
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response);
            return "success";
        }

        return "failed";
    }

    public static async updateCurrentIngestSlotBooking(
        ingestSlotBooking: IngestSlotBooking
    ): Promise<string> {
        const headers = await this.useAuthHeader();
        const response = await fetch(`${apiConfig.backendURL}/ingest-slots/edit-state/current`, {
            method: "PUT",
            headers: headers,
            body: ingestSlotBooking.serialize(),
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response);
            return "success";
        }

        return "failed";
    }

    public static async completeOrder(orderId: number): Promise<string> {
        const headers = await this.useAuthHeader();
        const response = await fetch(`${apiConfig.backendURL}/support/complete-order/${orderId}`, {
            method: "PUT",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response);
            return "success";
        }

        return "failed";
    }
    public static async switchIngestSlot(switchJobRequest: SwitchJobRequest): Promise<string> {
        const headers = await this.useAuthHeader();
        const response = await fetch(`${apiConfig.backendURL}/support/switch-ingest-slot`, {
            method: "PUT",
            headers: headers,
            body: switchJobRequest.serialize(),
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response);
            return "success";
        }

        return "failed";
    }

    public static async editProjectAndHolder(
        orderProjectHistory: OrderProjectHistory
    ): Promise<string> {
        const headers = await this.useAuthHeader();
        const response = await fetch(`${apiConfig.backendURL}/orders/history/current/edit`, {
            method: "PUT",
            headers: headers,
            body: orderProjectHistory.serialize(),
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response);
            return "success";
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async cancelOrder(cancellationRequest: CancellationRequest): Promise<string> {
        const headers = await this.useAuthHeader();
        const response = await fetch(`${apiConfig.backendURL}/databox-orders/cancel`, {
            method: "PUT",
            headers: headers,
            body: cancellationRequest.serialize(),
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response);
            return "success";
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async updateDataboxInformationById(
        databox: Databox
    ): Promise<Databox | undefined> {
        const headers = await this.useAuthHeader();
        const response = await fetch(
            `${apiConfig.backendURL}/databox/edit-databox-information/${databox.id}`,
            {
                method: "PUT",
                headers: headers,
                body: databox.serialize(),
            }
        ).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (!response) {
            throw Error("Server send empty message.");
        }

        const body = await response.json();
        const databoxResponse = DataboxResponse.fromSerialized(body);

        await checkStatus(response, undefined, undefined, databoxResponse.error);

        if (!databoxResponse.databoxes) return undefined;

        return databoxResponse.databoxes[0];
    }

    public static async updateInCarMount(inCarMount: InCarMount): Promise<string> {
        const headers = await this.useAuthHeader();
        const response = await fetch(`${apiConfig.backendURL}/in-car-mounts/edit`, {
            method: "PUT",
            headers: headers,
            body: inCarMount.serialize(),
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response);
            return "success";
        }

        return "failed";
    }

    public static async completeCurrentMaintenanceMessage(
        currentMaintenanceMessageId: number
    ): Promise<MaintenanceSchedule | null> {
        const resourceName = "Maintenance Message";
        const headers = await this.useAuthHeader();
        const response = await fetch(
            `${apiConfig.backendURL}/maintenance-message/${currentMaintenanceMessageId}`,
            {
                method: "PUT",
                headers: headers,
            }
        ).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (!response) {
            throw Error("Server send empty message.");
        }

        const body = await response.json();

        const maintenanceScheduleReponse: MaintenanceScheduleResponse | null =
            MaintenanceScheduleResponse.fromSerialized(body);

        await checkStatus(response, resourceName, undefined, maintenanceScheduleReponse?.error);

        if (!maintenanceScheduleReponse) {
            return null;
        }

        return maintenanceScheduleReponse.maintenanceSchedule;
    }

    public static async initializeDatabox(
        initializeDatabox: InitializeDataboxRequest
    ): Promise<string> {
        const headers = await this.useAuthHeader();

        const response = await fetch(`${apiConfig.backendURL}/support/initialize-databox`, {
            method: "PUT",
            headers: headers,
            body: initializeDatabox.serialize(),
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response);
            return "success";
        }
        return "failed";
    }

    public static async getAllTrackers(): Promise<Tracker[]> {
        const resourceName = "TrackerData";

        const headers = await this.useAuthHeader();

        const response = await fetch(`${apiConfig.backendURL}/trackers`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            const returnValue = await checkStatus(response, resourceName, 404);
            if (returnValue === "Special-case 404") return [];

            const body = await response.json();
            return body.map((json: any) => Tracker.fromSerialized(json));
        } else {
            throw Error("Server send empty message.");
        }
    }

    public static async getAllTrackersWithoutData(): Promise<Tracker[]> {
        const resourceName = "TrackerWithoutdData";

        const headers = await this.useAuthHeader();

        const response = await fetch(`${apiConfig.backendURL}/trackers-without-data`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            const returnValue = await checkStatus(response, resourceName, 404);
            if (returnValue === "Special-case 404") return [];

            const body = await response.json();
            return body.map((json: any) => Tracker.fromSerialized(json));
        } else {
            throw Error("Server send empty message.");
        }
    }

    // POST REQUESTS
    public static async addComment(comment: Comment): Promise<string> {
        const headers = await this.useAuthHeader();
        const response = await fetch(apiConfig.backendURL + `/comments/add`, {
            method: "POST",
            headers: headers,
            body: comment.serialize(),
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response);
            return "success";
        }
        return "Failed";
    }

    public static async addDatabox(databox: Databox): Promise<Databox | undefined> {
        const headers = await this.useAuthHeader();
        const response = await fetch(apiConfig.backendURL + `/databoxes/add`, {
            method: "POST",
            headers: headers,
            body: databox.serialize(),
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (!response) {
            throw Error("Server send empty message.");
        }

        const body = await response.json();
        const databoxResponse = DataboxResponse.fromSerialized(body);

        await checkStatus(response, undefined, undefined, databoxResponse.error);

        if (!databoxResponse.databoxes) return undefined;

        return databoxResponse.databoxes[0];
    }
    //Needs to be checked
    public static async addInCarMountComment(comment: InCarMountComment): Promise<string> {
        const headers = await this.useAuthHeader();
        const response = await fetch(apiConfig.backendURL + `/in-car-mounts-comments/add`, {
            method: "POST",
            headers: headers,
            body: comment.serialize(),
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response);
            return "success";
        }
        return "Failed";
    }

    // Set a state of an Ingest Slot like "Reserved", "Maintenance", etc.
    public static async addIngestSlotBooking(
        ingestSlotBooking: IngestSlotBooking
    ): Promise<string> {
        const headers = await this.useAuthHeader();
        const response = await fetch(apiConfig.backendURL + `/ingest-slots/add-state`, {
            method: "POST",
            headers: headers,
            body: ingestSlotBooking.serialize(),
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response);
            return "success";
        }
        return "Failed";
    }

    public static async addDataboxState(databoxBooking: DataboxBooking): Promise<string> {
        const headers = await this.useAuthHeader();
        const response = await fetch(`${apiConfig.backendURL}/databoxes/add-state`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(databoxBooking),
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response);
            return "success";
        }
        return "Failed";
    }

    public static async addProject(project: Project): Promise<string> {
        const headers = await this.useAuthHeader();
        const response = await fetch(apiConfig.backendURL + `/projects/add`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(project),
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response);
            return "success";
        }
        return "Failed";
    }

    public static async addDataboxOrderRequest(
        databoxOrderRequest: DataboxOrderRequest
    ): Promise<DataboxOrderResponse> {
        const headers = await this.useAuthHeader();
        const response = await fetch(apiConfig.backendURL + `/databox-order-request`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(databoxOrderRequest),
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (!response) {
            return new DataboxOrderResponse({ success: false });
        }

        if (response.status == 400) {
            throw Error(await response.text());
        }

        if (response.status == 504) {
            throw Error(
                "Please wait a few minutes and check if your Orders are created correctly. In case you can´t see the expected amount of new Orders, please reduce the number of selected Databoxes and try it again. - 504 Gateway Timeout"
            );
        }

        await checkStatus(response);

        if (response.status == 206) {
            const body = await response.json();
            if (!body) {
                throw Error("Invalid Server Response");
            }

            return new DataboxOrderResponse({
                success: false,
                address: Address.fromSerialized(body) ?? undefined,
            });
        }

        return new DataboxOrderResponse({ success: true });
    }

    public static async addUpsPickup(orderId: number, upsPickup: UpsPickup): Promise<string> {
        const headers = await this.useAuthHeader();
        const response = await fetch(apiConfig.backendURL + `/pickup/${orderId}`, {
            method: "POST",
            headers: headers,
            body: upsPickup.serialize(),
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response, "", 504);
            return "success";
        }
        return "Failed";
    }

    public static async addPackageDropOff(
        orderId: number,
        packageDropOff: PackageDropOff
    ): Promise<PackageDropOff | null> {
        const headers = await this.useAuthHeader();
        const response = await fetch(apiConfig.backendURL + `/package-drop-off/${orderId}`, {
            method: "POST",
            headers: headers,
            body: packageDropOff.serialize(),
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (!response) {
            return null;
        }

        const body = await response.json();
        const packageDropOffResponse: PackageDropOffResponse | null =
            PackageDropOffResponse.fromSerialized(body);

        await checkStatus(response, undefined, undefined, packageDropOffResponse?.error);

        if (!packageDropOffResponse) {
            return null;
        }

        return packageDropOffResponse.packageDropOff;
    }

    public static async raiseJiraTicket(jiraTicket: JiraTicket): Promise<string> {
        const headers = await this.useAuthHeader();
        const response = await fetch(apiConfig.backendURL + `/raise-jira-ticket`, {
            method: "POST",
            headers: headers,
            body: jiraTicket.serialize(),
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            if (response.status == 500) throw new Error(await response.text());
            await checkStatus(response);
            const ticketId = await response.text();
            return "Success" + ticketId;
        }
        return "Failed";
    }

    public static async abortIngest(ingestEvent: IngestEvent): Promise<string> {
        const headers = await this.useAuthHeader();
        const response = await fetch(apiConfig.backendURL + `/ingest/abort-status`, {
            method: "POST",
            headers: headers,
            body: ingestEvent.serialize(),
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            if (response.status == 500) throw new Error(await response.text());
            await checkStatus(response);
            return "success";
        }
        return "failed";
    }

    public static async cancelIngest(cancelRequest: CancelRequest): Promise<string> {
        const headers = await this.useAuthHeader();
        const response = await fetch(apiConfig.backendURL + `/ingest/cancel`, {
            method: "POST",
            headers: headers,
            body: cancelRequest.serialize(),
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            if (response.status == 500) throw new Error(await response.text());
            await checkStatus(response);
            return "success";
        }
        return "failed";
    }

    public static async createNonStandardRequestLabel(
        pickupAddress: Address,
        pickupAddressValidation: boolean,
        deliveryAddress: Address,
        deliveryAddressValidation: boolean,
        databoxName: string,
        jiraTicket: string
    ): Promise<NonStandardRequestResponse> {
        const headers = await this.useAuthHeader();
        const response = await fetch(apiConfig.backendURL + `/non-standard-request/label`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                pickupAddress,
                pickupAddressValidation,
                deliveryAddress,
                deliveryAddressValidation,
                jiraTicket,
                databoxName,
            }),
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });
        if (response) {
            const body = await response.json();
            const serializedResponse = NonStandardRequestResponse.fromSerialized(body);
            return serializedResponse;
        }
        return new NonStandardRequestResponse({ error: "Sorry! Something unexpected went wrong!" });
    }

    public static async createNonStandardRequestPickup(
        pickupAddress: Address,
        pickupAddressValidation: boolean,
        databoxName: string,
        jiraTicket: string,
        pickupDate: string
    ): Promise<NonStandardRequestResponse> {
        const headers = await this.useAuthHeader();
        const response = await fetch(apiConfig.backendURL + `/non-standard-request/pickup`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                pickupAddress,
                pickupAddressValidation,
                jiraTicket,
                databoxName,
                pickupDate,
            }),
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });
        if (response) {
            const body = await response.json();
            const serializedResponse = NonStandardRequestResponse.fromSerialized(body);
            return serializedResponse;
        }
        return new NonStandardRequestResponse({ error: "Sorry! Something unexpected went wrong!" });
    }

    public static async pauseIngestJob(
        ingestJobId: number,
        reasoning: string,
        jiraTicketId: string
    ): Promise<string> {
        const headers = await this.useAuthHeader();
        const response = await fetch(apiConfig.backendURL + `/ingest-job/pause`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ ingestJobId, reasoning, jiraTicketId }),
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response);
            return "success";
        }
        return "failed";
    }

    public static async restartIngestJob(orderId: number): Promise<string> {
        const headers = await this.useAuthHeader();
        const response = await fetch(apiConfig.backendURL + `/ingest-job/restart/${orderId}`, {
            method: "POST",
            headers: headers,
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (response) {
            await checkStatus(response);
            return "success";
        }
        return "failed";
    }

    public static async addNewMaintenanceMessage(
        maintenanceSchedule: MaintenanceSchedule
    ): Promise<MaintenanceSchedule | null> {
        const headers = await this.useAuthHeader();
        const response = await fetch(apiConfig.backendURL + `/maintenance-message`, {
            method: "POST",
            headers: headers,
            body: maintenanceSchedule.serialize(),
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });

        if (!response) {
            return null;
        }

        const body = await response.json();
        const maintenanceScheduleReponse: MaintenanceScheduleResponse | null =
            MaintenanceScheduleResponse.fromSerialized(body);

        await checkStatus(response, undefined, undefined, maintenanceScheduleReponse?.error);

        if (!maintenanceScheduleReponse) {
            return null;
        }

        return maintenanceScheduleReponse.maintenanceSchedule;
    }
    // DELETE REQUESTS
    public static async deleteDataboxState(stateId: number): Promise<string> {
        const headers = await this.useAuthHeader();
        const response = await fetch(`${apiConfig.backendURL}/databoxes/delete-state`, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(stateId),
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });
        if (response) {
            await checkStatus(response);
            return "success";
        }
        return "Failed";
    }

    public static async deleteIngestSlotBooking(ingestSlotBookingId: number): Promise<string> {
        const headers = await this.useAuthHeader();
        const response = await fetch(`${apiConfig.backendURL}/ingest-slots/delete-state`, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(ingestSlotBookingId),
        }).catch((error) => {
            if (error instanceof TypeError) throw Error("Cannot reach server");
        });
        if (response) {
            await checkStatus(response);
            return "success";
        }
        return "failed";
    }
};

async function checkStatus(
    response: Response,
    resourceName?: string,
    specialCase?: number,
    errorMessage?: string
) {
    const resource = resourceName ? resourceName : "";

    if (!response.ok) {
        switch (response.status) {
            case 400: {
                if (errorMessage) throw Error(`${response.status}: ${errorMessage} - Bad request`);
                throw Error(`${response.status}: ${await response.text()} - Bad request`);
            }
            case 401: {
                throw Error(`SPECIALCASE`);
            }
            case 403: {
                throw Error(`${response.status}: ${await response.text()} - Forbidden`);
            }
            case 404: {
                if (specialCase === 404) return "Special-case 404";
                else
                    throw Error(
                        `${response.status}: ${await response.text()} - ${resource} Not Found`
                    );
            }
            case 500: {
                throw Error(`${response.status}: ${await response.text()} - Internal Server Error`);
            }
            case 503: {
                throw Error(`${response.status}: ${await response.text()} - Service Unavailable`);
            }
            case 504: {
                if (specialCase === 504) return "Special-case 504";
                else throw Error(`${response.status}: ${await response.text()} - Gateway Timeout`);
            }
            default: {
                //return true;
                throw Error(`${response.status} - An error occurred`);
            }
        }
    }
}
