import React, { Fragment, useContext, useEffect, useState } from "react";
import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { GroupuiIcon, GroupuiDivider, GroupuiTooltip, GroupuiLink } from "@group-ui/group-ui-react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { DataboxBooking } from "../../models/databoxBooking";
import { HistoryEntry } from "../../models/historyEntry";
import { ApiService } from "../../services/apiService";
import { parseDate, parseFullDate } from "../../utils/DateUtil";
import { RightsWrapper } from "../container/RightsWrapper";
import ErrorContext from "../context/ErrorContext";
import { handleError } from "../context/ErrorContextUtils";
import UserGroupContext from "../context/UserGroupContext";
import GenericHeadline from "../generics/GenericHeadline";
import GenericTable, { columnDefinition } from "../generics/GenericTable";
import GenericTag from "../generics/GenericTag";
import Snackbar from "../generics/Snackbar";
import DeleteStateModal from "./modals/DeleteStateModal";
import EditStateModal from "./modals/EditStateModal";
import { Databox } from "../../models/databox";
import { RightsManagement } from "../../config/rightsManagement";
import IngestDetails from "../dashboard/IngestDetails";
import { IngestSlotMonitorData } from "../../models/ingestSlotMonitorData";
import { MediaStateType } from "../../enums/MediaStateType";
import { isInUserGroup } from "../../utils/RightsUtils";

const styles = {
    magnifyingGlass: {
        cursor: "pointer",
        color: "var(--groupui-vwag-color-petrol-800-primary)",
    },
    stateContainer: {
        marginBottom: "var(--groupui-sys-spacing-600)",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "var(--groupui-sys-spacing-800)",
    } as React.CSSProperties,
    detailsIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
};

const columns: columnDefinition[] = [
    {
        key: "created",
        header: "Created",
        width: "auto",
    },
    {
        key: "activity",
        header: "Activity",
        width: "auto",
    },
    {
        key: "details",
        header: "Details",
        width: "auto",
    },
    {
        key: "comment",
        header: "Comment",
        width: "auto",
    },
    {
        key: "button1",
        width: "4rem",
    },
    {
        key: "button2",
        width: "4rem",
    },
];

/**
 * A StatusTab Component, which displays the status of the given databox
 *
 * @returns StatusTab Component
 *
 */
function StatusTab() {
    const { databoxID } = useParams();
    const { inProgress } = useMsal();
    const [isLoading, setIsLoading] = useState(true);
    const [currentFutureStates, setCurrentFutureStates] = useState<HistoryEntry[]>([]);
    const [databoxHistoryData, setDataboxHistoryData] = useState<HistoryEntry[] | null>();
    const [databoxData, setDataboxData] = useState<Databox>();
    const [currentDataboxBookingData, setCurrentDataboxBookingData] =
        useState<DataboxBooking | null>();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedEntryId, setSelectedEntryId] = useState<number>(-1);

    const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"danger" | "success" | "warning">();
    const [message, setMessage] = useState<string>();

    const navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const [bookingData, setBookingData] = useState<HistoryEntry | null>();
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const { userGroups } = useContext(UserGroupContext);
    const [ingestSlotData, setIngestSlotData] = useState<IngestSlotMonitorData>();
    const [showIngestSlotData, setShowIngestSlotData] = useState<boolean>(false);

    useEffect(() => {
        getHistoryData();
        if (inProgress === InteractionStatus.None) {
            getDataboxData();
        }
        if (currentDataboxBookingData == null && inProgress === InteractionStatus.None) {
            ApiService.getCurrentBookingByDataboxId(Number(databoxID))
                .then((response) => {
                    setCurrentDataboxBookingData(response);
                    setIsLoading(false);
                })
                .catch((error) => {
                    handleError(error, changeErrorMessage, changeShowError);
                    setIsLoading(false);
                });
        }
    }, [inProgress, databoxID]);

    const getHistoryData = () => {
        ApiService.getDataboxHistoryByDataboxID(Number(databoxID))
            .then((response) => {
                if (response) {
                    const currentFuture = response.filter((entry: HistoryEntry) => {
                        return (
                            entry.started !== null &&
                            Date.parse(entry.finished ?? new Date().toISOString()) >= Date.now()
                        );
                    });

                    const history = response.filter((entry: HistoryEntry) => {
                        return Date.parse(entry.finished) < Date.now();
                    });

                    setCurrentFutureStates(currentFuture);
                    setDataboxHistoryData(history);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
                setIsLoading(false);
            });
    };

    const getDataboxData = () => {
        ApiService.getDataboxById(Number(databoxID))
            .then((response) => {
                setDataboxData(response);
                setIsLoading(false);
                return response;
            })
            .then((databox) => {
                if (
                    databox.mediaStateType.mediaStateType === MediaStateType.PRE_UPLOAD ||
                    databox.mediaStateType.mediaStateType === MediaStateType.UPLOADING ||
                    databox.mediaStateType.mediaStateType === MediaStateType.UPLOAD_COMPLETED ||
                    databox.mediaStateType.mediaStateType === MediaStateType.ERROR
                ) {
                    if (databox.ingestStation) {
                        ApiService.getIngestSlotMonitorDataForIngestStation(
                            databox.ingestStation.id
                        ).then((response) => {
                            const ingestSlotMonitorData = response.find((monitorData) =>
                                databox.mediaStateType.mediaStateType === MediaStateType.ERROR
                                    ? monitorData.databoxMediaState ===
                                      databox.mediaStateType.mediaStateType
                                    : monitorData.databoxId === databox.id &&
                                      monitorData.databoxMediaState ===
                                          databox.mediaStateType.mediaStateType
                            );
                            setIngestSlotData(ingestSlotMonitorData);
                        });
                    }
                }
            })
            .catch((error) => {
                navigate("/not-found");
                handleError(error, changeErrorMessage, changeShowError);
                setIsLoading(false);
            });
    };

    const checkMediaState = (mediaState: string): boolean => {
        return (
            mediaState.toLowerCase() === "decommissioned" ||
            mediaState.toLowerCase() === "maintenance" ||
            mediaState.toLowerCase() === "in stock" ||
            mediaState.toLowerCase() === "reserved"
        );
    };

    const checkIfFuture = (historyEntry: HistoryEntry): boolean => {
        return (
            historyEntry.started !== null &&
            Date.parse(historyEntry.finished ?? new Date().toISOString()) >= Date.now()
        );
    };

    const onModalClose = () => {
        setShowModal(false);
        setSelectedEntryId(-1);
    };

    const onModalOpen = (historyEntryId: number) => {
        setShowModal(true);
        setSelectedEntryId(historyEntryId);
    };

    const currentFutureData = currentFutureStates
        .sort((a: any, b: any) => {
            return Date.parse(b.created) - Date.parse(a.created);
        })
        .map((entry: HistoryEntry) => ({
            created: parseFullDate(entry.created),
            activity: (
                <GenericTag
                    text={entry.mediaStateType.mediaStateType}
                    variant="primary"
                    mediaStateTarget="databox"
                />
            ),
            details: generateHistoryDetails(entry, userGroups, navigate),
            comment: entry.comment ? entry.comment : "",
            button1: (
                <RightsWrapper
                    authorizedUserGroup={RightsManagement.EDIT_DATABOX_STATE}
                    userGroups={userGroups}
                >
                    {checkMediaState(entry.mediaStateType.mediaStateType) &&
                    checkIfFuture(entry) ? (
                        <GroupuiTooltip position="left" zIndex={100}>
                            <GroupuiIcon
                                style={styles.magnifyingGlass}
                                name="edit-32"
                                onClick={() => {
                                    setModal(true);
                                    ApiService.getHistoryEntryByDataboxIdAndUuid(
                                        Number(databoxID),
                                        entry.uuid
                                    )
                                        .then((response) => {
                                            setBookingData(response);
                                        })
                                        .catch((error) => {
                                            handleError(error, changeErrorMessage, changeShowError);
                                            setIsLoading(false);
                                        });
                                }}
                            />
                            <div slot="tooltip">Edit</div>
                        </GroupuiTooltip>
                    ) : (
                        <></>
                    )}
                </RightsWrapper>
            ),
            button2: (
                <RightsWrapper
                    authorizedUserGroup={RightsManagement.DELETE_DATABOX_STATE}
                    userGroups={userGroups}
                >
                    {checkMediaState(entry.mediaStateType.mediaStateType) &&
                    checkIfFuture(entry) ? (
                        <GroupuiTooltip position="left">
                            <GroupuiIcon
                                style={styles.magnifyingGlass}
                                name="trash-32"
                                onClick={() => onModalOpen(entry.id)}
                            />
                            <div slot="tooltip">Delete</div>
                        </GroupuiTooltip>
                    ) : (
                        <></>
                    )}
                </RightsWrapper>
            ),
        }));

    const historyData = (databoxHistoryData ?? [])
        .sort((a: any, b: any) => {
            return Date.parse(b.created) - Date.parse(a.created);
        })
        .map((historyEntry: HistoryEntry) => ({
            created: parseFullDate(historyEntry.created),
            activity: (
                <GenericTag
                    text={historyEntry.mediaStateType.mediaStateType}
                    variant="primary"
                    mediaStateTarget="databox"
                />
            ),
            details: generateHistoryDetails(historyEntry, userGroups, navigate),
            comment: historyEntry.comment ? historyEntry.comment : "",
        }));

    return (
        <div>
            <RightsWrapper
                key={"rights-first"}
                authorizedUserGroup={RightsManagement.OPEN_MODAL_DATABOX_STATE}
                userGroups={userGroups}
            >
                <EditStateModal modal={modal} setModal={setModal} bookingData={bookingData} />
                <DeleteStateModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    onModalClose={onModalClose}
                    selectedEntryId={selectedEntryId}
                />
            </RightsWrapper>
            <GenericHeadline
                title="Current & Future States"
                titleHeadingType="h4"
                actionButtons={[]}
            />
            <div style={styles.stateContainer}>
                <GenericTag
                    text={databoxData?.mediaStateType.mediaStateType ?? ""}
                    variant="primary"
                    mediaStateTarget="databox"
                />
                {databoxData?.mediaStateType.mediaStateType == MediaStateType.FREE ? (
                    ""
                ) : databoxData?.mediaStateType.mediaStateType == MediaStateType.RESERVED ? (
                    <p>{`Current Owner: ${databoxData.owner ?? ""}, Current User: ${
                        currentDataboxBookingData?.reservedBy
                    }, Shipping Date: ${parseFullDate(currentDataboxBookingData?.created)}`}</p>
                ) : databoxData?.mediaStateType.mediaStateType === MediaStateType.UPLOADING ||
                  databoxData?.mediaStateType.mediaStateType === MediaStateType.PRE_UPLOAD ||
                  databoxData?.mediaStateType.mediaStateType === MediaStateType.UPLOAD_COMPLETED ||
                  databoxData?.mediaStateType.mediaStateType === MediaStateType.ERROR ? (
                    <GroupuiTooltip position="right" style={styles.detailsIcon}>
                        <GroupuiIcon
                            name="search-plus-32"
                            onClick={() => setShowIngestSlotData(true)}
                            style={styles.magnifyingGlass}
                        />
                        <div slot="tooltip">Show Details</div>
                    </GroupuiTooltip>
                ) : (
                    <></>
                )}
            </div>

            <GroupuiDivider />

            <GenericTable columns={columns} data={currentFutureData} header={true} pageSize={5} />

            <GroupuiDivider />
            <GenericHeadline title="History" titleHeadingType="h4" actionButtons={[]} />
            <GenericTable columns={columns} data={historyData} header={true} pageSize={5} />
            {showSnackBar && (
                <Snackbar
                    severity={severity ?? "danger"}
                    message={message ?? "Some error occurred!"}
                    onClose={() => {
                        setShowSnackBar(false);
                    }}
                />
            )}
            {ingestSlotData && (
                <IngestDetails
                    showDetails={showIngestSlotData}
                    onClose={() => setShowIngestSlotData(false)}
                    ingestSlotMonitorData={ingestSlotData}
                />
            )}
        </div>
    );
}

const generateHistoryDetails = (
    historyEntry: HistoryEntry,
    userGroups: string[],
    navigate: NavigateFunction
) => {
    const displayArray = [];
    const showLinks = isInUserGroup(RightsManagement.OPERATION_INGESTS, userGroups);
    const mediaStateType = historyEntry.mediaStateType.mediaStateType;

    const fromStartDate = `From: ${parseDate(new Date(historyEntry.started))}`;
    const toFinishedDate = historyEntry.finished
        ? `To: ${parseDate(new Date(historyEntry.finished))}`
        : null;
    const byUser = historyEntry.reservedBy ? `By: ${historyEntry.reservedBy}` : null;
    const projectName = historyEntry.project ? `Project: ${historyEntry.project.name}` : null;
    const orderId = historyEntry.ingest?.ingestSlot?.ingestSlotDetails?.orderId;

    let orderIdMsg: JSX.Element | string = "";
    let ingestIdMsg: JSX.Element | string = "";
    let ingestSlotName: JSX.Element | string = "";

    if (orderId) {
        orderIdMsg = showLinks ? (
            <span>
                Order ID:{" "}
                <GroupuiLink
                    quiet={true}
                    onClick={() => navigate(`/databox-orders/${orderId}/order-details`)}
                >
                    {orderId}
                </GroupuiLink>
            </span>
        ) : (
            `Order ID: ${orderId}`
        );
    }

    if (historyEntry.ingest) {
        ingestIdMsg = showLinks ? (
            <span>
                Ingest ID:{" "}
                <GroupuiLink
                    quiet={true}
                    onClick={() =>
                        navigate("/ingests", {
                            state: { ingestId: historyEntry.ingest?.ID },
                        })
                    }
                >
                    {historyEntry.ingest.ID}
                </GroupuiLink>
            </span>
        ) : (
            `Ingest ID: ${historyEntry.ingest.ID}`
        );
    }

    if (historyEntry.ingest?.ingestSlot) {
        ingestSlotName = showLinks ? (
            <span>
                Ingest Slot:{" "}
                <GroupuiLink
                    quiet={true}
                    onClick={() =>
                        navigate(
                            `/ingest-stations/${historyEntry.ingest?.ID}/${historyEntry.ingest?.ingestSlot?.id}`
                        )
                    }
                >
                    {historyEntry.ingest.ingestSlot.name}
                </GroupuiLink>
            </span>
        ) : (
            `Ingest Slot: ${historyEntry.ingest.ingestSlot.name}`
        );
    }

    if (mediaStateType === MediaStateType.RESERVED || mediaStateType === MediaStateType.IN_STOCK) {
        if (fromStartDate) displayArray.push(fromStartDate);
        if (toFinishedDate) displayArray.push(toFinishedDate);
        if (byUser) displayArray.push(byUser);
        if (projectName) displayArray.push(projectName);
        return HistoryDetailsMsg(displayArray);
    }

    if (
        mediaStateType === MediaStateType.MAINTENANCE ||
        mediaStateType === MediaStateType.DECOMMISSIONED
    ) {
        const ticketID = historyEntry.ticketId ? `Ticket ID: ${historyEntry.ticketId}` : null;
        if (fromStartDate) displayArray.push(fromStartDate);
        if (toFinishedDate) displayArray.push(toFinishedDate);
        if (byUser) displayArray.push(byUser);
        if (ticketID) displayArray.push(ticketID);
        return HistoryDetailsMsg(displayArray);
    }

    if (mediaStateType.toLowerCase() === "ordered") {
        const prepForIngest = historyEntry.ingest?.preUploadStarted
            ? `Preparation for Ingest: ${parseDate(new Date(historyEntry.ingest.preUploadStarted))}`
            : null;
        if (orderId) displayArray.push(orderIdMsg);
        if (projectName) displayArray.push(projectName);
        if (prepForIngest) displayArray.push(prepForIngest);
        return HistoryDetailsMsg(displayArray);
    }

    if (mediaStateType === MediaStateType.UPLOAD_COMPLETED) {
        const volume = historyEntry.ingest?.dataVolume
            ? `Volume: ${historyEntry.ingest.dataVolume}`
            : null;

        if (orderId) displayArray.push(orderIdMsg);
        if (ingestIdMsg) displayArray.push(ingestIdMsg);
        if (ingestSlotName) displayArray.push(ingestSlotName);
        if (volume) displayArray.push(volume);
        if (projectName) displayArray.push(projectName);
        return HistoryDetailsMsg(displayArray);
    }
    return "";
};

const HistoryDetailsMsg = (array: (string | JSX.Element)[]) => {
    return (
        <div>
            {array.map((element, index) => {
                if (index === array.length - 1) {
                    return <Fragment key={index}>{element}</Fragment>;
                }
                return <Fragment key={index}>{element}, </Fragment>;
            })}
        </div>
    );
};

export default StatusTab;
