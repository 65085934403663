import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiService } from "../../services/apiService";
import ErrorContext from "../context/ErrorContext";
import { handleError } from "../context/ErrorContextUtils";
import { OrderProjectHistory } from "../../models/orderProjectHistory";
import { GroupuiLoadingSpinner } from "@group-ui/group-ui-react";
import { GeneralInformation } from "./GeneralInformation";

interface Props {
    editMode: boolean;
    handleEditMode: () => void;
}

/**
 * A GeneralInformationTab Component, which displays the general-information of the given databox
 *
 * @returns GeneralInformationTab Component
 *
 */
const GeneralInformationTab: React.FC<Props> = ({ editMode, handleEditMode }) => {
    const { databoxID } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [orderProjectHistory, setOrderProjectHistory] = useState<OrderProjectHistory | null>();
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);

    useEffect(() => {
        getData();
    }, [databoxID]);

    const getData = async () => {
        setIsLoading(true);
        try {
            setOrderProjectHistory(
                await ApiService.getCurrentOrderProjectHistoryByDataboxId(Number(databoxID))
            );
        } catch (error) {
            handleError("", changeErrorMessage, changeShowError);
        }
        setIsLoading(false);
    };

    return (
        <div>
            <GroupuiLoadingSpinner displayed={isLoading} />
            <GeneralInformation
                editDatabox={editMode}
                handleEditDatabox={handleEditMode}
                orderProjectHistory={orderProjectHistory ?? undefined}
            />
        </div>
    );
};

export default GeneralInformationTab;
