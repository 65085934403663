export class InitializeDataboxRequest {
    public ingestSlotId: number;
    public databoxId: number;
    constructor({ ingestSlotId, databoxId }: { ingestSlotId: number; databoxId: number }) {
        this.ingestSlotId = ingestSlotId;
        this.databoxId = databoxId;
    }

    private toObject() {
        return {
            ingestSlotId: this.ingestSlotId,
            databoxId: this.databoxId,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }
}
