import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import {
    GroupuiButton,
    GroupuiLoadingSpinner,
    GroupuiModal,
    GroupuiMultiselect,
    GroupuiMultiselectOption,
    GroupuiSelect,
    GroupuiSelectOption,
} from "@group-ui/group-ui-react";
import GenericHeadline from "../../generics/GenericHeadline";
import GenericInput from "../../generics/GenericInput";
import { ApiService } from "../../../services/apiService";
import { handleError } from "../../context/ErrorContextUtils";
import { IngestStation } from "../../../models/ingestStation";
import ErrorContext from "../../context/ErrorContext";
import { DataboxPoolContext } from "../../context/provider/DataboxPoolContext";
import { PortType } from "../../../models/portType";
import { isInUserGroup } from "../../../utils/RightsUtils";
import { RightsManagement } from "../../../config/rightsManagement";
import UserGroupContext from "../../context/UserGroupContext";
import { storageCapacityValues } from "../../../constants/storageCapacity";
import { databoxOwners } from "../../../constants/databoxOwner";
import { Tracker } from "../../../models/tracker";
import TrackerListModal from "./TrackerListModal";
import Snackbar from "../../generics/Snackbar";
import { Databox } from "../../../models/databox";
import { MediaState } from "../../../models/mediaState";
import GenericDateTimePicker from "../../generics/GenericDateTimePicker";

const styles = {
    modalStyle: {
        justifyContent: "center",
        alignItems: "center",
    } as React.CSSProperties,
    selectField: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "var(--groupui-sys-spacing-600)",
    } as React.CSSProperties,
    modalActionButtons: {
        display: "flex",
        justifyContent: "end",
        gap: "var(--groupui-sys-spacing-400)",
    } as React.CSSProperties,
    button: {
        marginBottom: "var(--groupui-sys-spacing-400)",
    } as React.CSSProperties,
};

interface AddDataboxModalProps {
    modal: boolean;
    setModal: Dispatch<SetStateAction<boolean>>;
}

interface IFormData {
    name?: string;
    serialNumber?: string;
    ingestStation?: IngestStation;
    owner?: string;
    storageCapacity?: string;
    connectorType?: PortType[];
    tracker?: Tracker;
    carnetNumber?: string;
    carnetExpirationDate?: string;
}

export const AddDataboxModal: React.FC<AddDataboxModalProps> = ({ modal, setModal }) => {
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const { userGroups } = useContext(UserGroupContext);
    const { databoxPool } = useContext(DataboxPoolContext);

    const [isLoading, setIsLoading] = useState(false);

    // Manage disabled fields
    const [disabled, setDisabled] = useState<boolean>(
        !isInUserGroup([RightsManagement.SUPPORT], userGroups)
    );

    // Holds state of the tracker selection list
    const [showTrackerList, setShowTrackerList] = useState(false);

    // States to handle the snackbar
    const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"danger" | "success" | "warning">();
    const [message, setMessage] = useState<string>();

    // Validation check
    const [isValid, setValid] = useState<boolean>(false);

    // Initial state for the form
    const initialState: IFormData = {
        name: undefined,
        serialNumber: undefined,
        ingestStation: undefined,
        owner: undefined,
        storageCapacity: undefined,
        connectorType: undefined,
        tracker: undefined,
        carnetNumber: undefined,
        carnetExpirationDate: undefined,
    };

    // Data used in all the fields
    const [ingestStations, setIngestStations] = useState<IngestStation[]>();
    const [portTypes, setPortTypes] = useState<PortType[]>([]);
    const [formData, setFormData] = useState<IFormData>(initialState);
    const [portTypeChecked, setPortTypeChecked] = useState<boolean[]>([]);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (
            !formData.name ||
            !formData.serialNumber ||
            !formData.ingestStation ||
            !formData.owner ||
            !formData.storageCapacity
        ) {
            setValid(false);
        } else {
            setValid(true);
        }
    }, [formData]);

    useEffect(() => {
        setIngPredefinedValues();
    }, [ingestStations]);

    // Get data for available Ingest Stations and Port Types
    const getData = async () => {
        setIsLoading(true);
        await Promise.all([
            ApiService.getAllIngestStations()
                .then((ingestStations) => {
                    const sortedData = ingestStations.sort((a, b) => a.name.localeCompare(b.name));
                    setIngestStations(sortedData);
                })
                .catch((error) => {
                    handleError(error, changeErrorMessage, changeShowError);
                }),
            ApiService.getAllPortTypes()
                .then((portTypes: PortType[]) => {
                    setPortTypes(portTypes);
                })
                .catch((error) => {
                    handleError(error, changeErrorMessage, changeShowError);
                }),
        ]).finally(() => setIsLoading(false));
    };

    const setIngPredefinedValues = async () => {
        if (!isInUserGroup([RightsManagement.SUPPORT], userGroups))
            setFormData((prevFormData) => ({
                ...prevFormData,
                owner: "ING",
                ingestStation: ingestStations?.find((ingestStation) => ingestStation.name == "ING"),
            }));
    };

    // Handle general input change of input fields and some select fields
    const handleInputChange = (event: any) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    // Handle multiselection of connector types
    const handleConnectorTypeChange = (event: any, index: number) => {
        if (event.target.checked) {
            // Add portType to the list
            setFormData((prevFormData) => {
                const selectedPortType = portTypes?.find(
                    (portType) => portType.id === Number(event.target.value)
                );

                // Check if the selected port type already exists in the array
                const isAlreadyAdded = prevFormData.connectorType?.some(
                    (connector) => connector.id === selectedPortType?.id
                );

                return {
                    ...prevFormData,
                    connectorType:
                        selectedPortType && !isAlreadyAdded
                            ? [...(prevFormData.connectorType || []), selectedPortType]
                            : prevFormData.connectorType,
                };
            });
        } else {
            // Remove portType from the list
            setFormData((prevFormData) => {
                return {
                    ...prevFormData,
                    connectorType: prevFormData.connectorType?.filter(
                        (connector) => connector.id !== Number(event.target.value)
                    ),
                };
            });
        }
        setPortTypeChecked((prevPortTypeChecked) => {
            const updatePortTypeChecked = [...prevPortTypeChecked];
            updatePortTypeChecked[index] = event.target.checked;

            return updatePortTypeChecked;
        });
    };

    // Handle the selection of the datepicker
    const handleDatePickerChange = (value: any) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            carnetExpirationDate: value,
        }));
    };

    // Handle Trackert selection from List
    const toggleTrackerList = () => {
        setShowTrackerList((prevState) => !prevState);
    };

    // Handle Trackert selection from List
    const handleTrackerSelect = (tracker: Tracker) => {
        if (tracker) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                tracker: tracker,
            }));
        }
        setShowTrackerList(false);
    };

    // Handle the cancel/close of the modal
    const handleCancel = () => {
        setModal(false);
        setFormData(
            isInUserGroup([RightsManagement.SUPPORT], userGroups)
                ? initialState
                : {
                      ...[initialState],
                      owner: "ING",
                      ingestStation: ingestStations?.find(
                          (ingestStation) => ingestStation.name == "ING"
                      ),
                  }
        );
        setPortTypeChecked(Array(portTypes.length).fill(false));
    };

    const handleValidation = (): boolean => {
        const ingestStation = ingestStations?.find(
            (ingestStation) => ingestStation.id == formData.ingestStation?.id
        );

        if (!isValid) {
            setShowSnackBar(true);
            setSeverity("danger");
            setMessage("Basic validation checks failed.");
            setIsLoading(false);
            return false;
        }

        if (databoxPool?.find((databox) => databox.name == formData.name) !== undefined) {
            setShowSnackBar(true);
            setSeverity("danger");
            setMessage("This name is already used for another Databox.");
            setIsLoading(false);
            return false;
        }

        if (
            databoxPool?.find(
                (databox) =>
                    databox.serialNumber == formData.serialNumber &&
                    (databox.ingestStation == null ||
                        (ingestStation !== undefined &&
                            databox.ingestStation.name == ingestStation.name))
            )
        ) {
            setShowSnackBar(true);
            setSeverity("danger");
            setMessage(
                "This Serial Number in combination with Ingest Station is already used for another Databox."
            );
            setIsLoading(false);
            return false;
        }

        return true;
    };

    // Handle the submit of the new databox
    const handleSubmit = async () => {
        setIsLoading(true);

        if (!handleValidation()) return;

        const newDatabox = new Databox({
            id: -1,
            name: formData.name!,
            serialNumber: formData.serialNumber!,
            owner: formData.owner!,
            mediaStateType: new MediaState({
                id: -1,
                mediaStateType: "Free",
                priority: -1,
            }),
            storageCapacity: Number(formData.storageCapacity),
            ingestStation: formData.ingestStation ?? null,
            tracker: formData.tracker ?? undefined,
            databoxPortTypes: formData.connectorType,
            carnetNumber: formData.carnetNumber,
            carnetExpirationDate: formData.carnetExpirationDate
                ? new Date(formData.carnetExpirationDate).toISOString()
                : undefined,
        });

        await ApiService.addDatabox(newDatabox)
            .then((databox: Databox | undefined) => {
                if (!databox) {
                    setShowSnackBar(true);
                    setSeverity("danger");
                    setMessage("Databox couldn`t be added!");
                    return;
                }

                setSeverity("success");
                setMessage("Successfully created a new Databox!");
                setShowSnackBar(true);
                setTimeout(() => {
                    location.reload();
                }, 2000);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <GroupuiModal
            closeButton={true}
            padding={"var(--groupui-sys-spacing-800)"}
            overflow={"auto"}
            displayed={modal}
            style={styles.modalStyle}
            onGroupuiModalClose={handleCancel}
        >
            <GroupuiLoadingSpinner displayed={isLoading} />
            {showSnackBar && (
                <Snackbar
                    severity={severity ?? "danger"}
                    message={message ?? "Some error occurred!"}
                    onClose={() => {
                        setShowSnackBar(false);
                    }}
                />
            )}
            {showTrackerList && (
                <TrackerListModal
                    showModal={showTrackerList}
                    onSelectTracker={handleTrackerSelect}
                    onModalClose={toggleTrackerList}
                />
            )}
            <GenericHeadline
                title="Add Databox"
                actionButtons={[]}
                titleHeadingType={"h3"}
                color={"var(--groupui-sys-color-brand-core)"}
                type={"modal"}
            />
            <GenericInput
                label={"Name *"}
                name={"name"}
                type={"text"}
                pattern={"[A-Za-z0-9]+"}
                maxLength={50}
                severity={"none"}
                required={true}
                disabled={false}
                value={formData.name}
                onGroupuiChange={handleInputChange}
            />
            <GenericInput
                label={"Serial Number *"}
                name={"serialNumber"}
                type={"text"}
                pattern={"[A-Za-z0-9]+"}
                maxLength={50}
                severity={"none"}
                required={true}
                disabled={false}
                value={formData.serialNumber}
                onGroupuiChange={handleInputChange}
            />
            <GroupuiSelect
                name={"ingestStation"}
                typeof={"input"}
                severity={"none"}
                maxHeight={"10rem"}
                style={styles.selectField}
                required={true}
                disabled={disabled}
                value={String(formData.ingestStation?.id)}
                onGroupuiChange={(event) => {
                    const { name, value } = event.target;
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        [name]: ingestStations?.find(
                            (ingestStation) => ingestStation.id == Number(value)
                        ),
                    }));
                }}
            >
                {ingestStations?.map((ingestStation, index) => {
                    return (
                        <GroupuiSelectOption
                            value={String(ingestStation.id)}
                            key={`select-option-${index}`}
                        >
                            {ingestStation.name}
                        </GroupuiSelectOption>
                    );
                })}
                <span slot="label">Ingest Station *</span>
            </GroupuiSelect>
            <GroupuiSelect
                name={"owner"}
                typeof={"input"}
                severity={"none"}
                maxHeight={"10rem"}
                style={styles.selectField}
                required={true}
                disabled={disabled}
                value={formData.owner}
                onGroupuiChange={handleInputChange}
            >
                {databoxOwners.map((e) => (
                    <GroupuiSelectOption value={e} key={e}>
                        {e}
                    </GroupuiSelectOption>
                ))}
                <GroupuiSelectOption value={undefined} key={"empty-value"}>
                    {"<no owner>"}
                </GroupuiSelectOption>
                <span slot="label">Owner</span>
            </GroupuiSelect>
            <GroupuiSelect
                name={"storageCapacity"}
                typeof={"input"}
                severity={"none"}
                maxHeight={"10rem"}
                style={styles.selectField}
                required={true}
                disabled={false}
                value={String(formData.storageCapacity)}
                onGroupuiChange={handleInputChange}
            >
                {storageCapacityValues.map((e) => (
                    <GroupuiSelectOption value={String(e)} key={e}>
                        {`${e} TB`}
                    </GroupuiSelectOption>
                ))}
                <span slot="label">Storage Capacity (TB) *</span>
            </GroupuiSelect>
            <GroupuiMultiselect
                name={"connectorType"}
                typeof={"input"}
                severity={"none"}
                maxHeight={"10rem"}
                style={styles.selectField}
                required={true}
                disabled={false}
                showChips={true}
            >
                {portTypes?.map((portType, index) => {
                    return (
                        <GroupuiMultiselectOption
                            value={String(portType.id)}
                            key={`select-option-${index}`}
                            checked={portTypeChecked[index]}
                            onCheckedChanged={(event) => handleConnectorTypeChange(event, index)}
                        >
                            {portType.portType}
                        </GroupuiMultiselectOption>
                    );
                })}
                <span slot="label">Connector Type</span>
            </GroupuiMultiselect>
            <GenericInput
                label={"Carnet Number"}
                name={"carnetNumber"}
                type={"text"}
                pattern={"[A-Za-z0-9]+"}
                maxLength={100}
                severity={"none"}
                required={false}
                disabled={false}
                value={formData.carnetNumber}
                onGroupuiChange={handleInputChange}
            />
            <GenericDateTimePicker
                label={"Carnet Expiration"}
                value={formData.carnetExpirationDate}
                setValue={handleDatePickerChange}
                required={false}
                severity={"none"}
                style={styles.selectField}
            />
            {isInUserGroup([RightsManagement.SUPPORT], userGroups) && (
                <div>
                    <GenericInput
                        label={"Tracker Name"}
                        type={"text"}
                        severity={"none"}
                        required={true}
                        disabled={true}
                        value={formData.tracker?.name.toString()}
                    />
                    <GroupuiButton
                        style={styles.button}
                        icon={"clipboard-24"}
                        onClick={toggleTrackerList}
                    >
                        Select Tracker from List
                    </GroupuiButton>
                </div>
            )}
            <div style={styles.modalActionButtons}>
                <GroupuiButton onClick={handleCancel} variant={"secondary"}>
                    Cancel
                </GroupuiButton>
                <GroupuiButton
                    icon={"add-32"}
                    disabled={!isValid}
                    type={"submit"}
                    onClick={handleSubmit}
                >
                    Add
                </GroupuiButton>
            </div>
        </GroupuiModal>
    );
};
