import {
    GroupuiButton,
    GroupuiIcon,
    GroupuiLoadingSpinner,
    GroupuiTooltip,
} from "@group-ui/group-ui-react";
import React, { useContext, useState } from "react";
import { RightsManagement } from "../../config/rightsManagement";
import { RightsWrapper } from "../container/RightsWrapper";
import GenericHeadline from "../generics/GenericHeadline";
import GenericMoreButton from "../generics/GenericMoreButton";
import DownloadReport from "../report/databoxesStatusReport/DownloadReport";
import useTitle from "../../hooks/useTitle";
import UserGroupContext from "../context/UserGroupContext";
import GenericTable, { columnDefinition } from "../generics/GenericTable";
import { searchColumns } from "../generics/GenericSearchBar/GenericSearchBar";
import { filterColumns } from "../generics/GenericFilter/GenericFilterBar";
import { AddDataboxModal } from "./modals/AddDataboxModal";
import { DataboxPoolContext } from "../context/provider/DataboxPoolContext";
import { Databox } from "../../models/databox";
import GenericTag from "../generics/GenericTag";
import { useNavigate } from "react-router-dom";

const styles = {
    contentContainer: {
        padding: "var(--groupui-sys-spacing-1200) var(--groupui-sys-spacing-1400)",
        scrollBehavior: "smooth",
        whiteSpace: "pre-wrap",
    } as React.CSSProperties,
    magnifyingGlass: {
        cursor: "pointer",
        color: "var(--groupui-sys-color-action-basic-default)",
    } as React.CSSProperties,
    filtersAndSearchWidth: { filters: "200px", search: "400px" },
};

const columns: columnDefinition[] = [
    {
        key: "name",
        header: "Name",
        width: "auto",
    },
    {
        key: "serialNumber",
        header: "Serial Number",
        width: "auto",
    },
    {
        key: "storageCapacity",
        header: "Storage Capacity",
        width: "auto",
    },
    {
        key: "connectorType",
        header: "Connector Type",
        width: "auto",
    },
    {
        key: "mediaState",
        header: "Current State",
        width: "auto",
    },
    {
        key: "carnet",
        header: "Carnet",
        width: "auto",
    },
    {
        key: "ingestStation",
        header: "Ingest Station",
        width: "auto",
    },
    {
        key: "owner",
        header: "Owner",
        width: "auto",
    },
    {
        key: "button",
        width: "4rem",
    },
];

const predefinedMediaStates = [
    "Free",
    "Reserved",
    "In Stock",
    "Delivery Preparation",
    "In Transit (Delivery)",
    "In Use",
    "Pickup not successful",
    "Waiting for Pickup",
    "Waiting for drop off",
    "In Transit (Return)",
    "Preparation for Ingest",
    "Waiting for Ingest Slot",
    "Pre-Upload",
    "Uploading",
    "Error",
    "Upload Completed",
    "Upload Cancelled",
    "Preparing for next use",
    "Order Completed",
    "Maintenance",
    "Decommissioned",
];

const databoxFilterColumns: filterColumns = [
    {
        column: "ingestStation",
        displayName: "Ingest Location",
        doSort: "asc",
    },
    {
        column: "mediaState",
        displayName: "Current State",
        doSort: "predefined",
        predefinedList: predefinedMediaStates,
    },
    {
        column: "storageCapacity",
        displayName: "Storage Capacity",
        doSort: "predefined",
        predefinedList: ["0 TB", "60 TB", "90 TB", "120 TB"],
        selectedByDefault: ["60 TB", "90 TB", "120 TB"],
    },
];

const databoxSearchColumns: searchColumns = [
    { column: "name", displayName: "Databox" },
    { column: "serialNumber", displayName: "Serial Number" },
];

export const DataboxPool: React.FC = () => {
    useTitle("Databox Pool");

    // Navigator
    const navigate = useNavigate();

    // User Permissions
    const { userGroups } = useContext(UserGroupContext);

    // Data from context provider
    const { isLoading, databoxPool, databoxesStatusReportData } = useContext(DataboxPoolContext);

    // More Button properties
    const [dropDownStateMoreButton, setDropDownStateMoreButton] = useState(false);
    const [addDataboxModal, setAddDataboxModal] = useState(false);

    const allDataboxesData =
        databoxPool?.map((databox: Databox) => {
            return {
                id: databox.id,
                name: databox.name,
                serialNumber: databox.serialNumber,
                storageCapacity: databox.storageCapacityDisplayName,
                connectorType: databox.databoxPortTypesDisplayName,
                mediaState: (
                    <GenericTag
                        text={databox.mediaStateType.mediaStateType}
                        variant="primary"
                        mediaStateTarget="databox"
                    />
                ),
                carnet: databox.carnet ? "Yes" : "No",
                ingestStation: databox.ingestStation?.name ?? "",
                owner: databox.owner,
                button: (
                    <GroupuiTooltip position="left">
                        <GroupuiIcon
                            name="search-plus-32"
                            style={styles.magnifyingGlass}
                            onClick={() => navigate(`/databox-pool/${databox.id}`)}
                        />
                        <div slot="tooltip">Show Details</div>
                    </GroupuiTooltip>
                ),
            };
        }) ?? [];

    return (
        <div style={styles.contentContainer}>
            <GroupuiLoadingSpinner displayed={isLoading} />
            <RightsWrapper
                authorizedUserGroup={RightsManagement.ADD_DATABOX}
                userGroups={userGroups}
            >
                <AddDataboxModal modal={addDataboxModal} setModal={setAddDataboxModal} />
            </RightsWrapper>
            <GenericHeadline
                title="Databox Pool"
                actionButtons={[
                    <GenericMoreButton
                        key={"More-Button-1"}
                        dropDownState={dropDownStateMoreButton}
                        setDropDownState={() => {
                            setDropDownStateMoreButton(!dropDownStateMoreButton);
                        }}
                        options={[
                            <RightsWrapper
                                key={"rights-first"}
                                authorizedUserGroup={RightsManagement.ADD_DATABOX}
                                userGroups={userGroups}
                            >
                                <GroupuiButton
                                    key={"first"}
                                    variant={"primary"}
                                    icon={"add-32"}
                                    fullwidth={true}
                                    alignment={"left"}
                                    onClick={() => {
                                        setAddDataboxModal(true);
                                        setDropDownStateMoreButton(false);
                                    }}
                                >
                                    Add Databox
                                </GroupuiButton>
                            </RightsWrapper>,
                            <DownloadReport
                                key={"databox-report-option-1"}
                                data={databoxesStatusReportData}
                                title={"Databox Status Report"}
                                setDropDownState={setDropDownStateMoreButton}
                            />,
                        ]}
                    />,
                ]}
            />
            <GenericTable
                columns={columns}
                data={allDataboxesData}
                header={true}
                pageSize={20}
                searchColumns={databoxSearchColumns}
                filterColumns={databoxFilterColumns}
                filtersAndSearchWidth={styles.filtersAndSearchWidth}
            />
        </div>
    );
};
