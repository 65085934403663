import React, { useContext, useEffect, useRef, useState } from "react";
import {
    GroupuiButton,
    GroupuiLoadingSpinner,
    GroupuiTab,
    GroupuiTabs,
} from "@group-ui/group-ui-react";
import { useParams } from "react-router";
import { Order } from "../../../models/order";
import { ApiService } from "../../../services/apiService";
import { RightsWrapper } from "../../container/RightsWrapper";
import ErrorContext from "../../context/ErrorContext";
import UserGroupContext from "../../context/UserGroupContext";
import OrderDetails from "../../myDataboxes/OrderDetails";
import PickupDetails from "../../myDataboxes/PickupDetails";
import RequestPickupModal from "../../myDataboxes/modal/RequestPickupModal";
import GenericHeadline from "../GenericHeadline";
import GenericMoreButton from "../GenericMoreButton";
import { DataboxOrderOverview } from "./GenericDataboxOrderOverview";
import useTitle from "../../../hooks/useTitle";
import ConfirmDropOffModal from "../../myDataboxes/modal/ConfirmDropOffModal";
import { RightsManagement } from "../../../config/rightsManagement";
import { handleError } from "../../context/ErrorContextUtils";
import CompleteOrderModal from "../../databoxOrders/CompleteOrderModal";
import EditProjectHolderPage, { RefType } from "../../myDataboxes/EditProjectHolderPage";
import { CancelOrderModal } from "../../databoxOrders/CancelOrderModal";
import { GenericTooltipButton } from "../GenericTooltipButton";
import { OrderContext } from "../../context/provider/OrderContext";

interface GenericDataboxDetailsProps {
    type: DataboxOrderOverview;
}
/**
 * Returns an DataboxDetails Component, which defines the different tabs displayed on the my databox details page
 *
 * @returns DataboxDetails Component
 *
 */
const GenericDataboxDetails: React.FC<GenericDataboxDetailsProps> = ({ type }) => {
    const [title, setTitle] = useState<string>();
    useTitle(title);
    const { currentOrder } = useContext(OrderContext);
    const { orderID, tab } = useParams();
    const { userGroups } = useContext(UserGroupContext);
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const [isLoading, setIsLoading] = useState(true);
    const [pickUpModal, setPickUpModal] = useState(false);
    const [dropOffModal, setDropOffModal] = useState(false);
    const [completeOrderModal, setCompleteOrderModal] = useState(false);
    const [cancelOrderModal, setCancelOrderModal] = useState(false);
    const [dropDownState, setDropDownState] = useState(false);
    const [activeTabId, setActiveTabId] = useState(tab);

    const [editProjectHolder, setEditProjectHolder] = useState<boolean>(false);

    const refEditProject = useRef<RefType>(null);

    const [order, setOrder] = useState<Order>();

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        switch (type) {
            case DataboxOrderOverview.MY_DATABOXES:
                setTitle(currentOrder ? `My Databox - ${currentOrder.displayName}` : `My Databox`);
                break;
            case DataboxOrderOverview.DATABOX_ORDERS:
                setTitle(
                    currentOrder
                        ? `Databox Order Details - ${currentOrder.displayName}`
                        : `Databox Order Details`
                );
        }
    }, [type]);

    useEffect(() => {
        if (dropOffModal) {
            setDropDownState(false);
        }
    }, [dropOffModal]);

    useEffect(() => {
        if (pickUpModal) {
            setDropDownState(false);
        }
    }, [pickUpModal]);

    const getData = () => {
        ApiService.getOrderById(Number(orderID))
            .then((response) => {
                setOrder(response);
                setIsLoading(false);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
            });
    };

    const handleModalClose = () => {
        setIsLoading(true);
        setPickUpModal(false);
        setDropOffModal(false);
        setDropDownState(false);
        getData();
        setActiveTabId("pick-up-details");
    };

    const saveEditProject = () => {
        if (refEditProject.current) {
            refEditProject.current.sendChanges();
        }
    };

    return (
        <div>
            <GroupuiLoadingSpinner displayed={isLoading} />
            {!order?.completed && (
                <RightsWrapper
                    key={"rights-first"}
                    authorizedUserGroup={RightsManagement.REQUEST_PICKUP}
                    userGroups={userGroups}
                >
                    {(order?.currentMediaState === "In Use" ||
                        order?.currentMediaState === "Pickup not successful") && (
                        <RequestPickupModal
                            modal={pickUpModal}
                            setModal={(value: boolean) => setPickUpModal(value)}
                            order={order}
                            onModalClose={handleModalClose}
                        />
                    )}
                    {(order?.currentMediaState === "In Use" ||
                        order?.currentMediaState === "Pickup not successful") && (
                        <ConfirmDropOffModal
                            modal={dropOffModal}
                            setModal={(value: boolean) => setDropOffModal(value)}
                            order={order}
                            onModalClose={handleModalClose}
                        />
                    )}
                    <CompleteOrderModal
                        orderId={order?.id ?? -1}
                        modal={completeOrderModal}
                        setModal={setCompleteOrderModal}
                    />
                    <CancelOrderModal
                        orderId={order?.id ?? -1}
                        modal={cancelOrderModal}
                        setModal={setCancelOrderModal}
                    />
                </RightsWrapper>
            )}
            <GenericHeadline
                title={title ?? ""}
                actionButtons={
                    !order?.completed
                        ? [
                              editProjectHolder === false ? (
                                  <RightsWrapper
                                      key={"rights-second"}
                                      authorizedUserGroup={RightsManagement.REQUEST_PICKUP}
                                      userGroups={userGroups}
                                  >
                                      {
                                          <GenericMoreButton
                                              key={"More-Button-1"}
                                              dropDownState={dropDownState}
                                              setDropDownState={() => {
                                                  setDropDownState(!dropDownState);
                                              }}
                                              options={[
                                                  <GenericTooltipButton
                                                      key={"button-request-pickup"}
                                                      buttonText={"Request Pickup"}
                                                      icon={"service-bell-32"}
                                                      disabled={
                                                          ((!!order?.upsPickup ||
                                                              !!order?.packageDropOff) &&
                                                              order?.currentMediaState !=
                                                                  "Pickup not successful" &&
                                                              (!!order?.upsPickup ||
                                                                  !!order?.packageDropOff) &&
                                                              order?.currentMediaState ==
                                                                  "In Use") ||
                                                          (order?.currentMediaState != "In Use" &&
                                                              order?.currentMediaState !=
                                                                  "Pickup not successful")
                                                      }
                                                      setModal={setPickUpModal}
                                                      setDropDownState={setDropDownState}
                                                      tooltipText={`Only possible in status "In Use" or "Pickup not successful"`}
                                                  />,

                                                  <GenericTooltipButton
                                                      key={"button-confirm-drop-off"}
                                                      buttonText={"Confirm Package Drop Off"}
                                                      icon={"check-32"}
                                                      disabled={
                                                          ((!!order?.upsPickup ||
                                                              !!order?.packageDropOff) &&
                                                              order?.currentMediaState !=
                                                                  "Pickup not successful" &&
                                                              (!!order?.upsPickup ||
                                                                  !!order?.packageDropOff) &&
                                                              order?.currentMediaState ==
                                                                  "In Use") ||
                                                          (order?.currentMediaState != "In Use" &&
                                                              order?.currentMediaState !=
                                                                  "Pickup not successful")
                                                      }
                                                      setModal={setDropOffModal}
                                                      setDropDownState={setDropDownState}
                                                      tooltipText={`Only possible in status "In Use" or "Pickup not successful"`}
                                                  />,
                                                  <RightsWrapper
                                                      authorizedUserGroup={
                                                          RightsManagement.COMPLETE_ORDER
                                                      }
                                                      userGroups={userGroups}
                                                      key={"Option-3-RW"}
                                                  >
                                                      <GroupuiButton
                                                          key={"Option-3"}
                                                          variant="primary"
                                                          icon="check-32"
                                                          disabled={
                                                              order?.ingestJob
                                                                  ?.smartHandsTicket2Id == null
                                                          }
                                                          fullwidth={true}
                                                          alignment="left"
                                                          onClick={() => {
                                                              setCompleteOrderModal(true);
                                                              setDropDownState(false);
                                                          }}
                                                      >
                                                          Complete Databox Order
                                                      </GroupuiButton>
                                                  </RightsWrapper>,
                                                  type === DataboxOrderOverview.MY_DATABOXES ? (
                                                      <RightsWrapper
                                                          authorizedUserGroup={
                                                              RightsManagement.EDIT_PROJECT_HOLDER
                                                          }
                                                          userGroups={userGroups}
                                                          key={"Option-4-RW"}
                                                      >
                                                          <GroupuiButton
                                                              key={"Option-4"}
                                                              variant="primary"
                                                              icon="edit-32"
                                                              fullwidth={true}
                                                              alignment="left"
                                                              //   disabled={}
                                                              onClick={() => {
                                                                  setEditProjectHolder(true);
                                                                  setDropDownState(false);
                                                              }}
                                                          >
                                                              Edit Project/Holder
                                                          </GroupuiButton>
                                                      </RightsWrapper>
                                                  ) : (
                                                      <></>
                                                  ),
                                                  type === DataboxOrderOverview.DATABOX_ORDERS ? (
                                                      <RightsWrapper
                                                          authorizedUserGroup={
                                                              RightsManagement.CANCEL_ORDER
                                                          }
                                                          userGroups={userGroups}
                                                          key={"Option-5-RW"}
                                                      >
                                                          <GenericTooltipButton
                                                              key={"button-cancel-order"}
                                                              buttonText={"Cancel Order"}
                                                              icon={"close-32"}
                                                              disabled={
                                                                  order?.completed ||
                                                                  !!order?.cancelled ||
                                                                  ((order?.shipmentJob
                                                                      ?.jobStatus === "Completed" ||
                                                                      order?.shipmentJob
                                                                          ?.jobStatus ===
                                                                          "Closed") &&
                                                                      (order?.shipmentJob
                                                                          ?.outboundTicketStatus ===
                                                                          "Completed" ||
                                                                          order?.shipmentJob
                                                                              ?.outboundTicketStatus ===
                                                                              "Closed")) ||
                                                                  order?.currentMediaState !=
                                                                      "Delivery Preparation"
                                                              }
                                                              tooltipText={`The order can no longer be cancelled, \n because the databox has already been shipped`}
                                                              setModal={setCancelOrderModal}
                                                              setDropDownState={setDropDownState}
                                                          />
                                                      </RightsWrapper>
                                                  ) : (
                                                      <></>
                                                  ),
                                              ]}
                                          />
                                      }
                                  </RightsWrapper>
                              ) : (
                                  <div
                                      style={{
                                          display: "flex",
                                          gap: "var(--groupui-sys-spacing-300)",
                                      }}
                                  >
                                      <GroupuiButton
                                          variant="secondary"
                                          icon="close-24"
                                          onClick={() => setEditProjectHolder(false)}
                                      >
                                          Cancel
                                      </GroupuiButton>
                                      <GroupuiButton
                                          icon="save-24"
                                          onClick={() => saveEditProject()}
                                      >
                                          Save
                                      </GroupuiButton>
                                  </div>
                              ),
                          ]
                        : []
                }
            />
            <GroupuiTabs
                activeTabId={activeTabId}
                onGroupuiTabChange={(event) => {
                    setActiveTabId(event.target.activeTabId);
                }}
            >
                <GroupuiTab style={{ overflow: "unset" }} tab="order-details" name="Order Details">
                    {editProjectHolder === false ? (
                        <OrderDetails order={order} type={type} />
                    ) : (
                        <EditProjectHolderPage orderId={order?.id ?? -1} ref={refEditProject} />
                    )}
                </GroupuiTab>
                <GroupuiTab tab="pick-up-details" name="Pickup Details">
                    <PickupDetails
                        setPickUpModal={setPickUpModal}
                        setDropOffModal={setDropOffModal}
                        order={order}
                    />
                </GroupuiTab>
            </GroupuiTabs>
        </div>
    );
};

export default GenericDataboxDetails;
