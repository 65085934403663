import React, { useContext, useEffect, useState } from "react";
import { GroupuiButton, GroupuiLoadingSpinner, GroupuiText } from "@group-ui/group-ui-react";
import GenericHeadline from "../generics/GenericHeadline";
import DataboxSelection from "./DataboxSelection";
import Snackbar from "../generics/Snackbar";
import InternalProject from "./orderDataboxes/InternalProject";
import ConfirmOrderModal from "./orderDataboxes/ConfirmOrderModal";
import { DataboxType } from "./generic/DataboxCard";
import { DataboxOrderRequest } from "../../models/databoxOrderRequest";
import ErrorContext from "../context/ErrorContext";
import { handleError } from "../context/ErrorContextUtils";
import { ApiService } from "../../services/apiService";
import { useNavigate } from "react-router";
import { msalInstance } from "../../config/config";
import useTitle from "../../hooks/useTitle";
import { Country } from "../../models/country";
import { Countries } from "../../enums/Countries";
import { USStatesList } from "../../enums/USStatesList";
import { RightsWrapper } from "../container/RightsWrapper";
import UserGroupContext from "../context/UserGroupContext";
import { Company } from "../../models/company";
import { RightsManagement } from "../../config/rightsManagement";
import { Address } from "../../models/address";
import AddressValidationModal from "./orderDataboxes/AddressValidationModal";
import { DataboxOrderResponse } from "../../models/DataboxOrderResponse";
import {
    GenericAddressInformationFields,
    Structure,
} from "../generics/GenericAddressInformationFields";
import { ProposedAddress } from "../../models/proposedAddress";

function OrderDataboxes() {
    useTitle("Order Databox");
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [addressValidationModal, setAddressValidationModal] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "danger">("success");
    const { userGroups } = useContext(UserGroupContext);

    const [databoxes60Count, setDataboxes60Count] = useState<number>(0);
    const [databoxes90Count, setDataboxes90Count] = useState<number>(0);
    const [databoxes120Count, setDataboxes120Count] = useState<number>(0);

    const [countryID, setCountryID] = useState<number>(0);
    const [countryName, setCountryName] = useState<string>("");
    const [locationID, setLocationID] = useState<number>(0);
    const [locationName, setLocationName] = useState<string>("");

    const [deliveryAddress, setDeliveryAddress] = useState<Address | undefined>(undefined);

    const [projectID, setProjectID] = useState<string>();

    const [databoxes60CountValid, setDataboxes60CountValid] = useState<boolean>(true);
    const [databoxes90CountValid, setDataboxes90CountValid] = useState<boolean>(true);
    const [databoxes120CountValid, setDataboxes120CountValid] = useState<boolean>(true);

    const [validateAddress, setValidateAddress] = useState<boolean>(true);

    const [specificDataboxIds, setSpecificDataboxIds] = useState<string[]>([]);
    const [specifcDataboxDetails, setSpecifcDataboxDetails] = useState({});

    const [countries, setCountries] = useState<Country[] | null>();

    const [suggestedAddress, setSuggestedAddress] = useState<Address>();

    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);

    const userIsInIngestGroup =
        userGroups.includes(RightsManagement.ING_USER) ||
        userGroups.includes(RightsManagement.ING_ADMIN);

    const handleSelectedCountryChange = (address: ProposedAddress) => {
        setDeliveryAddress(
            (prevFormData) =>
                new Address({
                    ...prevFormData!,
                    street: address?.street ?? prevFormData?.street ?? "",
                    city: address?.city ?? prevFormData?.city ?? "",
                    fullName: address?.fullName ?? prevFormData?.fullName ?? "",
                    zipCode: address?.zipCode ?? prevFormData?.zipCode ?? "",
                    state: address?.state ?? prevFormData?.state ?? "",
                    email: address?.email ?? prevFormData?.email ?? "",
                    phone: address?.phone ?? prevFormData?.phone ?? "",
                    company: address?.company ?? prevFormData?.company ?? "",
                })
        );
        if (countryID !== address.country.id) {
            setDataboxes60Count(0);
            setDataboxes90Count(0);
            setDataboxes120Count(0);
            setSpecificDataboxIds([]);
        }
        setCountryID(address.country.id);
    };

    useEffect(() => {
        if (countries === null) {
            ApiService.getAllCountries()
                .then((response) => {
                    setCountries(response);
                })
                .catch((error) => {
                    handleError(error, changeErrorMessage, changeShowError);
                });
        }
    }, []);
    const sendData = () => {
        setModal(false);
        setAddressValidationModal(false);
        if (validateData()) {
            const accounts = msalInstance.getAllAccounts();
            let userOID: string | undefined = "";

            if (accounts && accounts[0]["idTokenClaims"] && accounts[0]["idTokenClaims"]["roles"]) {
                userOID = accounts[0]["idTokenClaims"]["oid"];
            }

            const trimmedPhoneNumber = deliveryAddress?.phone.replaceAll(/[ -()-]+/g, "") ?? "";

            const orderRequest = new DataboxOrderRequest(
                deliveryAddress?.fullName ?? "",
                suggestedAddress?.street ?? deliveryAddress?.street ?? "",
                deliveryAddress?.addressSupplement ?? null,
                suggestedAddress?.zipCode ?? deliveryAddress?.zipCode ?? "",
                suggestedAddress?.state ?? deliveryAddress?.state ?? null,
                suggestedAddress?.city ?? deliveryAddress?.city ?? "",
                deliveryAddress?.company?.id,
                deliveryAddress?.email ?? "",
                trimmedPhoneNumber,
                Number(projectID),
                countryID,
                locationID,
                databoxes60Count,
                databoxes90Count,
                databoxes120Count,
                specificDataboxIds,
                userOID ? userOID : "",
                validateAddress
            );

            setIsLoading(true);

            setDeliveryAddress(
                (prevFormData) =>
                    new Address({
                        ...prevFormData!,
                        street: orderRequest.addressLine1,
                        city: orderRequest.city,
                        zipCode: orderRequest.zipCode,
                        state: orderRequest.state ?? undefined,
                    })
            );

            ApiService.addDataboxOrderRequest(orderRequest)
                .then((response: DataboxOrderResponse) => {
                    if (response.address) {
                        setAddressValidationModal(true);
                        setSuggestedAddress(response.address);
                        setIsLoading(false);
                        return;
                    }
                    setSuggestedAddress(undefined);

                    if (!response.success) {
                        setShowSnackbar(true);
                        setSnackbarMessage("Oh no something went wrong!");
                        setSnackbarSeverity("danger");
                        setIsLoading(false);
                        return;
                    }
                    setShowSnackbar(true);
                    setSnackbarMessage("Order successfully created!");
                    setSnackbarSeverity("success");
                    setIsLoading(false);

                    setTimeout(() => {
                        navigate("/my-databoxes");
                    }, 2000);
                })
                .catch((error) => {
                    handleError(error, changeErrorMessage, changeShowError, true);
                    setIsLoading(false);
                });
        }
    };

    const validateData = (): boolean => {
        if ((databoxes60Count || databoxes90Count || databoxes120Count) < 0) return false;
        if (userIsInIngestGroup && (countryID === 0 || locationID === 0)) return false;
        if (checkStateforUSCountries()) return false;
        return true;
    };

    const validateInputs = (): boolean => {
        if (checkDeliveryInputs() || projectID === "") return false;
        if (
            databoxes60CountValid === false ||
            databoxes90CountValid === false ||
            databoxes120CountValid === false
        )
            return false;
        if (projectID == undefined || projectID == null) return false;
        if (checkDataboxCount()) return false;
        if (userIsInIngestGroup && (countryID === 0 || locationID === 0)) return false;
        if (checkStateforUSCountries()) return false;
        return true;
    };

    const checkDataboxCount = () => {
        return (
            (databoxes60Count ||
                databoxes90Count ||
                databoxes120Count ||
                specificDataboxIds.length) <= 0
        );
    };

    const checkDeliveryInputs = () => {
        return (
            !deliveryAddress ||
            deliveryAddress.fullName.length > 22 ||
            (deliveryAddress.addressSupplement && deliveryAddress.addressSupplement.length > 35) ||
            (deliveryAddress.street && deliveryAddress.street.length > 50) ||
            deliveryAddress.fullName.trim() === "" ||
            deliveryAddress.street.trim() === "" ||
            deliveryAddress.zipCode.trim() === "" ||
            deliveryAddress.city.trim() === "" ||
            !deliveryAddress.country ||
            !deliveryAddress.company ||
            deliveryAddress.email.trim() === "" ||
            deliveryAddress.phone.trim() === "" ||
            (countryName !== "United States"
                ? deliveryAddress.phone.replace(/\D/g, "").length < 7
                : deliveryAddress.phone.replace(/\D/g, "").length < 10)
        );
    };

    const checkStateforUSCountries = () => {
        return (
            deliveryAddress?.country?.id ==
                countries?.find((country) => country.country == Countries.UNITED_STATES)?.id &&
            (!USStatesList.filter((usState) => usState.toLowerCase()).includes(
                deliveryAddress?.state ?? ""
            ) ||
                deliveryAddress?.state == "")
        );
    };

    return (
        <div>
            {isLoading && (
                <GroupuiLoadingSpinner
                    displayed={isLoading}
                    style={{
                        display: "flex",
                        position: "fixed",
                        height: "100%",
                        width: "100%",
                        zIndex: "1000",
                        top: "0px",
                        left: "0px",
                        bottom: "0px",
                    }}
                />
            )}
            {showSnackbar && (
                <Snackbar
                    severity={snackbarSeverity}
                    message={snackbarMessage}
                    onClose={() => setShowSnackbar(false)}
                />
            )}

            <GenericHeadline title="Order Databoxes" actionButtons={[]} />
            <DataboxSelection
                setSpecifcDataboxDetails={setSpecifcDataboxDetails}
                setDataboxes60CountValid={setDataboxes60CountValid}
                setDataboxes90CountValid={setDataboxes90CountValid}
                setDataboxes120CountValid={setDataboxes120CountValid}
                setDataboxes60Count={setDataboxes60Count}
                setDataboxes90Count={setDataboxes90Count}
                setDataboxes120Count={setDataboxes120Count}
                setCountryID={setCountryID}
                setCountryName={setCountryName}
                setLocationID={setLocationID}
                setLocationName={setLocationName}
                specificDataboxes={specificDataboxIds}
                setSpecificDataboxes={setSpecificDataboxIds}
                databoxes60Count={databoxes60Count}
                databoxes90Count={databoxes90Count}
                databoxes120Count={databoxes120Count}
                setIsLoading={setIsLoading}
                countryID={countryID}
                locationID={locationID}
            />
            <div>
                <GenericHeadline
                    title={"Delivery Address"}
                    titleHeadingType="h4"
                    actionButtons={[]}
                />
                <GenericAddressInformationFields
                    address={deliveryAddress}
                    setAddress={setDeliveryAddress}
                    disabled={checkDataboxCount()}
                    structure={Structure.ORDER_DATABOX}
                    country={countryName}
                    validateAddress={validateAddress}
                    setValidateAddress={setValidateAddress}
                    onCountryChange={handleSelectedCountryChange}
                />
            </div>
            <br />
            <InternalProject
                project={projectID}
                setProject={setProjectID}
                disabled={
                    checkDeliveryInputs() || checkDataboxCount() || checkStateforUSCountries()
                }
                required={true}
            />
            <br />
            <div style={{ display: "flex", justifyContent: "center" }}>
                <GroupuiText>
                    <b>Total:</b>{" "}
                    {specifcDataboxDetails["numberDataboxes"]
                        ? specifcDataboxDetails["numberDataboxes"]
                        : databoxes60Count + databoxes90Count + databoxes120Count}{" "}
                    Databoxes with{" "}
                    {specifcDataboxDetails["databoxVolume"]
                        ? specifcDataboxDetails["databoxVolume"]
                        : databoxes60Count * DataboxType.SMALL +
                          databoxes90Count * DataboxType.MIDDLE +
                          databoxes120Count * DataboxType.BIG}{" "}
                    TB
                </GroupuiText>
            </div>
            <RightsWrapper
                key={"rights-first"}
                authorizedUserGroup={RightsManagement.ORDER_DATABOX}
                userGroups={userGroups}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "var(--groupui-sys-spacing-500)",
                    }}
                >
                    <GroupuiButton
                        icon="shopping-cart-32"
                        disabled={!validateInputs()}
                        onClick={() => setModal(true)}
                    >
                        Order
                    </GroupuiButton>
                </div>
                <ConfirmOrderModal
                    showModal={modal}
                    setModal={setModal}
                    onConfirm={sendData}
                    addressValidation={validateAddress}
                />
                <AddressValidationModal
                    address={
                        new Address({
                            id: -1,
                            fullName: deliveryAddress?.fullName ?? "",
                            street: deliveryAddress?.street ?? "",
                            addressSupplement: deliveryAddress?.addressSupplement,
                            zipCode: deliveryAddress?.zipCode ?? "",
                            city: deliveryAddress?.city ?? "",
                            state: deliveryAddress?.state,
                            country:
                                countries?.find((country) => country.id == countryID) ??
                                new Country({ id: -1, country: "" }),
                            company:
                                deliveryAddress?.company ?? new Company({ id: -1, company: "" }),
                            email: deliveryAddress?.email ?? "",
                            phone: deliveryAddress?.phone ?? "",
                        })
                    }
                    suggestedAddress={suggestedAddress}
                    setSuggestedAddress={setSuggestedAddress}
                    showModal={addressValidationModal}
                    setModal={setAddressValidationModal}
                    onConfirm={() => {
                        sendData();
                    }}
                />
            </RightsWrapper>
        </div>
    );
}

export default OrderDataboxes;
