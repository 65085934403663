import { MediaState } from "./mediaState";

export class SusHubMonitorData {
    public id;
    public state;
    public name;
    public queuedJobs;
    public runningJobs;
    public uploadSpeed;
    public dataSize;
    public databoxName;

    constructor({
        id,
        state,
        name,
        queuedJobs,
        runningJobs,
        uploadSpeed,
        dataSize,
        databoxName,
    }: {
        id: number;
        state: MediaState;
        name: string;
        queuedJobs: number;
        runningJobs: number;
        uploadSpeed: number;
        dataSize: number;
        databoxName: string;
    }) {
        this.id = id;
        this.state = state;
        this.name = name;
        this.queuedJobs = queuedJobs;
        this.runningJobs = runningJobs;
        this.uploadSpeed = uploadSpeed;
        this.dataSize = dataSize;
        this.databoxName = databoxName;
    }

    private toObject() {
        return {
            id: this.id,
            state: this.state,
            name: this.name,
            inQueue: this.queuedJobs,
            currentlyUploading: this.runningJobs,
            uploadSpeed: this.uploadSpeed,
            dataSize: this.dataSize,
            databoxName: this.databoxName,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): SusHubMonitorData {
        if (json == null) {
            throw Error(
                `Error parsing SUS Moitor Data model for Self Upload Station Hub ${this.name}`
            );
        }

        return new SusHubMonitorData({
            id: json["id"],
            state: json["state"],
            name: json["name"],
            queuedJobs: json["queuedJobs"],
            runningJobs: json["runningJobs"],
            uploadSpeed: json["uploadSpeed"],
            dataSize: json["dataSize"],
            databoxName: json["databoxName"],
        });
    }
}
