import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import {
    GroupuiIcon,
    GroupuiSelect,
    GroupuiSelectOption,
    GroupuiText,
    GroupuiTooltip,
} from "@group-ui/group-ui-react";
import { Country } from "../../models/country";
import { Databox } from "../../models/databox";
import { ApiService } from "../../services/apiService";
import ErrorContext from "../context/ErrorContext";
import { handleError } from "../context/ErrorContextUtils";
import DataboxCard, { DataboxType } from "./generic/DataboxCard";
import SpecificDataboxSelect from "./SpecificDataboxSelect";
import UserGroupContext from "../context/UserGroupContext";
import { RightsWrapper } from "../container/RightsWrapper";
import { RightsManagement } from "../../config/rightsManagement";
import { DataboxResponse } from "../../models/databoxResponse";
import { IngestStationNameAndId } from "../../models/ingestStationNameAndId";

const styles = {
    icon: {
        color: "var(--groupui-sys-color-action-basic-default)",
    },
    countrySelectionContainer: {
        display: "flex",
        flexDirection: "row",
        gap: "var(--groupui-sys-spacing-800)",
        alignItems: "end",
    } as React.CSSProperties,
    locationSelectionContainer: {
        display: "flex",
        flexDirection: "row",
        gap: "var(--groupui-sys-spacing-800)",
        alignItems: "end",
        marginBottom: "1.5rem",
    } as React.CSSProperties,
    databoxSelectionContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
    } as React.CSSProperties,
};

interface DataboxSelectionProps {
    databoxes60Count: number;
    databoxes90Count: number;
    databoxes120Count: number;
    setDataboxes60Count: Dispatch<SetStateAction<number>>;
    setDataboxes90Count: Dispatch<SetStateAction<number>>;
    setDataboxes120Count: Dispatch<SetStateAction<number>>;
    setCountryID: Dispatch<SetStateAction<number>>;
    setLocationID: Dispatch<SetStateAction<number>>;
    setCountryName: Dispatch<SetStateAction<string>>;
    setLocationName: Dispatch<SetStateAction<string>>;
    setDataboxes60CountValid: Dispatch<SetStateAction<boolean>>;
    setDataboxes90CountValid: Dispatch<SetStateAction<boolean>>;
    setDataboxes120CountValid: Dispatch<SetStateAction<boolean>>;
    setSpecificDataboxes: Dispatch<SetStateAction<string[]>>;
    specificDataboxes: string[];
    setSpecifcDataboxDetails: Dispatch<SetStateAction<object>>;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    countryID: number;
    locationID: number;
}

const DataboxSelection: React.FC<DataboxSelectionProps> = ({
    databoxes60Count,
    databoxes90Count,
    databoxes120Count,
    setDataboxes60Count,
    setDataboxes90Count,
    setDataboxes120Count,
    setDataboxes60CountValid,
    setDataboxes90CountValid,
    setDataboxes120CountValid,
    setSpecificDataboxes,
    specificDataboxes,
    setSpecifcDataboxDetails,
    setCountryID,
    setLocationID,
    setCountryName,
    setLocationName,
    setIsLoading,
    countryID,
    locationID,
}) => {
    const [countrySelected, setCountrySelected] = useState<boolean>(false);
    const [locationSelected, setLocationSelected] = useState<boolean>(false);
    const [countryData, setCountryData] = useState<Country[] | null>(null);
    const [databoxes60, setDataboxes60] = useState<number>();
    const [databoxes90, setDataboxes90] = useState<number>();
    const [databoxes120, setDataboxes120] = useState<number>();

    const [freeDataboxes, setFreeDataboxes] = useState<Databox[]>([]);
    const [locationData, setLocationData] = useState<IngestStationNameAndId[] | null>(null);
    const [selectedCountryID, setSelectedCountryID] = useState<number>(0);
    const [selectedLocationID, setSelectedLocationID] = useState<number>(0);
    const [selectedCountryName, setSelectedCountryName] = useState<string>("");

    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const { userGroups } = useContext(UserGroupContext);

    const userIsINGGroup =
        userGroups.includes(RightsManagement.ING_USER) ||
        userGroups.includes(RightsManagement.ING_ADMIN);
    const UserIsSupport = userGroups.includes(RightsManagement.SUPPORT);

    useEffect(() => {
        ApiService.getAllCountries()
            .then((response) => setCountryData(response))
            .catch((error) => handleError(error, changeErrorMessage, changeShowError));

        if (userIsINGGroup) {
            ApiService.getIngestStationNamesAndIds()
                .then((response) => {
                    const allowedLocations = ["AM5", "SE3", "ING"];
                    let filteredLocations = response.filter((location) =>
                        allowedLocations.includes(location.name)
                    );

                    if (selectedCountryName === "United States") {
                        filteredLocations = filteredLocations.filter(
                            (location) => location.name !== "AM5"
                        );
                    }

                    setLocationData(filteredLocations);
                })
                .catch((error) => handleError(error, changeErrorMessage, changeShowError));
        }
    }, [selectedCountryName, userIsINGGroup]);

    useEffect(() => {
        if (countryID !== 0) {
            setSelectedCountryID(countryID);
        }
    }, [countryID]);

    // Handle country selection change
    const handleCountrySelectionChange = async (event: any) => {
        const selectedCountryId = Number(event.target.value);
        setSelectedCountryID(selectedCountryId);
        setCountryID(selectedCountryId);
        setCountrySelected(true);
        setIsLoading(true);

        try {
            if (userIsINGGroup && selectedCountryId !== 0 && selectedLocationID !== 0) {
                const databoxResponse: DataboxResponse =
                    await ApiService.getFreeDataboxesByCountryIdAndLocation(
                        selectedCountryId,
                        selectedLocationID
                    );
                setDataboxes60(databoxResponse.databox60TBCount);
                setDataboxes90(databoxResponse.databox90TBCount);
                setDataboxes120(databoxResponse.databox120TBCount);
            } else if (!userIsINGGroup) {
                const databoxResponse: DataboxResponse =
                    await ApiService.getFreeDataboxesByCountryId(selectedCountryId);
                setDataboxes60(databoxResponse.databox60TBCount);
                setDataboxes90(databoxResponse.databox90TBCount);
                setDataboxes120(databoxResponse.databox120TBCount);

                if (UserIsSupport) {
                    const specificDataboxResponse =
                        await ApiService.getFreeMaintenanceReservedDataboxesByCountryId(
                            selectedCountryId
                        );
                    setFreeDataboxes(specificDataboxResponse ?? []);
                }
            }

            const selectedCountry = countryData?.find(
                (country) => country.id === selectedCountryId
            );
            setCountryName(selectedCountry?.country ?? "");
            setSelectedCountryName(selectedCountry?.country ?? "");
        } catch (error) {
            handleError(error as any, changeErrorMessage, changeShowError);
        } finally {
            setIsLoading(false);
        }
    };

    // Handle location selection change for ING users
    const handleLocationSelectionChange = async (event: any) => {
        const selectedLocationId = Number(event.target.value);
        setSelectedLocationID(selectedLocationId);
        setLocationID(selectedLocationId);
        setLocationSelected(true);
        setIsLoading(true);

        try {
            if (userIsINGGroup && selectedCountryID !== 0 && selectedLocationId !== 0) {
                const databoxResponse: DataboxResponse =
                    await ApiService.getFreeDataboxesByCountryIdAndLocation(
                        selectedCountryID,
                        selectedLocationId
                    );
                setDataboxes60(databoxResponse.databox60TBCount);
                setDataboxes90(databoxResponse.databox90TBCount);
                setDataboxes120(databoxResponse.databox120TBCount);
            }

            const selectedLocation = locationData?.find((loc) => loc.id === selectedLocationId);
            setLocationName(selectedLocation?.name ?? "");
        } catch (error) {
            handleError(error as any, changeErrorMessage, changeShowError);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            {!countrySelected && (
                <GroupuiText size={"body-1"} weight={"regular"}>
                    Please first select the country to which you want the databoxes delivered, then
                    you can proceed with the order.
                </GroupuiText>
            )}
            <br />
            <div style={styles.countrySelectionContainer}>
                <GroupuiSelect
                    required={true}
                    value={selectedCountryID !== 0 ? selectedCountryID.toString() : ""}
                    typeof="input"
                    onGroupuiChange={handleCountrySelectionChange}
                >
                    {countryData?.map((element, index) => (
                        <GroupuiSelectOption key={`option-${index}`} value={element.id.toString()}>
                            {element.country}
                        </GroupuiSelectOption>
                    ))}
                    <span slot="label">Country *</span>
                </GroupuiSelect>
                <GroupuiTooltip position="left">
                    <GroupuiIcon name="help-32" style={styles.icon} />
                    <div slot="tooltip">
                        If you cannot find the country to which you wish to send the databoxes,
                        please contact Support.
                    </div>
                </GroupuiTooltip>
            </div>
            <br />
            {/* Location selection for ING users */}
            {userIsINGGroup && locationData && (
                <div style={styles.locationSelectionContainer}>
                    <GroupuiSelect
                        required={true}
                        value={selectedLocationID !== 0 ? selectedLocationID.toString() : ""}
                        typeof="input"
                        onGroupuiChange={handleLocationSelectionChange}
                    >
                        {locationData.map((location, index) => (
                            <GroupuiSelectOption
                                key={`location-option-${index}`}
                                value={location.id.toString()}
                            >
                                {location.name}
                            </GroupuiSelectOption>
                        ))}
                        <span slot="label">Location *</span>
                    </GroupuiSelect>
                    <GroupuiTooltip position="left">
                        <GroupuiIcon name="help-32" style={styles.icon} />
                        <div slot="tooltip">
                            If you cannot find the location from which you wish to request the
                            databoxes, please contact Support.
                        </div>
                    </GroupuiTooltip>
                </div>
            )}
            {!userIsINGGroup && (
                <RightsWrapper
                    authorizedUserGroup={RightsManagement.ORDER_SPECIFIC_DATABOX}
                    userGroups={userGroups}
                >
                    <SpecificDataboxSelect
                        freeDataboxes={freeDataboxes ?? []}
                        setSpecificDataboxes={setSpecificDataboxes}
                        specificDataboxes={specificDataboxes}
                        setSpecifcDataboxDetails={setSpecifcDataboxDetails}
                        disabled={
                            !countrySelected ||
                            databoxes60Count > 0 ||
                            databoxes90Count > 0 ||
                            databoxes120Count > 0
                        }
                    />
                </RightsWrapper>
            )}

            <div style={styles.databoxSelectionContainer}>
                <DataboxCard
                    databoxType={DataboxType.SMALL}
                    databoxMaxCount={databoxes60}
                    countrySelected={countrySelected && (!userIsINGGroup || locationSelected)}
                    specificDataboxSelected={specificDataboxes.length > 0}
                    databoxCount={databoxes60Count}
                    setDataboxCount={setDataboxes60Count}
                    changeIsValid={setDataboxes60CountValid}
                />
                <DataboxCard
                    databoxType={DataboxType.MIDDLE}
                    databoxMaxCount={databoxes90}
                    countrySelected={countrySelected && (!userIsINGGroup || locationSelected)}
                    specificDataboxSelected={specificDataboxes.length > 0}
                    databoxCount={databoxes90Count}
                    setDataboxCount={setDataboxes90Count}
                    changeIsValid={setDataboxes90CountValid}
                />
                <DataboxCard
                    databoxType={DataboxType.BIG}
                    databoxMaxCount={databoxes120}
                    countrySelected={countrySelected && (!userIsINGGroup || locationSelected)}
                    specificDataboxSelected={specificDataboxes.length > 0}
                    databoxCount={databoxes120Count}
                    setDataboxCount={setDataboxes120Count}
                    changeIsValid={setDataboxes120CountValid}
                />
            </div>
        </div>
    );
};

export default DataboxSelection;
