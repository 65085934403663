import React from "react";

const GenericLoadingSpinner: React.FC = () => {
    return (
        <div>
            <style>
                {`
                                @keyframes rotation {
                                  0% {
                                    transform: rotate(0deg);
                                  }
                                  100% {
                                    transform: rotate(360deg);
                                  }
                                }
                              `}
            </style>
            <span
                style={{
                    width: "36px",
                    height: "36px",
                    border: "3px solid #008075",
                    borderBottomColor: "transparent",
                    borderRadius: "50%",
                    display: "inline-block",
                    boxSizing: "border-box",
                    animation: "rotation 1s linear infinite",
                }}
            />
        </div>
    );
};

export default GenericLoadingSpinner;
