export const RightsManagement = class RightsManagement {
    // =============== //
    // AVAILABLE ROLES //
    // =============== //
    public static USER = "VWAG_GROUPMDM_USER";
    public static OPERATOR = "VWAG_GROUPMDM_OPERATOR";
    public static ADMINISTRATOR = "VWAG_GROUPMDM_ADMINISTRATOR";
    public static SUPPORT = "VWAG_GROUPMDM_SUPPORT";
    public static ING_USER = "VWAG_GROUPMDM_ING_USER";
    public static ING_ADMIN = "VWAG_GROUPMDM_ING_ADMIN";

    // =================================== //
    // RIGHTS MANAGEMENT FOR FUNCTIONALITY //
    // =================================== //

    // Change Ingest Slot State
    public static OPEN_MODAL_INGEST_SLOT_STATE = [this.SUPPORT];
    public static ADD_INGEST_SLOT_STATE = [this.SUPPORT];
    public static EDIT_INGEST_SLOT_STATE = [this.SUPPORT];
    public static DELETE_INGEST_SLOT_STATE = [this.SUPPORT];

    // Add Databox
    public static ADD_DATABOX = [this.ING_ADMIN, this.SUPPORT];
    public static EDIT_DATABOX = [this.ING_ADMIN, this.SUPPORT];

    // Change Databox State
    public static OPEN_MODAL_DATABOX_STATE = [this.SUPPORT];
    public static ADD_DATABOX_STATE = [this.SUPPORT];
    public static EDIT_DATABOX_STATE = [this.SUPPORT];
    public static DELETE_DATABOX_STATE = [this.SUPPORT];

    // Add Databox Comment
    public static ADD_DATABOX_COMMENT = [this.ADMINISTRATOR, this.SUPPORT];

    // Order
    public static ORDER_DATABOX = [
        this.USER,
        this.ADMINISTRATOR,
        this.SUPPORT,
        this.ING_USER,
        this.ING_ADMIN,
    ];
    public static REQUEST_PICKUP = [this.USER, this.ADMINISTRATOR, this.SUPPORT];
    public static ORDER_ING_DATABOX = [this.ING_ADMIN, this.ING_USER];

    // Order specific databox
    public static ORDER_SPECIFIC_DATABOX = [this.SUPPORT];

    // Overwrite Error State
    public static OVERWRITE_ERROR = [this.SUPPORT];

    // Complete Order
    public static COMPLETE_ORDER = [this.SUPPORT];

    // Cancel Order
    public static CANCEL_ORDER = [this.SUPPORT];

    // Download Ingests
    public static DOWNLOAD_INGESTS = [this.ADMINISTRATOR, this.SUPPORT];

    // Edit Holder/Project
    public static EDIT_PROJECT_HOLDER = [this.ING_ADMIN, this.ADMINISTRATOR, this.SUPPORT];

    public static INITIALIZE_DATABOX = [this.SUPPORT];

    // View In Car Mouts Details info
    public static IN_CAR_MOUNT_DETAILS_INFO = [this.SUPPORT];

    // Edit In Car Mounts Details
    public static IN_CAR_MOUNT_EDIT_DETAILS = [this.ADMINISTRATOR, this.SUPPORT];

    // Edit In Car Mounts General Information
    public static IN_CAR_MOUNT_EDIT_GENERAL_INFO = [this.SUPPORT];

    // Edit In Car Mounts Additional Information
    public static IN_CAR_MOUNT_EDIT_ADDITIONAL_INFO = [this.ADMINISTRATOR];

    // Non-Standard Requests
    public static NON_STANDARD_REQUESTS = [this.SUPPORT];

    // View Databox Monitor on Dashboard
    public static DATABOX_MONITOR = [this.USER, this.ADMINISTRATOR, this.SUPPORT];

    // Add Maintenance Messages
    public static MAINTENANCE_MESSAGE = [this.SUPPORT];

    // ============================ //
    // RIGHTS MANAGEMENT FOR ROUTES //
    // ============================ //

    // Inventory Menu Items
    public static DATABOXES_ROUTES = [this.ING_ADMIN, this.ADMINISTRATOR, this.SUPPORT];
    public static INCARMOUNTS_ROUTES = [this.ADMINISTRATOR, this.SUPPORT];

    // Databoxes Menu Items
    public static REQUEST_ROUTES = [
        this.USER,
        this.ADMINISTRATOR,
        this.SUPPORT,
        this.ING_ADMIN,
        this.ING_USER,
    ];
    public static MYDATABOXES_ROUTES = [
        this.USER,
        this.ADMINISTRATOR,
        this.SUPPORT,
        this.ING_ADMIN,
        this.ING_USER,
    ];

    // Operation Menu Items
    public static DATABOX_ORDERS_ROUTES = [this.ADMINISTRATOR, this.SUPPORT, this.ING_ADMIN];
    public static INGESTS_ROUTES = [this.ADMINISTRATOR, this.SUPPORT];
    public static SHIPMENT_JOBS_ROUTES = [this.OPERATOR, this.SUPPORT];
    public static INGEST_JOBS_ROUTES = [this.OPERATOR, this.SUPPORT];

    // Reports Menu Items
    public static REPORT_ROUTES = [this.ING_ADMIN, this.ADMINISTRATOR, this.SUPPORT];
    public static REPORT_DATABOXES_STATUS_REPORT_ROUTES = [
        this.ING_ADMIN,
        this.ADMINISTRATOR,
        this.SUPPORT,
    ];
    public static REPORT_SHIPMENT_REPORT_ROUTES = [this.ADMINISTRATOR, this.SUPPORT];
    public static REPORT_INGEST_STATISTICS_ROUTES = [this.ADMINISTRATOR, this.SUPPORT];

    // DXC Reports Menu Items
    public static CYCLES_REPORT_ROUTES = [this.SUPPORT];
    public static DATABOXES_STATUS_REPORT_ROUTES = [this.SUPPORT];
    public static INGESTS_REPORT_ROUTES = [this.SUPPORT];
    public static JOB_REPORT_ROUTES = [this.SUPPORT];

    // Administration Menu Items
    public static MANAGE_PROJECTS_ROUTES = [this.ADMINISTRATOR, this.SUPPORT];

    // =========================================== //
    // RIGHTS MANAGEMENT FOR SIDE NAVIGATION ITEMS //
    // =========================================== //

    // Inventory Menu Items
    public static INVENTORY = [this.ING_ADMIN, this.ADMINISTRATOR, this.SUPPORT];
    public static INVENTORY_DATABOX_POOL = [this.ING_ADMIN, this.ADMINISTRATOR, this.SUPPORT];
    public static INVENTORY_IN_CAR_MOUNTS = [this.ADMINISTRATOR, this.SUPPORT];

    // Databoxes Menu Items
    public static DATABOXES = [
        this.USER,
        this.ADMINISTRATOR,
        this.SUPPORT,
        this.ING_USER,
        this.ING_ADMIN,
    ];

    // // Operation Menu Items
    public static OPERATION = [this.OPERATOR, this.ADMINISTRATOR, this.SUPPORT, this.ING_ADMIN];
    public static OPERATION_DATABOX_ORDERS = [this.ADMINISTRATOR, this.SUPPORT, this.ING_ADMIN];
    public static OPERATION_INGESTS = [this.ADMINISTRATOR, this.SUPPORT];
    public static OPERATION_SHIPMENT_JOBS = [this.OPERATOR, this.SUPPORT];
    public static OPERATION_INGEST_JOBS = [this.OPERATOR, this.SUPPORT];

    // DXC Reports Menu Items
    public static REPORTS = [this.ING_ADMIN, this.ADMINISTRATOR, this.SUPPORT];
    public static REPORTS_DATABOXES_STATUS_REPORT = [
        this.ING_ADMIN,
        this.ADMINISTRATOR,
        this.SUPPORT,
    ];
    public static REPORTS_SHIPMENT_REPORT = [this.ADMINISTRATOR, this.SUPPORT];
    public static REPORTS_INGEST_STATISTICS = [this.ADMINISTRATOR, this.SUPPORT];

    // DXC Reports Menu Items
    public static DXC_REPORTS = [this.SUPPORT];

    // Administration Menu Items
    public static ADMINISTRATION = [this.ADMINISTRATOR, this.SUPPORT];
};
