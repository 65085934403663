import {
    GroupuiButton,
    GroupuiInlineNotification,
    GroupuiLoadingSpinner,
    GroupuiModal,
    GroupuiSelect,
    GroupuiSelectOption,
    GroupuiText,
} from "@group-ui/group-ui-react";
import GenericHeadline from "../../generics/GenericHeadline";
import React, { useContext, useEffect, useState } from "react";
import { IngestSlot } from "../../../models/ingestSlot/ingestSlot";
import { InitializeDataboxRequest } from "../../../models/InitializeDataboxRequest";
import Snackbar from "../../generics/Snackbar";
import { ApiService } from "../../../services/apiService";
import ErrorContext from "../../context/ErrorContext";
import { handleError } from "../../context/ErrorContextUtils";

interface InitializeDataboxModalProps {
    databoxId: number;
    modal: boolean;
    setModal: (value: boolean) => void;
    ingestStationId: number;
}

const InitializeDataboxModal: React.FC<InitializeDataboxModalProps> = ({
    databoxId,
    modal,
    setModal,
    ingestStationId,
}) => {
    const [ingestSlot, setIngestSlot] = useState<IngestSlot>();
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"success" | "danger">("success");
    const [message, setMessage] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const { changeErrorMessage, changeShowError } = useContext(ErrorContext);
    const [freeIngestSlots, setFreeIngestSlots] = useState<IngestSlot[]>([]);
    const disableButton = ingestSlot === undefined;

    const resetState = () => {
        setIngestSlot(undefined);
        setModal(false);
        setShowSnackbar(false);
        setMessage("");
    };

    useEffect(() => {
        if (modal) {
            setLoading(true);
            ApiService.getFreeIngestSlotsByIngestStationId(ingestStationId)
                .then((response) => {
                    setLoading(false);
                    setFreeIngestSlots(response);
                })
                .catch(() => {
                    setLoading(false);
                    setFreeIngestSlots([]);
                });
        }
    }, [modal, ingestStationId]);

    const sendRequest = () => {
        if (ingestSlot) {
            setLoading(true);
            ApiService.initializeDatabox(
                new InitializeDataboxRequest({ ingestSlotId: ingestSlot.id, databoxId: databoxId })
            )
                .then((response) => {
                    if (response.startsWith("success")) {
                        setMessage("Request to initialize databox created successfully!");
                        setSeverity("success");
                        setShowSnackbar(true);
                        setTimeout(() => {
                            resetState();
                        }, 2000);
                    } else {
                        setShowSnackbar(true);
                        setMessage("An error occurred during initialization of databox!");
                        setSeverity("danger");
                    }
                })
                .catch((error) => handleError(error, changeErrorMessage, changeShowError))
                .finally(() => setLoading(false));
        }
    };

    return (
        <GroupuiModal
            displayed={modal}
            onGroupuiModalClose={() => setModal(false)}
            maxWidth="70vw"
            l={12}
            xl={12}
            xxl={7}
            closeButton={true}
            overflow="visible"
            padding="var(--groupui-sys-spacing-400)"
        >
            <GroupuiLoadingSpinner displayed={loading} />
            {showSnackbar && (
                <Snackbar
                    message={message}
                    severity={severity}
                    onClose={() => setShowSnackbar(false)}
                />
            )}
            <GenericHeadline
                title="Initialize Databox"
                titleHeadingType="h3"
                type="modal"
                marginBottom="var(--groupui-sys-spacing-300)"
                actionButtons={[]}
            />
            <GroupuiText>
                A smart-hands ticket will be created to insert the databox into the chosen ingest
                slot
            </GroupuiText>
            {freeIngestSlots.length === 0 && (
                <GroupuiInlineNotification
                    visible={true}
                    hideIcon={false}
                    severity="warning"
                    style={{
                        // Added because color from the component isn´t working
                        color: "var(--groupui-sys-color-warning-basic-default)",
                        backgroundColor: "var(--groupui-sys-color-warning-weak-default)",
                        // Added to avoided dropdowns to be hidden
                        zIndex: "0",
                    }}
                >
                    <div
                        slot="headline"
                        // Added because color from the component isn´t working
                        style={{ color: "var(--groupui-sys-color-text-normal)" }}
                    >
                        No Ingest Slots Available
                    </div>
                    <div
                        slot="content"
                        // Added because color from the component isn´t working
                        style={{ color: "var(--groupui-sys-color-text-normal)" }}
                    >
                        All ingest slots are occupied. Please wait until a slot becomes available.
                    </div>
                </GroupuiInlineNotification>
            )}
            <br />
            <GroupuiSelect maxHeight="7rem">
                {freeIngestSlots.map((ingestSlot) => (
                    <GroupuiSelectOption
                        onClick={() => setIngestSlot(ingestSlot)}
                        key={ingestSlot.id}
                    >
                        {ingestSlot.name}
                    </GroupuiSelectOption>
                ))}
                <span key={"label"} slot="label">
                    Ingest Slot *
                </span>
            </GroupuiSelect>
            <br />
            <div
                style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "var(--groupui-sys-spacing-400)",
                    marginTop: "var(--groupui-sys-spacing-1200)",
                }}
            >
                <GroupuiButton onClick={resetState} variant="secondary">
                    Cancel
                </GroupuiButton>
                <GroupuiButton
                    disabled={disableButton}
                    size="m"
                    type="submit"
                    onClick={sendRequest}
                >
                    Initialize
                </GroupuiButton>
            </div>
        </GroupuiModal>
    );
};

export default InitializeDataboxModal;
