import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ApiService } from "../../../services/apiService";
import { handleError } from "../ErrorContextUtils";
import ErrorContext from "../ErrorContext";
import { Databox } from "../../../models/databox";
import UserGroupContext from "../UserGroupContext";
import { DataboxesStatusReport } from "../../../models/reports/databoxesStatusReport";
import { GraphApiEndpoints } from "../../../constants/graphApiEndpoints";
import { GraphApiService, RequestType } from "../../../services/graphApi/graphApiService";
import { parseFullDateWithoutSeconds, parseDateOnly } from "../../../utils/DateUtil";

interface IDataboxPoolContext {
    isLoading: boolean;
    databoxPool?: Databox[];
    databoxesStatusReportData?: any[];
    currentDatabox?: Databox;
}

const defaultState = {
    isLoading: false,
    databoxPool: undefined,
    databoxesStatusReportData: undefined,
    currentDatabox: undefined,
};

export const DataboxPoolContext = createContext<IDataboxPoolContext>(defaultState);

interface DataboxPoolProviderProps {
    children?: React.ReactNode;
}

export const DataboxPoolProvider: React.FC<DataboxPoolProviderProps> = ({ children }) => {
    const location = useLocation();
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const { userGroups } = useContext(UserGroupContext);

    const [isLoading, setIsLoading] = useState(true);
    const [databoxPool, setDataboxPool] = useState<Databox[]>();
    const [currentDatabox, setCurrentDatabox] = useState<Databox>();
    const [databoxesStatusReportData, setDataboxesStatusReportData] = useState<any[] | undefined>(
        []
    );

    // Get current databox id from URL
    const urlPathParameter = location.pathname.split("/");
    const currentDataboxId = urlPathParameter[urlPathParameter.length - 1];

    useEffect(() => {
        getData();
    }, [location.pathname]);

    const getData = async () => {
        if (urlPathParameter.length == 2) {
            await Promise.all([getDataboxPool(), getDataboxStatusReport()]);
        }
        getSpecificDatabox();
    };

    const getSpecificDatabox = async () => {
        setIsLoading(true);
        await Promise.all([
            new RegExp(`^[1-9]\\d*$`).test(currentDataboxId)
                ? ApiService.getDataboxById(Number(currentDataboxId))
                      .then((databox: Databox) => {
                          setCurrentDatabox(databox);
                      })
                      .catch((error) => handleError(error, changeErrorMessage, changeShowError))
                : undefined,
        ]).then(() => setIsLoading(false));
    };

    const getDataboxPool = async (): Promise<Databox[] | undefined> => {
        setIsLoading(true);
        let databoxPool: Databox[];
        try {
            databoxPool = await ApiService.getAllDataboxes();
        } catch (error: any) {
            handleError(error, changeErrorMessage, changeShowError);
            return undefined;
        }
        setDataboxPool(databoxPool);
        setIsLoading(false);
    };

    const getDataboxStatusReport = async () =>
        setDataboxesStatusReportData(
            await getAllDataboxStatus(changeShowError, changeErrorMessage, userGroups)
        );

    return (
        <DataboxPoolContext.Provider
            value={{
                isLoading,
                databoxPool,
                databoxesStatusReportData,
                currentDatabox,
            }}
        >
            {children}
        </DataboxPoolContext.Provider>
    );
};

// Map the data on the reult entity for the report
const getAllDataboxStatus = async (
    changeShowError: ((show: boolean) => void) | undefined,
    changeErrorMessage: ((message: string) => void) | undefined,
    userGroups: string[]
) => {
    const databoxes: DataboxesStatusReport[] | void | null =
        await ApiService.getDataboxesStatusReports().catch((error) =>
            handleError(error, changeErrorMessage, changeShowError)
        );

    if (!databoxes) return;

    const userIdNameMapping = new Map<string, string>();
    await Promise.all(
        databoxes.map(async (databoxesStatus: DataboxesStatusReport) => {
            let result = undefined;
            if (databoxesStatus.requestor != null) {
                if (!userIdNameMapping.has(databoxesStatus.requestor)) {
                    result = await GraphApiService.callMsGraph(
                        GraphApiEndpoints.userById(databoxesStatus.requestor),
                        RequestType.GET
                    ).catch((error) => {
                        handleError(error, changeErrorMessage, changeShowError);
                    });
                    userIdNameMapping.set(databoxesStatus.requestor, result.displayName);
                }
            }
        })
    );

    return databoxes
        .sort((a: DataboxesStatusReport, b: DataboxesStatusReport) => {
            return a.databoxId - b.databoxId;
        })
        .map((databoxStatus: DataboxesStatusReport, index) => {
            return {
                databoxName: databoxStatus.databoxName,
                databoxSerialNumber: databoxStatus.databoxSerialNumber,
                storageCapacity: databoxStatus.storageCapacityDisplayName,
                databoxOwner: databoxStatus.databoxOwner,
                orderId: databoxStatus.orderId ? "OR" + databoxStatus.orderId : "",
                databoxStatus: databoxStatus.databoxStatus,
                tracker: databoxStatus.tracker,
                battery: databoxStatus.battery ? databoxStatus.battery + " %" : "",
                lastTrackerUpdate: parseFullDateWithoutSeconds(databoxStatus.lastTrackerUpdate),
                ingestLocation: databoxStatus.ingestLocation,
                destination: databoxStatus.destination,
                requestor: userIdNameMapping.get(databoxStatus.requestor) ?? "",
                lastComment: databoxStatus.lastComment,
                internalProject: databoxStatus.internalProject,
                currentHolder: databoxStatus.currentHolder,
                purchaseOrderNumber: databoxStatus.purchaseOrderNumber,
                purchaseOrderDate: parseDateOnly(databoxStatus.purchaseOrderDate),
                opxNumber: databoxStatus.opxNumber,
                serviceContractId: databoxStatus.serviceContractId,
                serviceStartDate: parseDateOnly(databoxStatus.serviceStartDate),
                serviceEndDate: parseDateOnly(databoxStatus.serviceEndDate),
                longitude: databoxStatus.longitude,
                latitude: databoxStatus.latitude,
            };
        });
};
