import { GroupuiDatePicker, GroupuiIcon, GroupuiInput } from "@group-ui/group-ui-react";
import { log } from "console";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

interface GenericDateTimePickerProps {
    label: string;
    value: string | undefined;
    setValue: Dispatch<SetStateAction<string | undefined>>;
    required: boolean;
    disabled?: boolean;
    severity?: "none" | "danger" | "warning" | "success" | undefined;
    style?: React.CSSProperties;
}

const GenericDateTimePicker: React.FC<GenericDateTimePickerProps> = ({
    label,
    value,
    setValue,
    required,
    disabled,
    severity,
    style,
}) => {
    const [date, setDate] = useState<string>();
    const [time, setTime] = useState<string>();

    useEffect(() => {
        if (date && time) {
            setValue(date + "T" + time);
        } else if (!date && !time) {
            setValue(undefined);
        }
    }, [date, time]);

    useEffect(() => {
        if (value) {
            setDate(value?.split("T")[0] ?? "");
            setTime(value?.split("T")[1].split(".")[0] ?? "");
        }
    }, [value]);

    return (
        <div
            style={{
                position: "relative",
                display: "flex",
                flexDirection: "row",
                gap: "var(--groupui-sys-spacing-300)",
            }}
        >
            <GroupuiDatePicker
                style={{ whiteSpace: "normal", ...style }}
                required={required}
                disabled={disabled}
                severity={severity}
                showClearIcon={true}
                value={date}
                onGroupuiChange={(event) => setDate(event.target.value)}
                onGroupuiClearClicked={() => setDate(undefined)}
            >
                <span slot="label">
                    {label} - Date {required ? "*" : ""}
                </span>
            </GroupuiDatePicker>
            <GroupuiInput
                required={required}
                disabled={disabled}
                severity={severity}
                value={time}
                showClearIcon={true}
                type="time"
                style={style}
                onGroupuiChange={(event) => setTime(event.target.value)}
                onGroupuiClearClicked={() => setTime(undefined)}
            >
                <span slot="label">
                    {label} - Time {required ? "*" : ""}
                </span>
            </GroupuiInput>
        </div>
    );
};

export default GenericDateTimePicker;
