export class CancelRequest {
    public ingestUuid;
    public slotSerialNumber;
    public databoxSerialNumber;
    public hubName;
    public messageUuid;
    public orderId;
    public ingestSlotId;
    public databoxId;

    constructor({
        ingestUuid,
        slotSerialNumber,
        databoxSerialNumber,
        hubName,
        messageUuid,
        orderId,
        ingestSlotId,
        databoxId,
    }: {
        ingestUuid: string;
        slotSerialNumber: string;
        databoxSerialNumber: string;
        hubName: string;
        messageUuid: string;
        orderId: number;
        ingestSlotId: number;
        databoxId: number;
    }) {
        this.ingestUuid = ingestUuid;
        this.slotSerialNumber = slotSerialNumber;
        this.databoxSerialNumber = databoxSerialNumber;
        this.hubName = hubName;
        this.messageUuid = messageUuid;
        this.orderId = orderId;
        this.ingestSlotId = ingestSlotId;
        this.databoxId = databoxId;
    }

    private toObject() {
        return {
            ingestUuid: this.ingestUuid,
            slotSerialNumber: this.slotSerialNumber,
            databoxSerialNumber: this.databoxSerialNumber,
            hubName: this.hubName,
            messageUuid: this.messageUuid,
            orderId: this.orderId,
            ingestSlotId: this.ingestSlotId,
            databoxId: this.databoxId,
        };
    }

    public serialize() {
        return JSON.stringify(this.toObject());
    }

    static fromSerialized(json: object): CancelRequest | null {
        if (json == null) {
            return null;
        }
        return new CancelRequest({
            ingestUuid: json["ingestUuid"],
            slotSerialNumber: json["slotSerialNumber"],
            databoxSerialNumber: json["databoxSerialNumber"],
            hubName: json["hubName"],
            messageUuid: json["messageUuid"],
            orderId: json["orderId"],
            ingestSlotId: json["ingestSlotId"],
            databoxId: json["databoxId"],
        });
    }
}
