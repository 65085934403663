import React, { useState } from "react";
import {
    GroupuiAccordion,
    GroupuiButton,
    GroupuiDivider,
    GroupuiHeadline,
    GroupuiIcon,
    GroupuiModal,
    GroupuiNotification,
} from "@group-ui/group-ui-react";
import { SlotStatus } from "../../models/slotStatus";
import IngestSteps from "./dashboardDetails/IngestSteps";
import IngestStatus from "./dashboardDetails/IngestStatus";
import IngestAdditionalInformation from "./dashboardDetails/IngestAdditionalInformation";
import DashboardDetailsHeadline from "./dashboardDetails/DashboardDetailsHeadline";
import { IngestSlotMonitorData } from "../../models/ingestSlotMonitorData";
import { formatBytes, formatSpeed, getUploadSpeed } from "../../utils/DataVolumeUtil";
import { getTimePassed } from "../../utils/DashboardUtils";

const styles = {
    headline: {
        color: "var(--groupui-sys-color-action-basic-default)",
        width: "50rem",
    },
    errorContainer: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
    } as React.CSSProperties,
    icon: {
        paddingRight: "5%",
        color: "white",
    },
    message: {
        color: "white",
    },
};
interface IngestDetailsProps {
    showDetails: boolean;
    onClose: () => void;
    ingestSlotMonitorData: IngestSlotMonitorData;
}

/**
 * A DashboardDetails Component, which displays details information for running ingests
 *
 * @param {boolean} showDetails - dependent on the value it shows the details or not
 * @param {() => void} onClose - function to be run after the details modal is closed
 * @param {IngestSlotMonitorData} ingestSlotMonitorData - holds the information to be displayed in the details
 *
 * @returns DashboardDetails Component
 *
 */
const IngestDetails: React.FC<IngestDetailsProps> = ({
    showDetails,
    onClose,
    ingestSlotMonitorData,
}) => {
    const [showErrorDetails, setShowErrorDetails] = useState(false);

    const uploadSpeed = getUploadSpeed(
        ingestSlotMonitorData.uploadedBytes,
        ingestSlotMonitorData.uploadStart,
        ingestSlotMonitorData.lastStepTimestamp
    );

    return (
        <GroupuiModal
            displayed={showDetails}
            closeButton={true}
            onGroupuiModalClose={onClose}
            maxWidth="72rem"
            margin="100px"
        >
            <DashboardDetailsHeadline ingestSlotMonitorData={ingestSlotMonitorData} />

            <GroupuiDivider />
            {ingestSlotMonitorData?.state == "Error" &&
                ingestSlotMonitorData?.overwrittenSince === undefined &&
                ingestSlotMonitorData?.overwriteState === undefined && (
                    <GroupuiNotification
                        visible={
                            ingestSlotMonitorData?.error !== undefined &&
                            ingestSlotMonitorData?.error !== null
                        }
                        severity="danger"
                        dismissible={false}
                    >
                        <div style={styles.errorContainer}>
                            <GroupuiIcon name={"error-filled-32"} height={25} style={styles.icon} />
                            {ingestSlotMonitorData?.error &&
                            ingestSlotMonitorData?.error.length > 265 ? (
                                <div>
                                    <div style={styles.message}>
                                        {ingestSlotMonitorData?.error
                                            ? showErrorDetails
                                                ? ingestSlotMonitorData?.error
                                                : ingestSlotMonitorData?.error.substring(0, 265) +
                                                  "..."
                                            : "Something went wrong"}
                                    </div>
                                    <GroupuiButton
                                        severity="danger"
                                        target="self"
                                        variant="primary"
                                        onClick={() => setShowErrorDetails(!showErrorDetails)}
                                    >
                                        {showErrorDetails ? "Hide Details" : "Show Details"}
                                    </GroupuiButton>
                                </div>
                            ) : (
                                <div style={styles.message}>
                                    {ingestSlotMonitorData?.error
                                        ? ingestSlotMonitorData?.error
                                        : "Something went wrong"}
                                </div>
                            )}
                        </div>
                    </GroupuiNotification>
                )}
            <GroupuiAccordion first={true} expanded={true}>
                <span slot="headline">
                    <GroupuiHeadline heading="h6">Ingest Status</GroupuiHeadline>
                </span>
                <IngestStatus
                    ingestStationId={ingestSlotMonitorData?.ingestStationId}
                    ingestStation={ingestSlotMonitorData?.ingestStationName ?? "No data"}
                    ingestSlotId={ingestSlotMonitorData?.ingestSlotId}
                    slotName={ingestSlotMonitorData?.name ? ingestSlotMonitorData?.name : "No data"}
                    databoxName={
                        ingestSlotMonitorData?.databoxName
                            ? ingestSlotMonitorData?.databoxName
                            : "No data"
                    }
                    slotStep={
                        ingestSlotMonitorData?.lastStep
                            ? ingestSlotMonitorData?.lastStep
                            : "No data"
                    }
                    slotUploadSpeed={
                        uploadSpeed != undefined && formatSpeed(uploadSpeed) != ""
                            ? formatSpeed(uploadSpeed)
                            : "No data"
                    }
                    slotUploadProgress={
                        ingestSlotMonitorData?.uploadProgress ||
                        ingestSlotMonitorData?.uploadProgress != 0
                            ? Math.round((ingestSlotMonitorData.uploadProgress ?? 0) * 100) + "%"
                            : "/"
                    }
                    slotTimePassed={
                        getTimePassed(ingestSlotMonitorData)
                            ? getTimePassed(ingestSlotMonitorData)
                            : "No data"
                    }
                    chunkNumber={
                        ingestSlotMonitorData?.chunkNumber
                            ? ingestSlotMonitorData?.chunkNumber
                            : "No data"
                    }
                    numberOfChunks={
                        ingestSlotMonitorData?.numberOfChunks
                            ? ingestSlotMonitorData?.numberOfChunks
                            : "No data"
                    }
                    dataVolume={
                        ingestSlotMonitorData?.dataVolume
                            ? formatBytes(ingestSlotMonitorData?.dataVolume)
                            : "No data"
                    }
                    uploadedVolume={
                        ingestSlotMonitorData?.uploadedVolume
                            ? formatBytes(ingestSlotMonitorData?.uploadedVolume)
                            : "No data"
                    }
                    numberOfFiles={
                        ingestSlotMonitorData?.numberOfFiles
                            ? ingestSlotMonitorData?.numberOfFiles
                            : "No data"
                    }
                    numberOfUploadedFiles={
                        ingestSlotMonitorData?.numberOfUploadedFiles
                            ? ingestSlotMonitorData?.numberOfUploadedFiles
                            : "No data"
                    }
                    ingestType={
                        ingestSlotMonitorData?.ingestType
                            ? ingestSlotMonitorData?.ingestType
                            : "No data"
                    }
                />
            </GroupuiAccordion>
            <GroupuiAccordion>
                <span slot="headline">
                    <GroupuiHeadline heading="h6">Steps</GroupuiHeadline>
                </span>
                {ingestSlotMonitorData?.lastStep !== undefined && (
                    <IngestSteps
                        step={
                            ingestSlotMonitorData?.lastStep === ""
                                ? "DONE"
                                : ingestSlotMonitorData?.lastStep
                        }
                    />
                )}
            </GroupuiAccordion>
            <GroupuiAccordion>
                <span slot="headline">
                    <GroupuiHeadline heading="h6">Additional Information</GroupuiHeadline>
                </span>
                <IngestAdditionalInformation
                    databoxSerialNumber={ingestSlotMonitorData?.databoxSerialNumber}
                    databoxName={ingestSlotMonitorData?.databoxName}
                    uploadID={ingestSlotMonitorData?.uploadId ?? "No data"}
                    ingestID={String(ingestSlotMonitorData?.ingestId) ?? "No data"}
                    ingestUuid={ingestSlotMonitorData?.ingestUuid ?? "No data"}
                    ingestStationName={ingestSlotMonitorData?.ingestStationName ?? "No data"}
                    messageID={ingestSlotMonitorData?.messageUuid ?? "No data"}
                    duration={
                        ingestSlotMonitorData?.duration
                            ? ingestSlotMonitorData?.duration
                            : "No data"
                    }
                    ingestVersion={
                        ingestSlotMonitorData?.ingestVersion
                            ? ingestSlotMonitorData?.ingestVersion
                            : "No data"
                    }
                    messageTimestamp={
                        ingestSlotMonitorData?.messageTimestamp
                            ? ingestSlotMonitorData?.messageTimestamp
                            : "No data"
                    }
                    numberOfMeasurements={
                        ingestSlotMonitorData?.numberOfMeasurements
                            ? ingestSlotMonitorData?.numberOfMeasurements
                            : "No data"
                    }
                    slotSerialNumber={
                        ingestSlotMonitorData?.slotSerialNumber
                            ? ingestSlotMonitorData?.slotSerialNumber
                            : "No data"
                    }
                    discoveryID={
                        ingestSlotMonitorData?.discoveryId != undefined
                            ? ingestSlotMonitorData?.discoveryId
                            : "No data"
                    }
                    ingestStart={
                        ingestSlotMonitorData?.ingestStart
                            ? ingestSlotMonitorData?.ingestStart
                            : "No data"
                    }
                    csCallRetries={ingestSlotMonitorData?.csCallRetries ?? "No data"}
                    dataId={ingestSlotMonitorData?.dataId ?? "No data"}
                    ingestAttempt={ingestSlotMonitorData?.ingestAttempt ?? undefined}
                    ingestAttempts={ingestSlotMonitorData?.ingestAttempts ?? undefined}
                    ingestJobId={ingestSlotMonitorData?.ingestJobId}
                />
            </GroupuiAccordion>
        </GroupuiModal>
    );
};

export default IngestDetails;
