import React from "react";
import { GroupuiCard, GroupuiHeadline, GroupuiIcon, GroupuiInput } from "@group-ui/group-ui-react";
import { getStepLabelForDatabaseName } from "../../../utils/DashboardUtils";
import { useNavigate } from "react-router-dom";

const styles = {
    cardIcons: {
        color: "var(--groupui-sys-color-action-basic-default-inverse)",
        display: "flex",
        justifyContent: "center",
    },
    cardText: {
        color: "white",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
    },
};

interface IngestStatusProps {
    ingestStationId: number;
    ingestStation: string;
    ingestSlotId: number;
    slotName: string;
    databoxName: string;
    slotStep: string;
    slotUploadSpeed: string;
    slotUploadProgress: string;
    slotTimePassed: string;
    numberOfChunks: string;
    chunkNumber: string;
    dataVolume: string;
    uploadedVolume: string;
    numberOfFiles: string;
    numberOfUploadedFiles: string;
    ingestType: string;
}

const IngestStatus: React.FC<IngestStatusProps> = ({
    ingestStationId,
    ingestStation,
    ingestSlotId,
    slotName,
    databoxName,
    slotStep,
    slotUploadSpeed,
    slotUploadProgress,
    slotTimePassed,
    numberOfChunks,
    chunkNumber,
    dataVolume,
    uploadedVolume,
    numberOfFiles,
    numberOfUploadedFiles,
    ingestType,
}) => {
    const navigate = useNavigate();
    return (
        <>
            <div
                style={{
                    display: "grid",
                    gridColumnGap: "var(--groupui-sys-spacing-300)",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
                    gridAutoFlow: "column",
                }}
            >
                <GroupuiCard
                    height="170px"
                    elevation="1"
                    background="var(--groupui-sys-color-action-basic-hover)"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <GroupuiIcon name="location-48" style={styles.cardIcons} />
                    <GroupuiHeadline heading={"h4"} style={styles.cardText} weight="bold">
                        {ingestStation}
                    </GroupuiHeadline>
                </GroupuiCard>
                <GroupuiCard
                    height="170px"
                    elevation="0"
                    background="var(--groupui-sys-color-action-basic-hover)"
                    style={{ zIndex: "0" }}
                >
                    <GroupuiIcon name="upload-48" style={styles.cardIcons} />
                    <GroupuiHeadline heading={"h5"} style={styles.cardText} weight="bold">
                        {getStepLabelForDatabaseName(slotStep)}
                    </GroupuiHeadline>
                </GroupuiCard>
                <GroupuiCard
                    height="170px"
                    elevation="1"
                    background="var(--groupui-sys-color-action-basic-hover)"
                >
                    <GroupuiIcon name="network-48" style={styles.cardIcons} />
                    <GroupuiHeadline heading={"h4"} style={styles.cardText} weight="bold">
                        {slotUploadSpeed}
                    </GroupuiHeadline>
                </GroupuiCard>
                <GroupuiCard
                    height="170px"
                    elevation="1"
                    background="var(--groupui-sys-color-action-basic-hover)"
                >
                    <GroupuiIcon name="checklist-48" style={styles.cardIcons} />
                    <GroupuiHeadline heading={"h4"} style={styles.cardText} weight="bold">
                        {slotUploadProgress}
                    </GroupuiHeadline>
                </GroupuiCard>
                <GroupuiCard
                    height="170px"
                    elevation="1"
                    background="var(--groupui-sys-color-action-basic-hover)"
                >
                    <GroupuiIcon name="clock-48" style={styles.cardIcons} />
                    <GroupuiHeadline heading={"h4"} style={styles.cardText} weight="bold">
                        {slotTimePassed}
                    </GroupuiHeadline>
                </GroupuiCard>
            </div>
            <div
                style={{
                    display: "grid",
                    gridTemplateRows: "60px 60px 65px",
                    gridTemplateColumns: "32% 32% 32%",
                    gap: "var(--groupui-sys-spacing-400)",
                    marginTop: "var(--groupui-sys-spacing-300)",
                }}
            >
                <GroupuiInput style={{}} disabled={true} value={databoxName}>
                    <span slot="label">Databox Name</span>
                </GroupuiInput>
                <GroupuiInput style={{}} disabled={true} value={chunkNumber}>
                    <span slot="label">Uploaded Data Chunks</span>
                </GroupuiInput>
                <GroupuiInput style={{}} disabled={true} value={numberOfFiles}>
                    <span slot="label">Files</span>
                </GroupuiInput>
                <div style={{ position: "relative" }}>
                    <GroupuiInput style={{}} disabled={true} value={slotName}>
                        <span slot="label">Ingest Slot Name</span>
                    </GroupuiInput>
                    <div style={{ position: "absolute", right: 8, bottom: 0 }}>
                        <GroupuiIcon
                            name="external-link-32"
                            onClick={() =>
                                navigate(`/ingest-stations/${ingestStationId}/${ingestSlotId}`)
                            }
                            style={{ cursor: "pointer" }}
                        />
                    </div>
                </div>

                <GroupuiInput style={{}} disabled={true} value={dataVolume}>
                    <span slot="label">Data Volume</span>
                </GroupuiInput>
                <GroupuiInput style={{}} disabled={true} value={numberOfUploadedFiles}>
                    <span slot="label">Uploaded Files</span>
                </GroupuiInput>
                <GroupuiInput style={{}} disabled={true} value={ingestType}>
                    <span slot="label">Ingest Type</span>
                </GroupuiInput>
                <GroupuiInput style={{}} disabled={true} value={numberOfChunks}>
                    <span slot="label">Data Chunks</span>
                </GroupuiInput>
                <GroupuiInput style={{}} disabled={true} value={uploadedVolume}>
                    <span slot="label">Uploaded Volume</span>
                </GroupuiInput>
            </div>
        </>
    );
};

export default IngestStatus;
