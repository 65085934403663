import React, { useContext, useRef, useState } from "react";
import {
    GroupuiIcon,
    GroupuiSideNavigation,
    GroupuiSideNavigationGroup,
    GroupuiSideNavigationItem,
    GroupuiTooltip,
} from "@group-ui/group-ui-react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import PortalHeader from "./PortalHeader";
import UserGroupContext from "../context/UserGroupContext";
import { RightsManagement } from "../../config/rightsManagement";

const navbarItems: {
    text: string;
    icon: string;
    url?: string;
    authorizedUserGroup?: string[];
    children?: {
        text: string;
        icon?: string;
        url: string;
        authorizedUserGroup?: string[];
    }[];
}[] = [
    {
        text: "Dashboard",
        icon: "home-24",
        url: "/",
    },
    {
        text: "Ingest Stations",
        icon: "location-24",
        url: "/ingest-stations",
    },
    {
        text: "Inventory",
        icon: "clipboard-24",
        authorizedUserGroup: RightsManagement.INVENTORY,
        children: [
            {
                text: "Databox Pool",
                url: "/databox-pool",
                authorizedUserGroup: RightsManagement.INVENTORY_DATABOX_POOL,
            },
            {
                text: "In Car Mounts",
                url: "/in-car-mounts",
                authorizedUserGroup: RightsManagement.INVENTORY_IN_CAR_MOUNTS,
            },
        ],
    },
    {
        text: "Databoxes",
        icon: "save-24",
        authorizedUserGroup: RightsManagement.DATABOXES,
        children: [
            {
                text: "Create Request",
                url: "/create-request",
            },
            {
                text: "My Databoxes",
                url: "/my-databoxes",
            },
        ],
    },
    {
        text: "Operation",
        icon: "checklist-24",
        authorizedUserGroup: RightsManagement.OPERATION,
        children: [
            {
                text: "Databox Orders",
                url: "/databox-orders",
                authorizedUserGroup: RightsManagement.OPERATION_DATABOX_ORDERS,
            },
            {
                text: "Ingests",
                url: "/ingests",
                authorizedUserGroup: RightsManagement.OPERATION_INGESTS,
            },
            {
                text: "Shipment Jobs",
                url: "/shipment-jobs",
                authorizedUserGroup: RightsManagement.OPERATION_SHIPMENT_JOBS,
            },
            {
                text: "Ingest Jobs",
                url: "/ingest-jobs",
                authorizedUserGroup: RightsManagement.OPERATION_INGEST_JOBS,
            },
            {
                text: "Non-Standard Requests",
                url: "/non-standard-requests",
                authorizedUserGroup: RightsManagement.NON_STANDARD_REQUESTS,
            },
        ],
    },
    {
        text: "Reports",
        icon: "diagram-bars-24",
        authorizedUserGroup: RightsManagement.REPORTS,
        children: [
            {
                text: "Databoxes Status Report",
                url: "/reports/databoxes-status-report",
                authorizedUserGroup: RightsManagement.REPORTS_DATABOXES_STATUS_REPORT,
            },
            {
                text: "Shipment Report",
                url: "/reports/shipment-report",
                authorizedUserGroup: RightsManagement.REPORTS_SHIPMENT_REPORT,
            },
            {
                text: "Ingests Statistics",
                url: "/reports/ingests-statistics",
                authorizedUserGroup: RightsManagement.REPORTS_INGEST_STATISTICS,
            },
        ],
    },
    {
        text: "DXC Reports",
        icon: "file-text-24",
        authorizedUserGroup: RightsManagement.DXC_REPORTS,
        children: [
            {
                text: "Cycles Report",
                url: "/cycles-report",
            },
            {
                text: "Databoxes Status Report",
                url: "/databoxes-status-report",
            },
            {
                text: "Ingests Report",
                url: "/ingests-report",
            },
            {
                text: "Job Report",
                url: "/job-report",
            },
        ],
    },
    {
        text: "Administration",
        icon: "settings-24",
        authorizedUserGroup: RightsManagement.ADMINISTRATION,
        children: [
            {
                text: "Manage Projects",
                url: "/manage-projects",
            },
        ],
    },
];

interface SideNavigationProps {
    handleChange: () => void;
    iconOnly: boolean;
}

/**
 * Returns an SideNavigation Component, which holds the different tabs a user can interact with
 *
 * @param {() => void} handleChange - function to run when the side-navigation collapses/expands
 * @param {boolean} iconOnly - adjust styling depending on showing the side-navigation in icon-only mode or fullsize
 *
 * @returns SideNavigation Component
 *
 */
const SideNavigation: React.FC<SideNavigationProps> = ({ handleChange, iconOnly }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [counter, setCounter] = useState(0);
    const [isHover, setIsHover] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const [dropdownIndex, setDropdownIndex] = useState<number>();
    const [submenuPosition, setSubmenuPosition] = useState<{ top: string; left: string }>({
        top: "0px",
        left: "0px",
    });
    const { userGroups } = useContext(UserGroupContext);
    const sideNavRef = useRef<HTMLDivElement>(null);

    const styles = {
        contentContainer: {
            width: iconOnly ? "4.5rem" : "20rem",
        },
        sideNavigationContainer: {
            width: "20rem",
            position: "fixed",
            zIndex: "600",
            float: "left",
        } as React.CSSProperties,
        sideNavigation: {
            padding: "var(--groupui-sys-spacing-600) 0 0",
            height: "calc(100vh - 24px)",
        },
        sideNavigationItems: {
            height: "calc(100vh - 55px)",
        },
        sideNavigationItem: {
            width: iconOnly ? "" : "20rem",
        },
        collabsable: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
        } as React.CSSProperties,
        collabsableContainer: {
            display: "flex",
            flexDirection: "row",
            margin: "var(--groupui-sys-spacing-400)",
            justifyContent: iconOnly ? "" : "space-between",
            width: iconOnly ? "" : "calc(20rem - 32px)",
            alignItems: "center",
        } as React.CSSProperties,
        collabsableIcon: {
            color: "white",
            cursor: "pointer",
            backgroundColor: isHover ? "rgba(32, 96, 126, 0.1)" : "",
        },
        dropDownHeadline: {
            background: "var(--groupui-sys-color-brand-core)",
            color: "white",
            fontSize: "1rem",
            display: "flex",
            alignItems: "flex-start",
            whiteSpace: "nowrap",
            letterSpacing: "0.0125rem",
            fontFamily: "GroupUI, sans-serif",
            padding: "var(--groupui-sys-spacing-500) var(--groupui-sys-spacing-300)",
            lineHeight: "1.55",
        } as React.CSSProperties,
        menuItems: {
            paddingLeft: "var(--groupui-sys-spacing-800)",
        } as React.CSSProperties,
    };

    const handleMouseEnter = (index: number, event: React.MouseEvent<HTMLElement>) => {
        if (!sideNavRef.current) return;
        const boundingRect = event.currentTarget.getBoundingClientRect();
        const sideNavRect = sideNavRef.current.getBoundingClientRect();
        setDropdown(true);
        setDropdownIndex(index);
        const submenuElement = event.currentTarget.nextElementSibling;
        const submenuHeight = submenuElement ? submenuElement.scrollHeight : 0;
        const newPosition = adjustSubmenuPosition(boundingRect, sideNavRect, submenuHeight);
        setSubmenuPosition(newPosition);
    };

    const adjustSubmenuPosition = (
        boundingRect: DOMRect,
        sideNavRect: DOMRect,
        submenuHeight: number
    ) => {
        const viewPortHeight = window.innerHeight;

        let top = boundingRect.top - sideNavRect.top;
        const offset = -80;

        // Ensure the submenu stays within the viewport
        if (top + submenuHeight + offset > viewPortHeight) {
            top = viewPortHeight - submenuHeight - 10;
            if (top < 0) {
                // If the submenu is still going out of the viewport, set top to 0
                top = 0;
            }
        }

        return {
            top: `${top + offset}px`,
            left: `${boundingRect.width}px`,
        };
    };

    return (
        <div style={styles.contentContainer}>
            <div style={styles.sideNavigationContainer} ref={sideNavRef}>
                {!iconOnly && <PortalHeader iconOnly={iconOnly} />}
                {iconOnly && (
                    <div
                        style={{
                            height: "4rem",
                            backgroundColor: "var(--groupui-sys-color-brand-core)",
                        }}
                    />
                )}
                <GroupuiSideNavigation
                    key={counter}
                    size="l"
                    inverted={true}
                    iconOnly={iconOnly}
                    style={styles.sideNavigation}
                >
                    <div
                        style={{
                            height: "calc(100vh - 10rem)",
                            overflowY: "auto",
                            overflowX: "hidden",
                        }}
                    >
                        {navbarItems
                            .filter(
                                (navItem) =>
                                    navItem.authorizedUserGroup?.some((value) =>
                                        userGroups.includes(value)
                                    ) || navItem.authorizedUserGroup == undefined
                            )
                            .map((element, index) => {
                                if (!element.children) {
                                    if (iconOnly) {
                                        return (
                                            <GroupuiTooltip
                                                key={`tooltip-${index}`}
                                                position="right"
                                                style={{ whiteSpace: "nowrap" }}
                                                zIndex={100}
                                            >
                                                <GroupuiSideNavigationItem
                                                    key={`nav-item-${index}`}
                                                    icon={element.icon}
                                                    iconOnly={iconOnly}
                                                    active={
                                                        !!matchPath(
                                                            {
                                                                path: element.url + "/*",
                                                                caseSensitive: false,
                                                            },
                                                            location.pathname
                                                        )
                                                    }
                                                    style={styles.sideNavigationItem}
                                                    onClick={() => navigate(element.url!)}
                                                />
                                                {iconOnly && (
                                                    <div
                                                        key={`tooltip-slot-${index}`}
                                                        slot="tooltip"
                                                    >
                                                        {element.text}
                                                    </div>
                                                )}
                                            </GroupuiTooltip>
                                        );
                                    } else {
                                        return (
                                            <GroupuiSideNavigationItem
                                                key={`nav-item-${index}`}
                                                icon={element.icon}
                                                iconOnly={iconOnly}
                                                active={
                                                    !!matchPath(
                                                        {
                                                            path: element.url + "/*",
                                                            caseSensitive: false,
                                                        },
                                                        location.pathname
                                                    )
                                                }
                                                style={styles.sideNavigationItem}
                                                onClick={() => navigate(element.url!)}
                                            >
                                                {element.text}
                                            </GroupuiSideNavigationItem>
                                        );
                                    }
                                } else {
                                    if (iconOnly) {
                                        return (
                                            <div key={`wrapper-${index}`}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        flex: 1,
                                                        color: "blue",
                                                    }}
                                                >
                                                    <GroupuiSideNavigationItem
                                                        key={`nav-item-${index}`}
                                                        icon={element.icon}
                                                        iconOnly={iconOnly}
                                                        active={
                                                            !!matchPath(
                                                                {
                                                                    path:
                                                                        element.children?.find(
                                                                            (value) => {
                                                                                return (
                                                                                    "/" +
                                                                                        value.url.split(
                                                                                            "/"
                                                                                        )[1] ==
                                                                                    "/" +
                                                                                        location.pathname.split(
                                                                                            "/"
                                                                                        )[1]
                                                                                );
                                                                            }
                                                                        )?.url + "/*",
                                                                    caseSensitive: false,
                                                                },
                                                                location.pathname
                                                            )
                                                        }
                                                        style={{
                                                            background:
                                                                dropdown && dropdownIndex == index
                                                                    ? "rgba(32, 96, 126, 0.1)"
                                                                    : "",
                                                        }}
                                                        onMouseEnter={(event) => {
                                                            handleMouseEnter(index, event);
                                                        }}
                                                        onMouseLeave={() => {
                                                            setDropdown(false);
                                                            setDropdownIndex(index);
                                                        }}
                                                    />
                                                </div>
                                                <ul
                                                    key={`dropdown-${index}`}
                                                    style={{
                                                        position: "absolute",
                                                        left: submenuPosition.left,
                                                        top: submenuPosition.top,
                                                        padding: "unset",
                                                        boxShadow:
                                                            "0 10px 15px -3px rgba(46, 41, 51, 0.08), 0 4px 6px -2px rgba(71, 63, 79, 0.16)",
                                                        minWidth: "15rem",
                                                        maxHeight: `calc(100vh - ${submenuPosition.top})`, // Ensure it doesn't exceed viewport height
                                                        overflowY: "auto", // Add scroll if needed
                                                        display:
                                                            dropdown && dropdownIndex == index
                                                                ? "block"
                                                                : "none",
                                                        background:
                                                            "var(--groupui-sys-color-brand-core)",
                                                    }}
                                                >
                                                    <div
                                                        key={`submenu-${index}`}
                                                        className="test"
                                                        style={styles.dropDownHeadline}
                                                        onMouseEnter={() => {
                                                            setDropdown(true);
                                                            setDropdownIndex(index);
                                                        }}
                                                        onMouseLeave={() => {
                                                            setDropdown(false);
                                                            setDropdownIndex(index);
                                                        }}
                                                    >
                                                        {element.text}
                                                    </div>
                                                    {element.children
                                                        .filter(
                                                            (childNavItem) =>
                                                                childNavItem.authorizedUserGroup?.some(
                                                                    (value) =>
                                                                        userGroups.includes(value)
                                                                ) ||
                                                                childNavItem.authorizedUserGroup ==
                                                                    undefined
                                                        )
                                                        .map((entry, entryIndex) => (
                                                            <GroupuiSideNavigationItem
                                                                key={`nav-subitem-${entryIndex}`}
                                                                active={false}
                                                                iconOnly={!iconOnly}
                                                                onMouseEnter={() => {
                                                                    setDropdown(true);
                                                                    setDropdownIndex(index);
                                                                }}
                                                                onMouseLeave={() => {
                                                                    setDropdown(false);
                                                                    setDropdownIndex(index);
                                                                }}
                                                                onClick={() => navigate(entry.url)}
                                                            >
                                                                <div
                                                                    key={`item-content-${entryIndex}`}
                                                                    style={styles.menuItems}
                                                                >
                                                                    {entry.text}
                                                                </div>
                                                            </GroupuiSideNavigationItem>
                                                        ))}
                                                </ul>
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <GroupuiSideNavigationGroup
                                                key={`nav-group-${index}`}
                                                icon={element.icon}
                                            >
                                                <span key={`headline-${index}`} slot="headline">
                                                    {element.text}
                                                </span>
                                                {element.children
                                                    .filter(
                                                        (childNavItem) =>
                                                            childNavItem.authorizedUserGroup?.some(
                                                                (value) =>
                                                                    userGroups.includes(value)
                                                            ) ||
                                                            childNavItem.authorizedUserGroup ==
                                                                undefined
                                                    )
                                                    .map((entry, entryIndex2) => (
                                                        <GroupuiSideNavigationItem
                                                            key={`nav-item-${entryIndex2}`}
                                                            icon={entry.icon}
                                                            iconOnly={iconOnly}
                                                            active={
                                                                !!matchPath(
                                                                    {
                                                                        path: entry.url + "/*",
                                                                        caseSensitive: false,
                                                                    },
                                                                    location.pathname
                                                                )
                                                            }
                                                            style={styles.sideNavigationItem}
                                                            onClick={() => navigate(entry.url)}
                                                        >
                                                            {entry.text}
                                                        </GroupuiSideNavigationItem>
                                                    ))}
                                            </GroupuiSideNavigationGroup>
                                        );
                                    }
                                }
                            })}
                    </div>
                    <div style={styles.collabsable}>
                        <div style={styles.collabsableContainer}>
                            {!iconOnly && <img src={"/static/img/DXC_Logo.png"} alt="dxcLogo" />}
                            <GroupuiIcon
                                name={iconOnly ? "chevron-right-32" : "chevron-left-32"}
                                style={styles.collabsableIcon}
                                onPointerEnter={() => setIsHover(true)}
                                onPointerLeave={() => setIsHover(false)}
                                onClick={() => {
                                    handleChange();
                                    setCounter(counter + 1);
                                    setIsHover(false);
                                }}
                            />
                        </div>
                    </div>
                </GroupuiSideNavigation>
            </div>
        </div>
    );
};

export default SideNavigation;
