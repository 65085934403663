import React, { useContext, useEffect, useState } from "react";
import GenericHeadline from "../generics/GenericHeadline";
import {
    GroupuiButton,
    GroupuiDatePicker,
    GroupuiDivider,
    GroupuiLoadingSpinner,
    GroupuiMultiselect,
    GroupuiMultiselectOption,
    GroupuiSelect,
    GroupuiSelectOption,
} from "@group-ui/group-ui-react";
import GenericInput from "../generics/GenericInput";
import TrackerDataTable from "../generics/TrackerDataTable";
import { DataboxPoolContext } from "../context/provider/DataboxPoolContext";
import { IngestStation } from "../../models/ingestStation";
import { PortType } from "../../models/portType";
import { Tracker } from "../../models/tracker";
import { databoxOwners } from "../../constants/databoxOwner";
import { ApiService } from "../../services/apiService";
import { handleError } from "../context/ErrorContextUtils";
import ErrorContext from "../context/ErrorContext";
import { storageCapacityValues } from "../../constants/storageCapacity";
import {
    parseDate,
    parseDateBasic,
    parseDateForDatepicker,
    parseDateForDatetimeInputField,
    parseFullDate,
} from "../../utils/DateUtil";
import GenericDateTimePicker from "../generics/GenericDateTimePicker";
import { RightsManagement } from "../../config/rightsManagement";
import { isInUserGroup } from "../../utils/RightsUtils";
import UserGroupContext from "../context/UserGroupContext";
import { OrderProjectHistory } from "../../models/orderProjectHistory";
import TrackerListModal from "./modals/TrackerListModal";
import { Databox } from "../../models/databox";
import Snackbar from "../generics/Snackbar";
import { MediaState } from "../../models/mediaState";

const styles = {
    sectionLayout: {
        display: "flex",
        gap: "var(--groupui-sys-spacing-800)",
        paddingBottom: "var(--groupui-sys-spacing-200)",
    } as React.CSSProperties,
    sectionLayoutColumn: {
        width: "50%",
    } as React.CSSProperties,
    selectField: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "var(--groupui-sys-spacing-600)",
    } as React.CSSProperties,
    button: {
        marginBottom: "var(--groupui-sys-spacing-400)",
    } as React.CSSProperties,
};

interface IFormData {
    name?: string;
    serialNumber?: string;
    ingestStation?: IngestStation;
    owner?: string;
    storageCapacity?: string;
    connectorType?: PortType[];
    purchaseOrderNumber?: string;
    purchaseOrderDate?: string;
    opxNumber?: string;
    serviceContractId?: string;
    serviceStartDate?: string;
    serviceEndDate?: string;
    tracker?: Tracker;
    carnetNumber?: string;
    carnetExpirationDate?: string;
}

interface GeneralInformationProps {
    editDatabox: boolean;
    handleEditDatabox: () => void;
    orderProjectHistory?: OrderProjectHistory;
}

export const GeneralInformation: React.FC<GeneralInformationProps> = ({
    editDatabox,
    handleEditDatabox,
    orderProjectHistory,
}) => {
    const { currentDatabox, databoxPool } = useContext(DataboxPoolContext);
    const { changeShowError, changeErrorMessage } = useContext(ErrorContext);
    const { userGroups } = useContext(UserGroupContext);

    // Initial state for the form
    const initialState: IFormData = {
        name: undefined,
        serialNumber: undefined,
        owner: undefined,
        ingestStation: undefined,
        storageCapacity: undefined,
        connectorType: undefined,
        purchaseOrderNumber: undefined,
        purchaseOrderDate: undefined,
        opxNumber: undefined,
        serviceContractId: undefined,
        serviceStartDate: undefined,
        serviceEndDate: undefined,
        tracker: undefined,
        carnetNumber: undefined,
        carnetExpirationDate: undefined,
    };
    const [formData, setFormData] = useState<IFormData>(initialState);

    const [isLoading, setIsLoading] = useState(false);

    // Holds state of the tracker selection list
    const [showTrackerList, setShowTrackerList] = useState(false);
    // States to handle the snackbar
    const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
    const [severity, setSeverity] = useState<"danger" | "success" | "warning">();
    const [message, setMessage] = useState<string>();

    // Data used in all the fields
    const [ingestStations, setIngestStations] = useState<IngestStation[]>();
    const [portTypes, setPortTypes] = useState<PortType[]>([]);
    const [portTypeChecked, setPortTypeChecked] = useState<boolean[]>([]);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        setInitialDataState();
    }, [currentDatabox, ingestStations, portTypes]);

    // Get data for available Ingest Stations and Port Types
    const getData = async () => {
        setIsLoading(true);
        await Promise.all([
            ApiService.getAllIngestStations()
                .then((ingestStations) => {
                    const sortedData = ingestStations.sort((a, b) => a.name.localeCompare(b.name));
                    setIngestStations(sortedData);
                })
                .catch((error) => {
                    handleError(error, changeErrorMessage, changeShowError);
                }),
            ApiService.getAllPortTypes()
                .then((portTypes: PortType[]) => {
                    setPortTypes(portTypes);
                })
                .catch((error) => {
                    handleError(error, changeErrorMessage, changeShowError);
                }),
        ]).finally(() => setIsLoading(false));
    };

    // Fill the initial state with data
    const setInitialDataState = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            name: currentDatabox?.name,
            serialNumber: currentDatabox?.serialNumber,
            owner: currentDatabox?.owner,
            ingestStation: ingestStations?.find(
                (ingestStation) => ingestStation.name == currentDatabox?.ingestStation?.name
            ),
            storageCapacity: currentDatabox?.storageCapacity
                ? String(currentDatabox?.storageCapacity)
                : undefined,
            connectorType: currentDatabox?.databoxPortTypes,
            purchaseOrderNumber: currentDatabox?.purchaseOrderNumber,
            purchaseOrderDate: currentDatabox?.purchaseOrderDate,
            opxNumber: currentDatabox?.opxNumber,
            serviceContractId: currentDatabox?.serviceContractId,
            serviceStartDate: currentDatabox?.serviceStartDate,
            serviceEndDate: currentDatabox?.serviceEndDate,
            tracker: currentDatabox?.tracker ?? undefined,
            carnetNumber: currentDatabox?.carnetNumber,
            carnetExpirationDate: currentDatabox?.carnetExpirationDate,
        }));
        setInitialConnectorTypes();
    };

    // Set connector type multiselect input to the initial port types
    const setInitialConnectorTypes = () => {
        // Set predefined connector types
        setPortTypeChecked((prevPortTypeChecked) => {
            const updatePortTypeChecked = [...prevPortTypeChecked].fill(false);

            // Find the index of the portType that matches with currentDatabox.databoxPortTypes
            portTypes.forEach((portType, index) => {
                if (
                    currentDatabox?.databoxPortTypes?.some(
                        (databoxPortType) => databoxPortType.portType === portType.portType
                    )
                ) {
                    updatePortTypeChecked[index] = true;
                }
            });

            return updatePortTypeChecked;
        });
    };

    // Handle general input change of input fields and some select fields
    const handleInputChange = (event: any) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value == "" ? undefined : value,
        }));
    };

    // Handle multiselection of connector types
    const handleConnectorTypeChange = (event: any, index: number) => {
        if (event.target.checked) {
            // Add portType to the list
            setFormData((prevFormData) => {
                const selectedPortType = portTypes?.find(
                    (portType) => portType.id === Number(event.target.value)
                );

                // Check if the selected port type already exists in the array
                const isAlreadyAdded = prevFormData.connectorType?.some(
                    (connector) => connector.id === selectedPortType?.id
                );

                return {
                    ...prevFormData,
                    connectorType:
                        selectedPortType && !isAlreadyAdded
                            ? [...(prevFormData.connectorType || []), selectedPortType]
                            : prevFormData.connectorType,
                };
            });
        } else {
            // Remove portType from the list
            setFormData((prevFormData) => {
                return {
                    ...prevFormData,
                    connectorType: prevFormData.connectorType?.filter(
                        (connector) => connector.id !== Number(event.target.value)
                    ),
                };
            });
        }
        setPortTypeChecked((prevPortTypeChecked) => {
            const updatePortTypeChecked = [...prevPortTypeChecked];
            updatePortTypeChecked[index] = event.target.checked;

            return updatePortTypeChecked;
        });
    };

    // Handle the selection of the datepicker
    const handleDatePickerChange = (value: any) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            carnetExpirationDate: value,
        }));
    };

    // Handle Trackert selection from List
    const toggleTrackerList = () => {
        setShowTrackerList((prevState) => !prevState);
    };

    // Handle Trackert selection from List
    const handleTrackerSelect = (tracker: Tracker) => {
        if (tracker) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                tracker: tracker,
            }));
        }
        setShowTrackerList(false);
    };

    // handle the cancel of the form
    const handleCancel = () => {
        handleEditDatabox();
        setInitialDataState();
    };

    const handleValidation = (): boolean => {
        const ingestStation = ingestStations?.find(
            (ingestStation) => ingestStation.id == formData.ingestStation?.id
        );

        if (
            !formData.name ||
            !formData.owner ||
            !formData.ingestStation ||
            !formData.storageCapacity ||
            !formData.serialNumber
        ) {
            setShowSnackBar(true);
            setSeverity("danger");
            setMessage("Basic validation checks failed.");
            setIsLoading(false);
            return false;
        }

        if (
            databoxPool
                ?.filter((databox) => databox.id !== currentDatabox?.id)
                .find((databox) => databox.name == formData.name) !== undefined
        ) {
            setShowSnackBar(true);
            setSeverity("danger");
            setMessage("This name is already used for another Databox.");
            setIsLoading(false);
            return false;
        }

        if (
            databoxPool
                ?.filter((databox) => databox.id !== currentDatabox?.id)
                .find(
                    (databox) =>
                        databox.serialNumber == formData.serialNumber &&
                        (databox.ingestStation == null ||
                            (ingestStation !== undefined &&
                                databox.ingestStation.name == ingestStation.name))
                )
        ) {
            setShowSnackBar(true);
            setSeverity("danger");
            setMessage(
                "This Serial Number in combination with Ingest Station is already used for another Databox."
            );
            setIsLoading(false);
            return false;
        }

        return true;
    };

    // Handle the submit of the updated databox
    const handleSubmit = async () => {
        setIsLoading(true);

        if (!handleValidation()) return;

        const updatedDatabox = new Databox({
            id: currentDatabox?.id ?? -1,
            name: formData.name!,
            serialNumber: formData.serialNumber!,
            owner: formData.owner!,
            storageCapacity: Number(formData.storageCapacity),
            mediaStateType:
                currentDatabox?.mediaStateType ??
                new MediaState({
                    id: -1,
                    mediaStateType: "Free",
                    priority: -1,
                }),
            ingestStation: formData.ingestStation ?? null,
            tracker: formData.tracker,
            purchaseOrderNumber: formData.purchaseOrderNumber,
            purchaseOrderDate: formData.purchaseOrderDate
                ? new Date(formData.purchaseOrderDate).toISOString()
                : undefined,
            opxNumber: formData.opxNumber,
            serviceContractId: formData.serviceContractId,
            serviceStartDate: formData.serviceStartDate
                ? new Date(formData.serviceStartDate).toISOString()
                : undefined,
            serviceEndDate: formData.serviceEndDate
                ? new Date(formData.serviceEndDate).toISOString()
                : undefined,
            databoxPortTypes: formData.connectorType,
            carnetNumber: formData.carnetNumber,
            carnetExpirationDate: formData.carnetExpirationDate
                ? new Date(formData.carnetExpirationDate).toISOString()
                : undefined,
        });

        await ApiService.updateDataboxInformationById(updatedDatabox)
            .then((databox: Databox | undefined) => {
                if (!databox) {
                    setShowSnackBar(true);
                    setSeverity("danger");
                    setMessage("Databox couldn`t be updated!");
                    return;
                }

                setSeverity("success");
                setMessage("Successfully updated the Databox!");
                setShowSnackBar(true);
                setTimeout(() => {
                    location.reload();
                }, 1000);
            })
            .catch((error) => {
                handleError(error, changeErrorMessage, changeShowError);
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <div>
            <GroupuiLoadingSpinner displayed={isLoading} />
            {showSnackBar && (
                <Snackbar
                    severity={severity ?? "danger"}
                    message={message ?? "Some error occurred!"}
                    onClose={() => {
                        setShowSnackBar(false);
                    }}
                />
            )}
            {showTrackerList && (
                <TrackerListModal
                    showModal={showTrackerList}
                    onSelectTracker={handleTrackerSelect}
                    onModalClose={toggleTrackerList}
                />
            )}
            <GenericHeadline
                title={"General Information"}
                titleHeadingType="h4"
                actionButtons={[
                    ...(editDatabox
                        ? [
                              <GroupuiButton
                                  key={"c1"}
                                  icon={"close-24"}
                                  variant={"tertiary"}
                                  alignment={"left"}
                                  onClick={handleCancel}
                              >
                                  Cancel
                              </GroupuiButton>,
                              <GroupuiButton
                                  key={"c4"}
                                  icon={"save-24"}
                                  alignment={"left"}
                                  disabled={
                                      !formData.name ||
                                      !formData.owner ||
                                      !formData.ingestStation ||
                                      !formData.storageCapacity ||
                                      !formData.serialNumber
                                  }
                                  onClick={handleSubmit}
                              >
                                  Save
                              </GroupuiButton>,
                          ]
                        : []),
                ]}
            />
            <div style={styles.sectionLayout}>
                <div style={styles.sectionLayoutColumn}>
                    <GenericInput
                        label={`Name${editDatabox ? " *" : ""}`}
                        name={"name"}
                        type={"text"}
                        pattern={"[A-Za-z0-9]+"}
                        maxLength={50}
                        severity={"none"}
                        required={true}
                        disabled={!editDatabox}
                        value={formData.name}
                        onGroupuiChange={handleInputChange}
                    />
                    <GenericInput
                        label={"Internal Project"}
                        value={orderProjectHistory?.project?.name}
                        disabled={true}
                    />
                    <GenericInput
                        label={"Current Holder"}
                        value={orderProjectHistory?.holderId}
                        disabled={true}
                    />
                    <GroupuiSelect
                        name={"owner"}
                        typeof={"input"}
                        severity={"none"}
                        maxHeight={"10rem"}
                        style={styles.selectField}
                        required={true}
                        disabled={
                            !editDatabox || !isInUserGroup([RightsManagement.SUPPORT], userGroups)
                        }
                        value={formData.owner}
                        onGroupuiChange={handleInputChange}
                    >
                        {databoxOwners.map((owner) => (
                            <GroupuiSelectOption value={owner} key={owner}>
                                {owner}
                            </GroupuiSelectOption>
                        ))}
                        <GroupuiSelectOption value={undefined} key={"empty-value"}>
                            {"<no owner>"}
                        </GroupuiSelectOption>
                        <span slot="label">Owner{editDatabox ? " *" : ""}</span>
                    </GroupuiSelect>
                    <GroupuiSelect
                        name={"ingestStation"}
                        typeof={"input"}
                        severity={"none"}
                        maxHeight={"10rem"}
                        style={styles.selectField}
                        required={true}
                        disabled={
                            !editDatabox || !isInUserGroup([RightsManagement.SUPPORT], userGroups)
                        }
                        value={String(formData.ingestStation?.id)}
                        onGroupuiChange={(event) => {
                            const { name, value } = event.target;
                            setFormData((prevFormData) => ({
                                ...prevFormData,
                                [name]: ingestStations?.find(
                                    (ingestStation) => ingestStation.id == Number(value)
                                ),
                            }));
                        }}
                    >
                        {ingestStations?.map((ingestStation, index) => {
                            return (
                                <GroupuiSelectOption
                                    value={String(ingestStation.id)}
                                    key={`select-option-${index}`}
                                >
                                    {ingestStation.name}
                                </GroupuiSelectOption>
                            );
                        })}
                        <span slot="label">Ingest Station{editDatabox ? " *" : ""}</span>
                    </GroupuiSelect>
                    <GroupuiMultiselect
                        name={"connectorType"}
                        typeof={"input"}
                        severity={"none"}
                        maxHeight={"10rem"}
                        style={styles.selectField}
                        required={true}
                        disabled={!editDatabox}
                        showChips={true}
                    >
                        {portTypes?.map((portType, index) => {
                            return (
                                <GroupuiMultiselectOption
                                    value={String(portType.id)}
                                    key={`select-option-${index}`}
                                    checked={portTypeChecked[index]}
                                    onCheckedChanged={(event) =>
                                        handleConnectorTypeChange(event, index)
                                    }
                                >
                                    {portType.portType}
                                </GroupuiMultiselectOption>
                            );
                        })}
                        <span slot="label">Connector Type</span>
                    </GroupuiMultiselect>
                </div>
                <div style={styles.sectionLayoutColumn}>
                    <GenericInput
                        label={`Serial Number${editDatabox ? " *" : ""}`}
                        name={"serialNumber"}
                        type={"text"}
                        pattern={"[A-Za-z0-9]+"}
                        maxLength={50}
                        severity={"none"}
                        required={true}
                        disabled={!editDatabox}
                        value={formData.serialNumber}
                        onGroupuiChange={handleInputChange}
                    />
                    <GroupuiSelect
                        name={"storageCapacity"}
                        typeof={"input"}
                        severity={"none"}
                        maxHeight={"10rem"}
                        style={styles.selectField}
                        required={true}
                        disabled={!editDatabox}
                        value={String(formData.storageCapacity)}
                        onGroupuiChange={handleInputChange}
                    >
                        {storageCapacityValues.map((e) => (
                            <GroupuiSelectOption value={String(e)} key={e}>
                                {`${e} TB`}
                            </GroupuiSelectOption>
                        ))}
                        <span slot="label">Storage Capacity (TB){editDatabox ? " *" : ""}</span>
                    </GroupuiSelect>
                    <GenericInput
                        label={"Carnet"}
                        type={"text"}
                        severity={"none"}
                        disabled={true}
                        value={
                            editDatabox
                                ? formData.carnetNumber
                                    ? "Yes"
                                    : "No"
                                : currentDatabox?.carnet
                                ? "Yes"
                                : "No"
                        }
                        onGroupuiChange={handleInputChange}
                    />
                    <GenericInput
                        label={"Carnet Number"}
                        name={"carnetNumber"}
                        type={"text"}
                        pattern={"[A-Za-z0-9]+"}
                        maxLength={100}
                        severity={"none"}
                        required={false}
                        disabled={!editDatabox}
                        value={formData.carnetNumber}
                        onGroupuiChange={handleInputChange}
                    />
                    <GenericDateTimePicker
                        label={"Carnet Expiration"}
                        value={parseDateForDatetimeInputField(formData.carnetExpirationDate)}
                        setValue={handleDatePickerChange}
                        required={false}
                        disabled={!editDatabox}
                        severity={"none"}
                        style={styles.selectField}
                    />
                </div>
            </div>
            <GroupuiDivider />
            {isInUserGroup([RightsManagement.SUPPORT], userGroups) && (
                <div>
                    <GenericHeadline
                        title="Purchase and Service Information"
                        titleHeadingType="h4"
                        actionButtons={[]}
                    />
                    <div style={styles.sectionLayout}>
                        <div style={styles.sectionLayoutColumn}>
                            <GenericInput
                                label={"Purchase Order Number"}
                                name={"purchaseOrderNumber"}
                                type={"text"}
                                pattern={"[A-Za-z0-9]+"}
                                maxLength={100}
                                required={false}
                                disabled={!editDatabox}
                                value={formData.purchaseOrderNumber}
                                onGroupuiChange={handleInputChange}
                            />
                            <GroupuiDatePicker
                                name={"purchaseOrderDate"}
                                style={{ whiteSpace: "normal", ...styles.selectField }}
                                severity={"none"}
                                required={false}
                                disabled={!editDatabox}
                                value={parseDateForDatepicker(formData.purchaseOrderDate)}
                                onGroupuiChange={handleInputChange}
                            >
                                <span slot="label">Purchase Order Date</span>
                            </GroupuiDatePicker>
                            <GenericInput
                                label={"OPX Number"}
                                name={"opxNumber"}
                                type={"text"}
                                pattern={"[A-Za-z0-9]+"}
                                maxLength={100}
                                required={false}
                                disabled={!editDatabox}
                                value={formData.opxNumber}
                                onGroupuiChange={handleInputChange}
                            />
                        </div>
                        <div style={styles.sectionLayoutColumn}>
                            <GenericInput
                                label={"Service Contract ID"}
                                name={"serviceContractId"}
                                type={"text"}
                                pattern={"[A-Za-z0-9]+"}
                                maxLength={100}
                                required={false}
                                disabled={!editDatabox}
                                value={formData.serviceContractId}
                                onGroupuiChange={handleInputChange}
                            />
                            <GroupuiDatePicker
                                name={"serviceStartDate"}
                                style={{ whiteSpace: "normal", ...styles.selectField }}
                                severity={"none"}
                                required={false}
                                disabled={!editDatabox}
                                value={parseDateForDatepicker(formData.serviceStartDate)}
                                onGroupuiChange={handleInputChange}
                            >
                                <span slot="label">Service Start Date</span>
                            </GroupuiDatePicker>
                            <GroupuiDatePicker
                                name={"serviceEndDate"}
                                style={{ whiteSpace: "normal", ...styles.selectField }}
                                severity={"none"}
                                required={false}
                                disabled={!editDatabox}
                                value={parseDateForDatepicker(formData.serviceEndDate)}
                                onGroupuiChange={handleInputChange}
                            >
                                <span slot="label">Service End Date</span>
                            </GroupuiDatePicker>
                        </div>
                    </div>
                </div>
            )}
            <GroupuiDivider />
            <div>
                <GenericHeadline title="Tracker" titleHeadingType="h4" actionButtons={[]} />
                <GenericInput
                    label={"Tracker Name"}
                    type={"text"}
                    severity={"none"}
                    required={true}
                    disabled={true}
                    value={formData.tracker?.name.toString()}
                />
                {editDatabox && isInUserGroup([RightsManagement.SUPPORT], userGroups) && (
                    <GroupuiButton
                        style={styles.button}
                        icon={"clipboard-24"}
                        onClick={toggleTrackerList}
                    >
                        Select Tracker from List
                    </GroupuiButton>
                )}
                {!editDatabox && (
                    <TrackerDataTable trackerData={currentDatabox?.tracker?.trackerData ?? []} />
                )}
            </div>
        </div>
    );
};
